import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import React, { useMemo } from 'react';
import { cdnImage } from '@/utils/image-utils';
import {
  selectPromotionsError,
  selectPromotionsInProgress,
  selectPromotionWidgets,
} from '@/store/cms/promotions/selectors';
import PromotionsLoading from '@/pages/promotions/PromotionsLoading';
import PromotionsOverview from '@/pages/promotions/PromotionsOverview';
import PromotionsError from '@/pages/promotions/PromotionsError';
import PromotionsEmpty from '@/pages/promotions/PromotionsEmpty';

const BG_DESKTOP = cdnImage('ld_promotions_bg_desktop.jpg', { width: '1900', height: '480' });
const BG_TABLET = cdnImage('ld_promotions_bg_tablet.png', {
  width: '1024',
  height: '320',
  fit: 'contain',
});
const BG_MOBILE = cdnImage('ld_promotions_bg_mobile.png', {
  width: '640',
  height: '320',
  fit: 'contain',
});

function PromotionsPage() {
  const intl = useIntl();
  const promotionsError = useSelector(selectPromotionsError);
  const promotionsLoading = useSelector(selectPromotionsInProgress);
  const promotions = useSelector(selectPromotionWidgets);

  const VisibleComponent = useMemo(() => {
    if (promotionsLoading) {
      return <PromotionsLoading />;
    }

    if (promotionsError) {
      return <PromotionsError />;
    }

    if (promotions.length === 0) {
      return <PromotionsEmpty />;
    }

    return <PromotionsOverview promotions={promotions} />;
  }, [promotions, promotionsError, promotionsLoading]);

  return (
    <>
      <Helmet>
        <title>LuckyDays - {intl.formatMessage({ id: 'promotions.header' })}</title>
      </Helmet>
      <div className="pt-16 md:pt-32 pb-16 relative">
        <div className="absolute left-0 right-0 bottom-0 top-0 after:absolute after:right-0 after:left-0 after:bottom-0 after:top-0 after:bg-black/40 z-0">
          <img
            className="w-full h-full object-cover object-center"
            src={BG_DESKTOP}
            srcSet={`${BG_MOBILE} 640w, ${BG_TABLET} 1024w, ${BG_DESKTOP} 1440w`}
            alt={intl.formatMessage({ id: 'promotions.header' })}
          />
        </div>
        <div className="relative text-center">
          <h1 className="text-white mb-0">{intl.formatMessage({ id: 'promotions.header' })}</h1>
        </div>
      </div>
      <section className="max-w-5xl mx-auto px-4">{VisibleComponent}</section>
    </>
  );
}

export default PromotionsPage;
