import React from 'react';

import { Helmet } from 'react-helmet-async';

import PageHeader from '@/components/Layout/PageHeader';
import LegalCmsContent from '@/components/shared/LegalCmsContent';
import { useTermsAndConditionsLoader } from '@/hooks/useTermsAndConditionsLoader';

const TermsAndConditions = () => {
  const { tcData, tcLoading } = useTermsAndConditionsLoader();

  return (
    <>
      {tcData && (
        <Helmet>
          <title>LuckyDays - {tcData.title}</title>
        </Helmet>
      )}
      <PageHeader>
        <h1>{tcData?.title}</h1>
      </PageHeader>
      <LegalCmsContent data={tcData} loading={tcLoading} showTitle={false} />
    </>
  );
};

export default TermsAndConditions;
