import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { BFF_URL, envIsCanada } from '@/constants/constants';
import { RootState } from '@/models/root-state.model';
import { Store } from 'redux';
import { BFF_API_URLS } from '@/constants/bff-api-urls';
import {
  BffAvailableBonusesRequest,
  BffAvailableBonusesResponse,
  BffCompleteProfileMexicoRequest,
  BffGamesFetchGameUrlResponse,
  BffGamesGetGameUrlRequest,
  BffGamesJackpotsRequest,
  BffGamesJackpotsResponse,
  BffGamesRequest,
  BffGamesResponse,
  BffLifetimeDepositsResponse,
  BffRegisterResponse,
  BffResponse,
  BffVerifyUserResponse,
  BffGamesLastPlayedResponse,
  BffRegisterMxRequest,
  BffGetSowqResponse,
  BffPostSowqRequest,
  BffUserKycResponse,
  KycType,
  BffForgotPasswordRequest,
  BffForgotPasswordChangeRequest,
  BffSetAuthenticationMethodPathParam,
  BffAuthenticationMethodResponse,
  BffValidateEmailPhoneRequestType,
  BffValidateEmailPhoneResponse,
  BffCmsPromotionsResponse,
  BffStaticTextContentResponse,
  BffCompleteProfileRequest,
  BffCmsBonusIntroCardsResponse,
  BffPsaResponse,
  BffActiveBonusesResponse,
  BffCmsWelcomeTextsResponse,
} from '@lucky7ventures/bff-types';

const axiosInstance = axios.create({
  baseURL: BFF_URL,
});

export function bffApiServiceInterceptor(store: Store) {
  axiosInstance.interceptors.request.use(request => {
    const state: RootState = store.getState();
    const locale = state.common.locale;
    const token = state.auth.token;
    let headers = request.headers;

    // A singular place in the app where we attach the token to the request for API V2
    if (token) {
      headers = { ...headers, 'l7-t': `${token}` };
    }

    const requestLocale = envIsCanada() ? `${locale}-on` : `${locale}`;

    headers = {
      ...headers,
      'l7-b': '1', // 1 is for LuckyDays
      'l7-locale': requestLocale,
    };
    request.headers = headers;

    // add an additional "l" param to each request -> used for browser cache busting on the browser level
    // do it only for GET requests
    if (request.method === 'get') {
      const additionalParams = {
        l: requestLocale,
      };
      request.params = {
        ...request.params,
        ...additionalParams,
      };
    }
    return request;
  });
}

export type ApiServiceGetMethod<R> = (arg?: {
  config?: AxiosRequestConfig;
}) => Promise<AxiosResponse<R>>;

export type ApiServiceGetMethodWithQueryParams<R> = (arg: {
  config: AxiosRequestConfig;
}) => Promise<AxiosResponse<R>>;

export type ApiServicePostMethod<P, R> = ({
  payload,
  config,
}: {
  payload: P;
  config?: AxiosRequestConfig<P>;
}) => Promise<AxiosResponse<R>>;

export type ApiServicePostMethodWithPathParams<D, P, R> = ({
  payload,
  pathParams,
  config,
}: {
  payload: D;
  pathParams: P;
  config?: AxiosRequestConfig<D>;
}) => Promise<AxiosResponse<R>>;

export interface BffApiServiceI {
  registerMexico: ApiServicePostMethod<BffRegisterMxRequest, BffResponse<BffRegisterResponse>>;
  completeProfileMexico: ApiServicePostMethod<BffCompleteProfileMexicoRequest, BffResponse<null>>;
  verifyUser: ApiServiceGetMethod<BffResponse<BffVerifyUserResponse>>;
  getLifetimeDeposits: ApiServiceGetMethod<BffResponse<BffLifetimeDepositsResponse>>;
  uploadKycDocuments: ApiServicePostMethodWithPathParams<FormData, KycType, BffResponse<null>>;
  getAllGames: ApiServicePostMethod<BffGamesRequest, BffResponse<BffGamesResponse>>;
  getGamesJackpots: ApiServicePostMethod<
    BffGamesJackpotsRequest,
    BffResponse<BffGamesJackpotsResponse>
  >;
  getAvailableBonuses: ApiServicePostMethod<
    BffAvailableBonusesRequest,
    BffResponse<BffAvailableBonusesResponse>
  >;
  getGamesLastPlayed: ApiServiceGetMethod<BffGamesLastPlayedResponse>;
  getGameUrl: ApiServicePostMethod<
    BffGamesGetGameUrlRequest,
    BffResponse<BffGamesFetchGameUrlResponse>
  >;
  getKycSowq: ApiServiceGetMethod<BffGetSowqResponse>;
  postKycSowq: ApiServicePostMethod<BffPostSowqRequest, BffResponse<null>>;
  forgotPassword: ApiServicePostMethod<BffForgotPasswordRequest, BffResponse<null>>;
  forgotPasswordChange: ApiServicePostMethod<BffForgotPasswordChangeRequest, BffResponse<null>>;
  logout: ApiServiceGetMethod<BffResponse<null>>;
  getAuthenticationMethod: ApiServicePostMethod<null, BffAuthenticationMethodResponse>;
  setAuthenticationMethod: ApiServicePostMethodWithPathParams<
    null,
    BffSetAuthenticationMethodPathParam,
    null
  >;
  getKycDocuments: ApiServiceGetMethod<BffUserKycResponse>;
  validateEmailPhone: ApiServicePostMethod<
    BffValidateEmailPhoneRequestType,
    BffValidateEmailPhoneResponse
  >;
  getBonusIntroCards: ApiServiceGetMethod<BffCmsBonusIntroCardsResponse>;
  getPromotions: ApiServiceGetMethod<BffCmsPromotionsResponse>;
  getStaticTextContent: ApiServiceGetMethodWithQueryParams<BffStaticTextContentResponse>;
  completeProfile: ApiServicePostMethod<BffCompleteProfileRequest, BffResponse<null>>;
  getPsa: ApiServiceGetMethod<BffResponse<BffPsaResponse>>;
  getActiveBonuses: ApiServiceGetMethod<BffResponse<BffActiveBonusesResponse>>;
  getWelcomeTexts: ApiServiceGetMethod<BffCmsWelcomeTextsResponse>;
}

const apiGetCall = (url: string, config?: AxiosRequestConfig) => {
  return axiosInstance.get(url, config);
};

const apiPostCall = <P>(url: string, payload: P, config?: AxiosRequestConfig<P>) => {
  return axiosInstance.post(url, payload, config);
};

const BffApiService: BffApiServiceI = {
  registerMexico: ({ payload, config }) =>
    apiPostCall(BFF_API_URLS.REGISTER_MEXICO, payload, config),
  completeProfileMexico: ({ payload, config }) =>
    apiPostCall(BFF_API_URLS.COMPLETE_PROFILE_MEXICO, payload, config),
  verifyUser: arg => apiPostCall(BFF_API_URLS.VERIFY, null, arg?.config),
  getLifetimeDeposits: arg => apiPostCall(BFF_API_URLS.GET_LIFETIME_DEPOSITS, null, arg?.config),
  uploadKycDocuments: ({ payload, pathParams, config }) =>
    apiPostCall(BFF_API_URLS.KYC_DOCUMENTS_UPLOAD(pathParams), payload, config),
  getAllGames: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.GET_ALL_GAMES, payload, config);
  },
  getGamesJackpots: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.GET_GAMES_JACKPOTS, payload, config);
  },
  getGamesLastPlayed: arg => apiPostCall(BFF_API_URLS.GET_LAST_PLAYED, null, arg?.config),
  getAvailableBonuses: ({ payload, config }) =>
    apiPostCall(BFF_API_URLS.GET_AVAILABLE_BONUSES, payload, config),
  getGameUrl: ({ payload, config }) => apiPostCall(BFF_API_URLS.GET_GAME_URL, payload, config),
  getKycSowq: arg => apiGetCall(BFF_API_URLS.KYC_SOWQ, arg?.config),
  postKycSowq: ({ payload, config }) => apiPostCall(BFF_API_URLS.KYC_SOWQ, payload, config),
  forgotPassword: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.FORGOT_PASSWORD, payload, config);
  },
  forgotPasswordChange: ({ payload, config }) =>
    apiPostCall(BFF_API_URLS.FORGOT_PASSWORD_CHANGE, payload, config),
  logout: arg => {
    return apiPostCall(BFF_API_URLS.LOGOUT, null, arg?.config);
  },
  getAuthenticationMethod: ({ payload, config }) => {
    return apiPostCall(BFF_API_URLS.GET_AUTHENTICATION_METHOD, payload, config);
  },
  setAuthenticationMethod: ({ pathParams, config }) => {
    return apiPostCall(BFF_API_URLS.SET_AUTHENTICATION_METHOD(pathParams), null, config);
  },
  getKycDocuments: arg => apiPostCall(BFF_API_URLS.GET_KYC, null, arg?.config),
  validateEmailPhone: ({ payload, config }) =>
    apiPostCall(BFF_API_URLS.VALIDATE_EMAIL_PHONE, payload, config),
  getPromotions: arg => apiGetCall(BFF_API_URLS.GET_PROMOTIONS, arg?.config),
  getBonusIntroCards: arg => apiGetCall(BFF_API_URLS.GET_BONUS_INTRO_CARDS, arg?.config),
  getStaticTextContent: arg => apiGetCall(BFF_API_URLS.GET_STATIC_TEXT_CONTENT, arg.config),
  completeProfile: ({ payload, config }) =>
    apiPostCall(BFF_API_URLS.COMPLETE_PROFILE, payload, config),
  getPsa: arg => apiGetCall(BFF_API_URLS.GET_PSA, arg?.config),
  getActiveBonuses: arg => apiPostCall(BFF_API_URLS.GET_ACTIVE_BONUSES, arg?.config),
  getWelcomeTexts: arg => apiGetCall(BFF_API_URLS.GET_WELCOME_TEXTS, arg?.config),
};

export default BffApiService;
