import { RootState } from '@/models/root-state.model';
import { createSelector } from 'reselect';
import { PromotionWidgetVm } from '@/models/vm/promotion-widget-vm.model';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectUserBtagPrefix } from '@/store/selectors/combined.selectors';
import { selectUserTagsAsCmsEnum } from '@/store/selectors/userSelectors';
import { filterPromotionSlugs } from '@/store/cms/promotions/utils';
import { BffCmsPromotionResponse } from '@lucky7ventures/bff-types';
import { selectAutoLoginFinished } from '@/store/selectors/autoLoginSelectors';

export const selectPromotionSlugs = (state: RootState): string[] => state.promotions.slugs;
export const selectPromotions = (state: RootState): Record<string, BffCmsPromotionResponse> =>
  state.promotions.promotions;
export const selectPromotionsLoading = (state: RootState): boolean => state.promotions.loading;
export const selectPromotionsError = (state: RootState): boolean => state.promotions.error;

export const selectAvailablePromotionSlugs = createSelector(
  selectPromotionSlugs,
  selectPromotions,
  selectIsAuthenticated,
  selectUserBtagPrefix,
  selectUserTagsAsCmsEnum,
  (slugs, promotions, isAuthenticated, btagPrefix, userTagNames) =>
    filterPromotionSlugs({
      slugs,
      promotions,
      isAuthenticated,
      btagPrefix,
      userTags: userTagNames,
    }),
);

export const selectPromotionWidgets = createSelector(
  selectAvailablePromotionSlugs,
  selectPromotions,
  (slugs, promotions): PromotionWidgetVm[] => {
    return slugs.reduce((acc: PromotionWidgetVm[], slug) => {
      const promotion = promotions[slug];
      if (promotion) {
        acc.push({
          slug: promotion.slug,
          image: promotion.image,
          widgetTitle: promotion.widgetTitle,
          widgetShortDescription: promotion.widgetShortDescription,
          widgetShortTerms: promotion.widgetShortTerms,
        });
      }
      return acc;
    }, []);
  },
);

export const selectPromotionBySlug = createSelector(
  selectAvailablePromotionSlugs,
  selectPromotions,
  (_state: RootState, slug: string | undefined) => slug,
  (availableSlugs, promotions, slug) => {
    // we don't want to return if a slug doesn't exist in available slugs
    if (!slug || !availableSlugs.includes(slug)) {
      return null;
    }

    return promotions[slug];
  },
);

// we want to treat promotions as in progress if they are loading or auto login is not finished - to avoid flickers based on users state
export const selectPromotionsInProgress = createSelector(
  selectPromotionsLoading,
  selectAutoLoginFinished,
  (loading, autoLoginFinished) => loading || !autoLoginFinished,
);
