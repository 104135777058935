import {
  CDN_BASE,
  CDN_IMAGES_BONUSES_PATH,
  CDN_IMAGES_COMMON_PATH,
  CDN_IMAGES_GAMES_PATH,
  CDN_PAYMENT_PROVIDERS_IMAGES_COMMON_PATH,
} from '@/constants/constants';

interface CDNImageProps {
  width: string;
  height: string;
  fit?: 'scale-down' | 'cover' | 'contain' | 'crop' | 'pad';
  gravity?: string;
  dpr?: string;
  path?: string;
}

/* the images come from Wordpress in most cases, and there we have some conventions, for example the images are named like this: "/bonus/game-name"
 but as that is prone to human errors, and somebody can also input /bonus/game-name.jpg, we want to sanitize the image name to be sure that it is always in the correct format
*/
const sanitizeImageName = (img: string): string => {
  if (!img) {
    // If for some reason img is not defined, we early return
    return '';
  }

  // cover the case if somebody already included the .
  if (img.endsWith('.jpg') || img.endsWith('.png') || img.endsWith('.jpeg')) {
    return img;
  }
  // if the path is not already included we want to fallback to .png
  return `${img}.png`;
};

export const cdnImage = (img: string, props: CDNImageProps): string => {
  // if the img doesn't start with "/" we want to append the "/"
  const sanitizedImg = sanitizeImageName(img);
  const imgPath = `${props.path || CDN_IMAGES_COMMON_PATH}${
    sanitizedImg.startsWith('/') ? sanitizedImg : '/' + sanitizedImg
  }`;
  return `${CDN_BASE}/cdn-cgi/image/fit=${props.fit || 'cover'},width=${props.width},height=${
    props.height
  },gravity=${props.gravity || '1x0'},format=auto,dpr=${props.dpr || '1'}${imgPath}`;
};

// the bonus img comes from Wordpress, and the admins put it in these 2 cases
// 1. /bonuses/sugar-rush-1000
// 2. bonus-img.png
// we want this function to accomodate for both
export const cdnBonusImg = (img: string, props: CDNImageProps): string => {
  // if the image starts with /bonuses/ strip out that prefix
  const imgWithoutPrefix = img.replace('/bonuses/', '');
  const sanitizedImg = sanitizeImageName(imgWithoutPrefix);
  const imgPath = `${CDN_IMAGES_BONUSES_PATH}/${sanitizedImg}`;
  return `${CDN_BASE}/cdn-cgi/image/fit=${props.fit || 'cover'},width=${props.width},height=${
    props.height
  },gravity=${props.gravity || '1x0'},format=auto,dpr=${props.dpr || '1'}${imgPath}`;
};

export const gameThumbImg = (slug: string): string => {
  return cdnImage(`${slug}.jpg`, {
    width: '167',
    height: '205',
    dpr: '2',
    path: CDN_IMAGES_GAMES_PATH,
  });
};

export const gameThumbImgMexico = (slug: string, large: boolean): string => {
  return cdnImage(`${slug}.jpg`, {
    width: large ? '260' : '130',
    height: 'auto',
    dpr: '2',
    path: CDN_IMAGES_GAMES_PATH,
  });
};

export const paymentProviderImgMexico = (slug: string): string => {
  return cdnImage(`${slug}.png`, {
    width: '160',
    height: 'auto',
    dpr: '2',
    path: CDN_PAYMENT_PROVIDERS_IMAGES_COMMON_PATH + '/mexico',
    fit: 'contain',
  });
};

export const gspMexicoImage = (name: string, props?: CDNImageProps): string => {
  const width = props?.width ?? '20';
  const height = props?.height ?? '20';

  return cdnImage(`${name}.png`, {
    width: width,
    height: height,
    dpr: '2',
    path: CDN_IMAGES_COMMON_PATH + '/game-providers/mexico',
  });
};

export const gspGridMexicoImage = (name: string): string => {
  return cdnImage(`${name}.png`, {
    width: 'auto',
    height: '50px',
    dpr: '2',
    fit: 'contain',
    path: CDN_IMAGES_COMMON_PATH + '/game-providers/mexico/white',
  });
};

export const bonusCardMexicoImage = (name: string | null) => {
  if (name)
    return cdnBonusImg(name, {
      width: 'auto',
      height: 'auto',
      dpr: '2',
      gravity: 'auto',
    });
};

export const bonusFluidImage = (name: string): string => {
  return cdnBonusImg(name, {
    height: '84',
    width: '84',
    dpr: '2',
    fit: 'pad',
    gravity: 'auto',
  });
};

export const bannerDesktopImage = (name: string): string => {
  return cdnImage(`${name}`, {
    width: 'auto',
    height: '800px',
    dpr: '2',
    path: CDN_IMAGES_COMMON_PATH + '/banners',
    fit: 'contain',
  });
};

export const bannerMobileImage = (name: string): string => {
  return cdnImage(`${name}`, {
    width: 'auto',
    height: '700px',
    dpr: '2',
    path: CDN_IMAGES_COMMON_PATH + '/banners',
    fit: 'contain',
  });
};
