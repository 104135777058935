import { useDispatch, useSelector } from 'react-redux';
import { isRegulatedLocale } from '@/shared/utility';
import { useAbsoluteNavigate } from './useAbsoluteNavigate';
import { envIsCanada, envIsMexico, envIsNormal } from '@/constants/constants';
import { closeModal, openModal } from '@/store/actions/modal';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import { useCallback } from 'react';
import { setRegistrationOpenedFrom } from '@/store/actions/uiActions';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepActions,
} from '@/store/authentication/AuthenticationStore';
import useIsHomePage from '@/hooks/useIsHomePage';

export function useRegistration() {
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();
  const absoluteNavigate = useAbsoluteNavigate();
  const isHomePage = useIsHomePage();
  const { setHomeHeroAuthenticationStep } = useHomeHeroAuthenticationStepActions();

  const openRegistration = useCallback(
    (params: { openedFrom: OpenedFrom }): void => {
      // we set this OpenedFrom in the store to be able to track where the user started the Registration intent
      // so that we might bring him back to where he started upon registration success
      dispatch(setRegistrationOpenedFrom(params.openedFrom));
      triggerGTag(GTagEvents.open_registration, {
        openedFrom: params.openedFrom,
      });

      if (isRegulatedLocale(locale)) {
        dispatch(openModal('paynplay'));
        return;
      }

      setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.Registration);

      if (envIsMexico() || envIsNormal()) {
        if (!isHomePage) absoluteNavigate('/');
        window.scrollTo({ top: 0, behavior: 'smooth' });
        dispatch(closeModal());
        return;
      }

      if (envIsCanada()) {
        absoluteNavigate('/register');
        // Close any opened modal - e.g. login modal
        dispatch(closeModal());
        return;
      }
    },
    [isHomePage],
  );

  return { openRegistration };
}
