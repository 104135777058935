import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import { selectLocale } from '@/store/selectors/commonSelectors';
import BffApiService from '@/shared/bffApiService';
import { BffStaticTextContentResponse, CmsStaticTextContentType } from '@lucky7ventures/bff-types';

export const usePrivacyPolicyLoader = () => {
  const locale = useSelector(selectLocale);
  const { request, data, loading } = useBffApiRequest<BffStaticTextContentResponse>();

  useEffect(() => {
    request({
      apiMethod: BffApiService.getStaticTextContent,
      axiosConfig: { params: { contentType: CmsStaticTextContentType.privacyPolicy } },
    });
  }, [locale]);

  return { ppData: data, ppLoading: loading };
};
