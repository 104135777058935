import { Action } from 'redux';
import { BffCmsBonusIntroCard } from '@lucky7ventures/bff-types';

export enum BonusIntroCardsActionType {
  GetBonusIntroCards = 'GET_BONUS_INTRO_CARDS',
  GetBonusIntroCardsSuccess = 'GET_BONUS_INTRO_CARDS_SUCCESS',
  GetBonusIntroCardsError = 'GET_BONUS_INTRO_CARDS_ERROR',
}

export type GetBonusIntroCardsAction = Action<BonusIntroCardsActionType.GetBonusIntroCards>;
export type GetBonusIntroCardsSuccessAction =
  Action<BonusIntroCardsActionType.GetBonusIntroCardsSuccess> & {
    bonusIntroCards: BffCmsBonusIntroCard[];
  };
export type GetBonusIntroCardsErrorAction =
  Action<BonusIntroCardsActionType.GetBonusIntroCardsError>;

export type BonusIntroCardsActions =
  | GetBonusIntroCardsAction
  | GetBonusIntroCardsSuccessAction
  | GetBonusIntroCardsErrorAction;

export const getBonusIntroCards = (): GetBonusIntroCardsAction => ({
  type: BonusIntroCardsActionType.GetBonusIntroCards,
});

export const getBonusIntroCardsSuccess = (
  bonusIntroCards: BffCmsBonusIntroCard[],
): GetBonusIntroCardsSuccessAction => ({
  type: BonusIntroCardsActionType.GetBonusIntroCardsSuccess,
  bonusIntroCards,
});

export const getBonusIntroCardsError = (): GetBonusIntroCardsErrorAction => ({
  type: BonusIntroCardsActionType.GetBonusIntroCardsError,
});
