import { RootState } from '@/models/root-state.model';
import { createSelector } from 'reselect';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { FluidBonusData } from '@fluidpayments/types';
import { bonusFluidImage } from '@/utils/image-utils';
import {
  BffActiveBonusesResponse,
  BffAvailableBonusesResponse,
  BonusRedeemType,
} from '@lucky7ventures/bff-types';

export const selectAvailableBonuses = (state: RootState): BffAvailableBonusesResponse =>
  state.bonuses.availableBonuses;
export const selectActiveBonuses = (state: RootState): BffActiveBonusesResponse =>
  state.bonuses.bonuses;

export const selectAvailableBonusesFetched = (state: RootState): boolean =>
  state.bonuses.availableBonusesFetched;

export const selectCashierBonuses = createSelector([selectAvailableBonuses], availableBonuses => {
  return availableBonuses
    .filter(bonus => bonus.redeemTypeId !== BonusRedeemType.BonusCode)
    .map(bonus => ({
      bonusAmount: {},
      code: bonus.promoCode ?? '',
      title: { en: bonus.name },
      desc: { en: bonus.description },
      fixed: false,
      id: bonus.bonusId.toString(),
      isCodeOnly: true,
      minAmount: {},
      multiplier: 0,
      typeId: bonus.typeId,
    }));
});

export const defineMaxBonusPerLocale = (maxBonusAmount: number, locale: string): number => {
  /*
   * GiG sends max bonus amount based in Euro currency, which is
   * inaccurate for Mexico, Norway, South Africa and Thailand.
   * Hence we need to do manual multiplier conversion from Euro
   * to those countries' currencies:
   * MXN = multiplier by 20
   * NOK = multiplier by 10
   * ZAR  = multiplier by 16
   * THB = multiplier by 35
   * */

  switch (locale) {
    case 'mx':
      return maxBonusAmount * 20;
    case 'no':
      return maxBonusAmount * 10;
    case 'za':
      return maxBonusAmount * 16;
    case 'th':
      return maxBonusAmount * 35;
    default:
      return maxBonusAmount;
  }
};

export const selectFluidCashierBonuses = createSelector(
  selectAvailableBonuses,
  selectLocale,
  (bonuses, locale): FluidBonusData => {
    return bonuses
      .filter(bonus => bonus.redeemTypeId !== BonusRedeemType.BonusCode && bonus.promoCode) // There was a bug in Fluid that if we pass a bonus that doesn't have a promo code, the fluid cashier would crash
      .map(bonus => {
        return {
          code: bonus.promoCode!,
          selected: false,
          title: bonus.name,
          description: bonus.description,
          logoUrl: bonusFluidImage(bonus.image),
          termsAndConditions: bonus.terms,
          maxBonusPercentage: bonus.bonusPercentage,
          maxBonus: defineMaxBonusPerLocale(bonus.maxBonusAmount, locale),
          paymentMethodFilter: {
            // We have a fixed list of excluded payment methods for each bonus
            // Todo: excluded payment methods list should be fetched for together with bonus content from WP
            excludeFrom: [
              {
                providerType: 'NETELLER',
              },
              {
                providerType: 'SKRILL',
              },
              {
                providerType: 'PAYSAFECARD',
              },
              {
                providerType: 'ECOPAYZ',
              },
            ],
          },
        };
      });
  },
);

export const selectHasWelcomeBonuses = createSelector(selectAvailableBonuses, bonuses => {
  if (bonuses.length === 0) return false;
  if (!bonuses.find(bonus => bonus.name.toLowerCase().includes('welcome'))) return false;
  return true;
});

export const selectBonusesTurnedOverPercentage = createSelector(selectActiveBonuses, bonuses => {
  if (bonuses.length === 0) return null;

  let leftToTurnover = 0;
  let totalTurnover = 0;

  bonuses.forEach(bonus => {
    leftToTurnover += bonus.wageringAmount;
    totalTurnover += bonus.bonusWagering;
  });

  if (totalTurnover === 0) return null;

  const staked = totalTurnover - leftToTurnover;

  return Math.floor((staked / totalTurnover) * 100);
});

export const selectTotalBonusMoney = createSelector(selectActiveBonuses, bonuses => {
  if (bonuses.length === 0) return null;

  return bonuses.reduce((total, bonus) => {
    return total + bonus.realBonusMoney + bonus.bonusMoney;
  }, 0);
});
