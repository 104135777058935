import React from 'react';
import SkeletonLoader from '@/components/shared/skeleton-loader/SkeletonLoader';
import Card from '@/components/shared/card/Card';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { useIntl } from 'react-intl';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';

function PromotionLoading() {
  const intl = useIntl();
  const navigate = useAbsoluteNavigate();
  return (
    <div>
      <div className="max-w-5xl mx-auto px-4 pt-4 pb-16">
        <button
          type="button"
          className="flex items-center py-4 mb-4"
          onClick={() => navigate('/promotions')}
        >
          <ChevronLeftIcon className="w-6 h-6" />
          {intl.formatMessage({ id: 'promotions.all' })}
        </button>
        <Card>
          <SkeletonLoader className="w-full h-32" />
          <SkeletonLoader className="h-5 mt-3 w-[80%] rounded-md" />
          <SkeletonLoader className="h-5 mt-3 w-[60%] rounded-md" />
        </Card>
      </div>
    </div>
  );
}

export default PromotionLoading;
