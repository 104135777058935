import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { isIOS } from 'react-device-detect';

import { selectIsInAppWrapper, selectLocale } from '@/store/selectors/commonSelectors';
import { envIsNormal } from '@/constants/constants';
import { isRegulatedLocale } from '@/shared/utility';

const useApkDownload = () => {
  const locale = useSelector(selectLocale);
  const isInAppWrapper = useSelector(selectIsInAppWrapper);

  return useMemo(() => {
    // APK download is available:
    // 1. on .com, without regulated locales (fi, fi-en)
    // 2. on all devices except Apple mobiles (iOS)
    // 3. not in the mobile app itself
    const isApkDownloadAvailable =
      envIsNormal() && !isRegulatedLocale(locale) && !isIOS && !isInAppWrapper;

    const apkDownloadLink = (() => {
      switch (locale) {
        case 'th':
          return 'https://promo.luckydays3.com/th/app';
        case 'at':
          return 'https://promo.luckydays.com/at/app';
        case 'ca':
          return 'https://promo.luckydays.com/ca/app';
        case 'ca-fr':
          return 'https://promo.luckydays.com/fr/app';
        default:
          return 'https://promo.luckydays.com/en/app';
      }
    })();

    return {
      apkDownloadLink: isApkDownloadAvailable ? apkDownloadLink : '',
      isApkDownloadAvailable,
    };
  }, [locale, isInAppWrapper]);
};

export default useApkDownload;
