import React from 'react';
import { useIntl } from 'react-intl';
import { cdnImage } from '@/utils/image-utils';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { Button } from '@/components/buttons/Button';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import Card from '@/components/shared/card/Card';

function PromotionError() {
  const intl = useIntl();
  const navigate = useAbsoluteNavigate();
  return (
    <div>
      <div>
        <div className="max-w-5xl mx-auto px-4 pt-4 pb-16">
          <button
            type="button"
            className="flex items-center py-4 mb-4"
            onClick={() => navigate('/promotions')}
          >
            <ChevronLeftIcon className="w-6 h-6" />
            {intl.formatMessage({ id: 'promotions.all' })}
          </button>
          <Card>
            <div className="text-center">
              <img
                src={cdnImage('sitting-cat.png', {
                  width: '200',
                  height: '200',
                  fit: 'scale-down',
                })}
                alt="Lucky Cat Sitting"
                className="mx-auto"
              />
              <div className="my-8">{intl.formatMessage({ id: 'promotion.error' })}</div>
              <Button
                onClickHandler={() => navigate('/promotions')}
                text={intl.formatMessage({ id: 'promotions.all' })}
                className="!w-fit px-8"
              />
            </div>
          </Card>
        </div>
      </div>
      <div className="text-center my-16"></div>
    </div>
  );
}

export default PromotionError;
