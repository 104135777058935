import React from 'react';
import { useIntl } from 'react-intl';
import { cdnImage } from '@/utils/image-utils';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { Button } from '@/components/buttons/Button';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { useLogin } from '@/hooks/useLogin';
import { OpenedFrom } from '@/lib/gTagManager';

function PromotionsEmpty() {
  const intl = useIntl();
  const navigate = useAbsoluteNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const { openLogin } = useLogin();

  const onLoginHandler = () => {
    openLogin({ openedFrom: OpenedFrom.PromotionsEmpty });
  };

  return (
    <div className="text-center my-16">
      <img
        src={cdnImage('sitting-cat.png', { width: '200', height: '200', fit: 'scale-down' })}
        alt="Lucky Cat Sitting"
        className="mx-auto"
      />
      <div className="my-8">
        {intl.formatMessage({
          id: isAuthenticated
            ? 'promotions.overview.logged-in.empty'
            : 'promotions.overview.logged-out.empty',
        })}
      </div>
      {!isAuthenticated && (
        <Button
          onClickHandler={onLoginHandler}
          text={intl.formatMessage({ id: 'header.login' })}
          className="!w-fit px-8"
        />
      )}
      {isAuthenticated && (
        <Button
          onClickHandler={() => navigate('/')}
          text={intl.formatMessage({ id: 'header.home' })}
          className="w-min px-8"
        />
      )}
    </div>
  );
}

export default PromotionsEmpty;
