import { WelcomeTextsActions, WelcomeTextsActionType } from '@/store/cms/welcome-texts/actions';
import { BffCmsWelcomeTextsResponse, CmsWelcomeTextPosition } from '@lucky7ventures/bff-types';
import { isEmpty } from '@/shared/utility';

export interface WelcomeTextsState {
  welcomeTexts: BffCmsWelcomeTextsResponse;
  loading: boolean;
  error: boolean;
}

const initialState: WelcomeTextsState = {
  welcomeTexts: {
    [CmsWelcomeTextPosition.Hero]: { text: '' },
    [CmsWelcomeTextPosition.HomeCenter]: { text: '' },
    [CmsWelcomeTextPosition.BonusIntro]: { text: '' },
  },
  loading: false,
  error: false,
};

export const welcomeTextsReducer = (
  state = initialState,
  action: WelcomeTextsActions,
): WelcomeTextsState => {
  switch (action.type) {
    case WelcomeTextsActionType.GetWelcomeTexts:
      return { ...state, loading: true, error: false };
    case WelcomeTextsActionType.GetWelcomeTextsError:
      return { ...state, ...initialState, loading: false, error: true };
    case WelcomeTextsActionType.GetWelcomeTextsSuccess: {
      if (!isEmpty(action.welcomeTexts))
        return {
          ...state,
          welcomeTexts: { ...state.welcomeTexts, ...action.welcomeTexts },
          loading: false,
          error: false,
        };
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    default:
      return state;
  }
};
