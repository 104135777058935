import React from 'react';

import ModalLayout from '@/components/Modals/ModalLayout';
import LegalCmsContent from '@/components/shared/LegalCmsContent';
import { useTermsAndConditionsLoader } from '@/hooks/useTermsAndConditionsLoader';

const TermsAndConditionsModal = ({ closeModal }: { closeModal: VoidFunction }) => {
  const { tcData, tcLoading } = useTermsAndConditionsLoader();

  return (
    <ModalLayout closeModal={closeModal}>
      <LegalCmsContent data={tcData} loading={tcLoading} />
    </ModalLayout>
  );
};

export default TermsAndConditionsModal;
