import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import storeSynchronize from 'redux-localstore';
import createSagaMiddleware from 'redux-saga';
import { axiosInterceptor } from '@/shared/axiosInstance';
import authReducer from './reducers/auth';
import bonusesReducer from './reducers/bonusesReducer';
import drawerReducer from './reducers/drawer';
import { limitsReducer } from './reducers/limitsReducer';
import { loginHistoryReducer } from './reducers/loginHistoryReducer';
import modalReducer from './reducers/modal';
import userReducer from './reducers/user';
import { userPlayStatsAndLimitsReducer } from './reducers/userPlayStatsAndLimitsReducer';
import { watchUserPlayStatsAndLimits } from './sagas/userPlayStatsAndLimitsSaga';
import { watchAuth, watchBonuses, watchUser } from './sagas/index';
import { commonReducer } from './reducers/commonReducer';
import { watchLimits } from './sagas/limitsSaga';
import { watchLoginHistory } from './sagas/loginHistorySaga';
import { lastDepositReducer } from './reducers/lastDepositReducer';
import { watchLastDeposit } from './sagas/lastDepositSaga';
import { watchAutoLogin } from './sagas/autoLoginSaga';
import { autoLoginReducer } from './reducers/autoLoginReducer';
import { watchManualBonuses } from './sagas/manualBonusesSagas';
import { manualBonusesReducer } from './reducers/manualBonusesReducer';
import { watchWordpress } from './sagas/wordpressSagas';
import { wordpressReducer } from './reducers/wordpressReducer';
import { watchGames } from './sagas/gamesSagas';
import { gamesReducer } from './reducers/gamesReducer';
import { kycReducer } from './reducers/kycReducer';
import { watchKyc } from './sagas/kycSagas';
import { uiReducer } from './reducers/uiReducer';
import { watchConsents } from './sagas/consentsSagas';
import { consentsReducer } from './reducers/consentsReducer';
import amlReducer from './reducers/amlReducer';
import { watchAml } from './sagas/amlSagas';
import { userExtrasReducer } from './reducers/userExtras';
import { watchUserExtras } from './sagas/userExtrasSagas';
import { apiServiceInterceptor } from '@/shared/apiService';
import { bffApiServiceInterceptor } from '@/shared/bffApiService';
import { promotionsReducer } from '@/store/cms/promotions/reducer';
import { watchPromotionsSagas } from '@/store/cms/promotions/sagas';
import { watchBonusIntroCardsSagas } from '@/store/cms/bonus-intro/sagas';
import { bonusIntroCardsReducer } from '@/store/cms/bonus-intro/reducers';
import { watchWelcomeTextsSagas } from '@/store/cms/welcome-texts/sagas';
import { welcomeTextsReducer } from '@/store/cms/welcome-texts/reducers';

const rootReducer = combineReducers({
  aml: amlReducer,
  auth: authReducer,
  autoLogin: autoLoginReducer,
  bonuses: bonusesReducer,
  common: commonReducer,
  consents: consentsReducer,
  drawer: drawerReducer,
  bffGames: gamesReducer,
  kyc: kycReducer,
  lastDeposit: lastDepositReducer,
  limits: limitsReducer,
  loginHistory: loginHistoryReducer,
  manualBonuses: manualBonusesReducer,
  modal: modalReducer,
  ui: uiReducer,
  user: userReducer,
  userExtras: userExtrasReducer,
  userPlayStatsAndLimits: userPlayStatsAndLimitsReducer,
  wordpress: wordpressReducer,
  promotions: promotionsReducer,
  bonusIntro: bonusIntroCardsReducer,
  welcomeTexts: welcomeTextsReducer,
});

const sagaMiddleware = createSagaMiddleware();

/* Use this if you want to enable tracing for the redux devtools */
// const composeEnhancers = composeWithDevTools({ trace: true, maxAge: 200 });
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(watchAml);
sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchAutoLogin);
sagaMiddleware.run(watchBonuses);
sagaMiddleware.run(watchConsents);
sagaMiddleware.run(watchGames);
sagaMiddleware.run(watchKyc);
sagaMiddleware.run(watchLastDeposit);
sagaMiddleware.run(watchLimits);
sagaMiddleware.run(watchLoginHistory);
sagaMiddleware.run(watchManualBonuses);
sagaMiddleware.run(watchUser);
sagaMiddleware.run(watchUserExtras);
sagaMiddleware.run(watchUserPlayStatsAndLimits);
sagaMiddleware.run(watchBonusIntroCardsSagas);
sagaMiddleware.run(watchWelcomeTextsSagas);
sagaMiddleware.run(watchWordpress);
sagaMiddleware.run(watchPromotionsSagas);

// add axios interceptor with access to store
axiosInterceptor(store);
apiServiceInterceptor(store);
bffApiServiceInterceptor(store);

export default store;

storeSynchronize(store, {
  whitelist: ['bffGames'],
});
