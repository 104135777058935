import React, { useMemo } from 'react';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepData,
} from '@/store/authentication/AuthenticationStore';
import LoginForm from '@/features/authentication/login/LoginForm';
import ForgotPasswordForm from '@/features/authentication/forgot-password/ForgotPasswordForm';
import TwoFactorAuthenticationForm from '@/features/authentication/two-factor-authentication/TwoFactorAuthenticationForm';
import ForgotPasswordSuccess from '@/features/authentication/forgot-password/ForgotPasswordSuccess';
import { useSelector } from 'react-redux';
import { selectAuthRecentlyLoggedOut } from '@/store/selectors/authSelectors';
import { useRewardsWheel } from '@/hooks/useRewardsWheel';
import Registration from '@/features/authentication/registration/Registration';
import HomeHeroAuthenticationStepLayout from '@/pages/home/hero/HomeHeroAuthenticationStepLayout';
import { FormattedMessage } from 'react-intl';
import HomeHeroAuthenticationIntro from '@/pages/home/hero/HomeHeroAuthenticationIntro';
import useIsAuthenticationIntroEnabled from '@/hooks/useIsAuthenticationIntroEnabled';
import { selectHeroWelcomeTextsHtml } from '@/store/cms/welcome-texts/selectors';

const HomeHeroAuthentication = () => {
  const { homeHeroAuthenticationStep } = useHomeHeroAuthenticationStepData();
  const hasRecentlyLoggedOut = useSelector(selectAuthRecentlyLoggedOut);
  const { isEnabled: isRewardsReelEnabled } = useRewardsWheel();
  const showAuthenticationIntro = useIsAuthenticationIntroEnabled();
  const welcomeOfferHeroText = useSelector(selectHeroWelcomeTextsHtml);

  const heading = useMemo(() => {
    const getHeadingId = () => {
      switch (homeHeroAuthenticationStep) {
        case HomeHeroAuthenticationStep.Login:
          return isRewardsReelEnabled && hasRecentlyLoggedOut // special handling for the state when user logs out and rewards reel is enabled
            ? 'authentication.login.header.rewardsWheelReminder'
            : 'authentication.login.header';
        case HomeHeroAuthenticationStep.ForgotPassword:
          return 'authentication.forgot-password.header';
        case HomeHeroAuthenticationStep.TwoFactorAuthentication:
          return 'authentication.two-factor-authentication.header';
        default:
          return 'authentication.forgot-password-success.header';
      }
    };

    if (homeHeroAuthenticationStep === HomeHeroAuthenticationStep.Registration)
      return welcomeOfferHeroText;

    return (
      <FormattedMessage
        id={getHeadingId()}
        values={{
          span: str => <span className="text-blue-blue">{str}</span>,
        }}
      />
    );
  }, [
    homeHeroAuthenticationStep,
    isRewardsReelEnabled,
    hasRecentlyLoggedOut,
    welcomeOfferHeroText,
  ]);

  const AuthenticationStep = useMemo(() => {
    switch (homeHeroAuthenticationStep) {
      case HomeHeroAuthenticationStep.Login:
        return LoginForm;
      case HomeHeroAuthenticationStep.ForgotPassword:
        return ForgotPasswordForm;
      case HomeHeroAuthenticationStep.TwoFactorAuthentication:
        return TwoFactorAuthenticationForm;
      case HomeHeroAuthenticationStep.ForgotPasswordSuccess:
        return ForgotPasswordSuccess;
      default:
        return Registration;
    }
  }, [homeHeroAuthenticationStep]);

  if (showAuthenticationIntro) {
    return <HomeHeroAuthenticationIntro />;
  }

  return (
    <HomeHeroAuthenticationStepLayout heading={heading}>
      <AuthenticationStep />
    </HomeHeroAuthenticationStepLayout>
  );
};

export default HomeHeroAuthentication;
