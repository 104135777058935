import { IntlShape } from 'react-intl';
import { Location } from 'react-router-dom';
import {
  BUILD_MODE,
  CDN_BASE,
  CDN_IMAGES_COMMON_PATH,
  envIsNormal,
  IS_SWEDEN,
} from '@/constants/constants';
import { isAxiosResponse, isWpError } from './type-guards';
import { AxiosResponse } from 'axios';
import { LimitDetails } from '@lucky7ventures/lucky-components';
import { isRegulatedLocale } from '@/shared/utility';
import { buildLocales } from '@/constants/locales';
import { BffApiErrorCode } from '@/models/bff-api-error';
import { Dispatch } from 'redux';
import { openModal } from '@/store/actions/modal';
import { OpenedFrom } from '@/lib/gTagManager';
import { CmsCtaType } from '@lucky7ventures/bff-types';

export const localeFromPath = (location: Location): string | null => {
  const pathParts = location.pathname.split('/');
  // we look for locale at the index 1 in the url
  if (pathParts.length > 1 && buildLocales().includes(pathParts[1])) {
    return pathParts[1];
  }

  if (pathParts[1] === 'ja' || pathParts[1] === 'eu') {
    return 'row';
  }

  if (BUILD_MODE === 'canada') {
    if (pathParts[1] === 'fr') {
      return 'ca-fr';
    }
  }

  // if the locale is not matching our list of available locales
  return null;
};

export const mapLocaleToLang = (locale: string): string => {
  switch (locale) {
    case 'at':
      return 'de-AT';
    case 'ca-fr':
    case 'fr':
      return 'fr';
    case 'nz':
      return 'en-NZ';
    case 'ca':
      return 'en-CA';
    case 'za':
      return 'en-ZA';
    case 'ae':
      return 'en-AE';
    case 'no':
      return 'nb-NO';
    case 'th':
      return 'th';
    case 'fi-en':
      return 'fi-EN';
    case 'sv':
      return 'sv-SE';
    case 'row':
      return 'en';
    case 'mx':
      return 'es-MX';
    default:
      return locale;
  }
};

export const wpApiUrl = (locale: string, endpoint: string): string => {
  const wpBase = process.env.REACT_APP_WP_BASE;
  const wpApi = process.env.REACT_APP_WP_API;

  if (!wpBase || !wpApi) {
    throw new Error('Missing Env params: REACT_APP_WP_BASE or REACT_APP_WP_API');
  }

  const transform = (locale: string): string => {
    if (BUILD_MODE === 'canada') {
      // for the canada build mode we have special wordpress locales
      if (locale === 'ca-fr') {
        return '/ca-on-fr';
      }
      return '/ca-on';
    }

    if (locale === 'row') {
      return '';
    }

    // If locale is 'at' we still want to point to /de url on WP
    if (locale === 'at') {
      return '/de';
    }

    return `/${locale}`;
  };

  return wpBase + transform(locale) + wpApi + endpoint;
};

export const isValidEmail = (email: string): boolean => {
  if (!email) {
    return false;
  }

  const splitEmail = email.split('@');
  if (!splitEmail[1]) {
    return false;
  }

  return !splitEmail[1].includes('bankid.temp.email');
};

export const getPathWithoutLocale = (path: string): string => {
  if (IS_SWEDEN) {
    return path;
  }

  return path.split('/').splice(0, 1).join('/');
};

export const utcToLocalTime = (date: Date): Date => {
  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
  return newDate;
};

export const classNames = (...classes: any): string => {
  return classes.filter(Boolean).join(' ');
};

export function getLimitDuration(durationId: LimitDetails['Duration'], intl: IntlShape) {
  switch (durationId) {
    case 1:
      return `${intl.formatMessage({ id: 'misc.day' })}`; // day - 24hours
    case 2:
      return `${intl.formatMessage({ id: 'misc.week' })}`; // week
    case 3:
      return `${intl.formatMessage({ id: 'misc.month' })}`; // month
    case 4:
      return `3 ${intl.formatMessage({ id: 'misc.months' })}`; // months
    case 5:
      return 'No duration';
    case 6:
      return 'Session';
    default:
      break;
  }
}

// Check if the user should be redirected to /ca or /ca-fr when comming from a canadian IP
export function getLocaleByNavigator(): string {
  const browserLang = navigator.language || navigator.languages;
  if (browserLang.includes('fr')) {
    return 'ca-fr';
  } else {
    return 'ca';
  }
}

export const openSearchPallete = () => {
  // Hackers don't need no redux.
  window.dispatchEvent(new KeyboardEvent('keydown', { ctrlKey: true, key: 'k' }));
};
export const doNothing = (): void => {};

export const isSuccessfulWpResponse = <T>(response: unknown): response is AxiosResponse<T> => {
  if (!isAxiosResponse(response)) {
    return false;
  }

  return !isWpError(response.data);
};

export const depositLimits = () => {
  if (IS_SWEDEN) {
    return {
      min: 20,
      day: 500000,
      week: 999999,
      month: 999999,
    };
  }

  return {
    min: 20,
    day: 100000,
    week: 100000,
    month: 100000,
  };
};

export const paymentProviderLogo = (image: string, width = 152, height = 152): string => {
  return `${CDN_BASE}/cdn-cgi/image/fit=contain,width=${width},height=${height},format=auto,dpr=2${CDN_IMAGES_COMMON_PATH}/payment-providers/${image}`;
};

// FastReg is currently available only for .com without Finland
export const isFastReg = (locale: string) => envIsNormal() && !isRegulatedLocale(locale);

export const deepCompare = (obj1: any, obj2: any) => {
  // Check if both objects are of type 'object'
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return obj1 === obj2; // Compare non-object types
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is different
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Iterate through each key in the first object
  for (const key of keys1) {
    // Check if the second object has the same key
    if (!keys2.includes(key)) {
      return false;
    }

    // Recursively compare nested objects
    if (!deepCompare(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true; // Objects are deeply equal
};

export const stringValueByKey = <T extends object, K extends keyof T>(obj: T, key: K): string => {
  return obj[key] ? String(obj[key]) : '';
};

export const maskCanadaPostCode = (raw: string) => {
  if (!raw || raw.length === 0) {
    return '';
  }
  const alphanumericString = raw.replace(/\W+/g, '').toUpperCase();

  if (alphanumericString.length <= 3) {
    return alphanumericString;
  } else {
    return `${alphanumericString.slice(0, 3)} ${alphanumericString.slice(3, 6)}`;
  }
};

export const capitalize = (string: string) => {
  if (!string || string.length === 0) {
    return '';
  }
  return string.replace(/\b\w/g, match => match.toUpperCase());
};

export const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = event => {
      const img = new Image();

      if (typeof event.target?.result === 'string') {
        img.src = event.target.result;
      }

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          reject(new Error('Failed to get canvas context.'));
          return;
        }

        // Determine whether to scale the image
        if (img.width <= maxWidth && img.height <= maxHeight) {
          // Image is smaller than max dimensions; no scaling needed
          canvas.width = img.width;
          canvas.height = img.height;
        } else {
          // Calculate the scaling factor to resize the image while maintaining aspect ratio
          const scaleFactor = Math.min(maxWidth / img.width, maxHeight / img.height);
          canvas.width = img.width * scaleFactor;
          canvas.height = img.height * scaleFactor;
        }

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(blob => {
          if (!blob) {
            reject(new Error('Canvas is empty.'));
            return;
          }
          const resizedFile = new File([blob], file.name, {
            type: file.type,
            lastModified: Date.now(),
          });

          resolve(resizedFile);
        }, file.type);
      };

      img.onerror = () => {
        reject(new Error('Failed to load image.'));
      };
    };

    reader.onerror = () => {
      reject(new Error('Failed to read file.'));
    };

    reader.readAsDataURL(file);
  });
};

export const generateBffErrorMessageWithCode = (message: string, code: BffApiErrorCode) => {
  return `${message} [${code}]`;
};

export function createButtonActionFromCmsData(
  navigate: (to: string) => void,
  dispatch: Dispatch<any>,
  isReturningUser: boolean,
  openLogin: ({ openedFrom }: { openedFrom: OpenedFrom }) => void,
  openRegistration: ({ openedFrom }: { openedFrom: OpenedFrom }) => void,
  openedFrom: OpenedFrom,
  ctaType: CmsCtaType | null,
  ctaLink: string | null,
) {
  switch (ctaType) {
    case CmsCtaType.Authentication: {
      if (isReturningUser) return () => openLogin({ openedFrom });
      return () => openRegistration({ openedFrom });
    }
    case CmsCtaType.Internal:
      return () => navigate(ctaLink ?? '');
    case CmsCtaType.TermsAndConditions:
      return () => dispatch(openModal('bonusTermsAndConditions'));
    default:
      return () => window.open(ctaLink ?? '', '_blank');
  }
}

export function createButtonTextFromCmsData(
  ctaType: CmsCtaType | null,
  isReturningUser: boolean,
  intl: IntlShape,
  ctaText: string | null,
) {
  if (ctaText) return ctaText;
  if (ctaType === CmsCtaType.Authentication) {
    if (isReturningUser) return intl.formatMessage({ id: 'header.login' });
    return intl.formatMessage({ id: 'header.register' });
  }
}
