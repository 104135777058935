import React from 'react';
import BonusTermsAndConditionsContent from './BonusTermsAndConditionsContent';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import CloseButton from '@/components/UI/Buttons/CloseButton';
import { openModal } from '@/store/actions/modal';

const BonusTermsAndConditionsModal = ({ closeModal }: { closeModal: () => void }): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <div className="bonus-terms-modal prose relative flex w-full max-w-lg xl:max-w-2xl flex-col overflow-hidden rounded-lg bg-white px-4 pt-16 pb-4 text-left md:px-8">
      <CloseButton left onClick={closeModal} />
      <BonusTermsAndConditionsContent />
      <button
        type="button"
        onClick={() => dispatch(openModal('termsAndConditions'))}
        className="mt-8 flex w-full items-center justify-center rounded-full bg-cyan-500 p-4 font-bold text-white shadow-md transition hover:bg-cyan-600 hover:shadow-lg"
      >
        {intl.formatMessage({
          id: 'bonusTerms.linkToTermsText',
        })}
      </button>
      <button
        type="button"
        className="p-4 font-bold text-black/60 transition-colors hover:text-black"
        onClick={closeModal}
      >
        {intl.formatMessage({ id: 'termsAndConditions.closeButton' })}
      </button>
    </div>
  );
};

export default BonusTermsAndConditionsModal;
