import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import {
  CreditCardIcon,
  Bars3BottomRightIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import GameIcon from '../UI/Icons/GameIcon';
import NotificationBadge from '../UI/NotificationBadge';
import SmallLogo from '../UI/SmallLogo';
import { isEmpty } from '@/shared/utility';
import { openSearchPallete } from '@/utils/utils';
import { useCashierShow } from '@/features/cashier/useCashier';
import { selectAvailableBonuses } from '@/store/selectors/bonusesSelectors';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { openDrawer } from '@/store/actions/drawer';

const StyledTabBar = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: ${props => props.theme.colors.white};
  justify-content: space-around;
  align-items: center;
  display: flex;
  border-top: 1px solid #eee;
  z-index: 90;
  padding-bottom: env(safe-area-inset-bottom);

  @media (min-width: ${props => props.theme.breakpoints.small}) {
    display: none;
  }

  a,
  button {
    font-size: 16px;
    padding: 16px;
    border: none;
    background: ${props => props.theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: ${props => props.theme.colors.blue3};
    flex: 1;
    font-weight: bold;
    line-height: 20px;
    position: relative;
    cursor: pointer;

    &.active {
      color: ${props => props.theme.colors.blue1};

      svg * {
        stroke: ${props => props.theme.colors.blue1};
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .notification-badge {
      left: auto;
      right: calc(50% - 22px);
      top: 8px;
    }
  }
`;

function TabBar() {
  const isAuthenticated = useSelector(state => state.auth.token !== null);
  const availableBonuses = useSelector(selectAvailableBonuses);
  const [bonusCount, setBonusCount] = useState(0);
  const dispatch = useDispatch();
  const showCashier = useCashierShow();

  useEffect(() => {
    if (!isEmpty(availableBonuses)) {
      setBonusCount(availableBonuses.length);
    }
  }, [availableBonuses]);

  const handleSearchClick = () => {
    triggerGTag(GTagEvents.bottom_nav_search_click);
    openSearchPallete();
  };

  const handleMenuClick = () => {
    triggerGTag(GTagEvents.bottom_nav_menu_click);
    dispatch(openDrawer('menu'));
  };

  return (
    <StyledTabBar id="thumb-nav">
      <NavLink
        to=""
        aria-label="LuckyDays"
        onClick={() => {
          triggerGTag(GTagEvents.bottom_nav_home_click);
        }}
        end
      >
        <SmallLogo />
        {isAuthenticated && bonusCount > 0 && <NotificationBadge count={bonusCount} />}
      </NavLink>
      <NavLink
        to="games"
        aria-label="Games"
        onClick={() => {
          triggerGTag(GTagEvents.bottom_nav_games_click);
        }}
      >
        <GameIcon />
      </NavLink>
      {isAuthenticated && (
        <button
          data-cy="tab-bar-cashier-button"
          type="button"
          aria-label="Open cashier"
          onClick={() => {
            triggerGTag(GTagEvents.bottom_nav_cashier_click);
            showCashier();
          }}
        >
          <CreditCardIcon />
        </button>
      )}
      <button
        type="button"
        title="Search game"
        aria-label="Search game"
        onClick={handleSearchClick}
      >
        <MagnifyingGlassIcon />
      </button>
      <button
        data-cy="tab-bar-menu-button"
        type="button"
        title="Menu"
        aria-label="Menu"
        onClick={handleMenuClick}
      >
        <Bars3BottomRightIcon />
      </button>
    </StyledTabBar>
  );
}

export default TabBar;
