import React from 'react';

import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { Clock } from '@/components/Clock';
import { CountrySelector } from '@/components/CountrySelector';
import { TranslatedRichText } from '@/components/TranslatedRichText';
import { FooterLogos } from '@/components/FooterLogos';
import { FooterNav } from '@/components/FooterNav';
import { selectIsInAppWrapper, selectLocale } from '@/store/selectors/commonSelectors';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { isRegulatedLocale } from '@/shared/utility';
import {
  BUILD_MODE,
  envIsCanada,
  envIsMexico,
  envIsNormal,
  envIsSweden,
} from '@/constants/constants';
import Logo from '@/components/shared/logos/Logo';
import useApkDownload from '@/hooks/useApkDownload';

export function Footer(): JSX.Element {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const locale = useSelector(selectLocale);
  const intl = useIntl();
  const footerContentId = envIsCanada() ? '.ontario' : '';
  const isInAppWrapper = useSelector(selectIsInAppWrapper);
  const { isApkDownloadAvailable, apkDownloadLink } = useApkDownload();

  const shouldPresentCountrySelector = (): boolean => {
    if (envIsMexico() || envIsSweden()) {
      return false;
    }
    return !isAuthenticated;
  };

  return (
    <footer id="footer" className="bg-slate-900 py-24 text-white">
      <div className="mx-auto w-full max-w-[1400px] px-4 md:px-8">
        <Logo color="white" />
        <div className="mb-8 grid gap-12 md:grid-cols-3">
          <div className="prose prose-invert max-w-full space-y-6 md:col-span-2">
            <TranslatedRichText tagName="div" id={`footer.text${footerContentId}`} />
            {envIsMexico() && <p>{intl.formatMessage({ id: 'footer.text.age' })}</p>}
            {envIsCanada() && <p>{intl.formatMessage({ id: 'footer.ppc.text' })}</p>}
            {(isRegulatedLocale(locale) || BUILD_MODE === 'canada') && <Clock />}
            {shouldPresentCountrySelector() && <CountrySelector />}
          </div>
          <div className="mt-5">
            <FooterNav isAuthenticated={isAuthenticated} locale={locale} />
          </div>
        </div>
        <FooterLogos
          isApkDownloadAvailable={isApkDownloadAvailable}
          apkDownloadLink={apkDownloadLink}
          locale={locale}
          isMexico={envIsMexico()}
          isSweden={envIsSweden()}
          isCanada={envIsCanada()}
          isNormal={envIsNormal()}
          isInAppWrapper={isInAppWrapper}
        />
      </div>
    </footer>
  );
}
