export const appFlyerScript = (locale: string) => {
  const getKey = (locale: string) => {
    if (locale === 'th') {
      return 'fb7abfeb-f9e6-4d4d-9fff-fd9daff0842c';
    }
    if (locale === 'ca-fr') {
      return '6526d6bf-4286-4610-9938-ffc7af85314c';
    }
    if (locale === 'at') {
      return '17fb7820-719d-4b49-a5ef-16ee58920525';
    }
    if (locale === 'mx') {
      return 'e0ab981e-64a2-401f-8fe4-c13683c27ef2';
    }

    return '4416b76a-085f-4bfb-8d0a-31827eed5093';
  };

  return `!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "${getKey(
    locale,
  )}"}}); AF('banners', 'showBanner')`;
};
