import React from 'react';
import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	 iframe#launcher {
		 display: none;
	 }

	.fullscreen-enabled {
		overflow-y: scroll;
		background: ${props => props.theme.colors.offWhite};
	}

	body {
		margin: 0;
		padding: 0;
		font-family: 'Karla', sans-serif;
		text-align: left;
		font-size: 16px;
		line-height: 1.6;
		font-weight: normal;
		font-style: normal;
		background: ${props => props.theme.colors.offWhite};
		color: ${props => props.theme.colors.grey100};
		-webkit-font-smoothing: antialiased;
		text-rendering: optimizeLegibility;
		box-sizing: border-box;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			font-size: 16px;
		}

		*:focus {
			outline: none;
		}

      &.single-game {
         #thumb-nav {
            display: none;
         }

         #footer {
            display: none;
         }
      }

      &.drawer-open, &.ReactModal__Body--open {
         overflow: hidden;
         }
	   }

      &.single-game {
         @media (max-width: ${props => props.theme.breakpoints.medium}) {
            height: 830px;
            overflow: inherit;
            #header {
               display: none;
            }
         }

      ${props =>
        props.$isMobile &&
        css`
          overflow: hidden;
          #header {
            display: none;
          }
        `}
         }

	h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, h6 {
		font-family: 'Avenir Next', sans-serif;
		color: ${props => props.theme.colors.blue3};
		font-weight: bold;
		line-height: 1.2;
		margin: 1.414em 0 0.5em;

		/* -ms-word-break: break-all;
		word-break: break-all;
		word-break: break-word;

		-webkit-hyphens: auto;
		-moz-hyphens: auto;
		hyphens: auto; */
	}

	h1, .h1 {
		margin-top: 0;
		font-size: 48px;


		@media (min-width: ${props => props.theme.breakpoints.small}) {
			font-size: 54px;
		}
	}

	h2, .h2 {
		font-size: 38px;

	}

	h3, .h3 {
		font-size: 1.4rem;

	}

	h4, .h4 {
		font-size: 1.3rem;
	}

   h5 {
     font-size: 1.2rem;
   }

   h6 {
     font-size: 1rem;
   }

	p.subtitle {
		font-size: 18px;
		margin-bottom: 1em;

		@media (min-width: ${props => props.theme.breakpoints.small}) {
			font-size: 22px;
		}
	}

	p, li {
		margin: 0 0 1.3em 0;
	}

	ul {
		padding: 0 0 0 1em;
	}

	small, .font_small {font-size: 0.75em;}

	strong {
		font-weight: bold;
	}

	button, input {
		font-family: inherit;
	}

	input {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	/* Change autocomplete styles in WebKit */
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	textarea:-webkit-autofill,
	textarea:-webkit-autofill:hover,
	textarea:-webkit-autofill:focus,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus {
		background-color: none;
	}


	.tooltip-container {

		&:hover {
			.tooltip {
				visibility: visible;
				opacity: 1;

				&.tooltip-right,
				&.tooltip-left {
					transform: translate(0, -50%);
				}

				&.tooltip-top,
				&.tooltip-bottom {
					transform: translate(-50%, 0);
				}

				&.tooltip-bottom-right,
				&.tooltip-bottom-left,
				&.tooltip-top-right,
				&.tooltiå-top-left {
					transform: translate(0, 0);
				}
			}
		}
	}

	* {
		box-sizing: border-box;
	}

	.relative {
		position: relative;
	}

    @media (min-width: ${props => props.theme.breakpoints.small}) {
        .hide-on-medium {
            display: none;
        }
    }

	@media (min-width: ${props => props.theme.breakpoints.medium}) {
		.hide-on-large {
			display: none;
		}
	}
`;

const GlobalStyles = () => <GlobalStyle />;

export default GlobalStyles;
