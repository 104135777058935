import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { User } from '@/models/user.model';
import { getLocaleByCountryCode, isEmpty } from '@/shared/utility';
import { BUILD_MODE, FRENCH_ALPHA_CODES, IS_SWEDEN } from '@/constants/constants';
import { logout } from '@/store/actions/auth';
import { selectWrapperPlatform } from '@/store/selectors/commonSelectors';
import { WrapperPlatform } from '@/models/enum/wrapper-platform.enum';

const getPathWithPlatform = (path: string, wrapperPlatform: WrapperPlatform): string => {
  if (wrapperPlatform === WrapperPlatform.None) {
    return path;
  }

  return `${path}?platform=${wrapperPlatform}`;
};

const useUserLocaleRedirect = (): void => {
  const user = useSelector((state: any) => state.user.user);
  const isAuthenticated = useSelector((state: any) => state.auth.token !== null);
  const userLoading = useSelector((state: any) => state.user.userLoading);
  const locale = useSelector((state: any) => state.common.locale);
  const location = useLocation();
  const dispatch = useDispatch();
  const wrapperPlatform = useSelector(selectWrapperPlatform);

  function redirectBuildModeNormal(
    user: User,
    locale: string,
    wrapperPlatform: WrapperPlatform,
  ): void {
    let userLocale = getLocaleByCountryCode(user.CountryCode);

    if (userLocale === 'ca' && FRENCH_ALPHA_CODES.includes(user.LanguageAlphaCode)) {
      userLocale = 'ca-fr';
    }

    if (userLocale === locale) {
      return;
    }

    const path = location.pathname.replace(locale, userLocale);
    window.location.replace(getPathWithPlatform(path, wrapperPlatform));
  }

  function redirectBuildModeCanada(user: User, locale: string): void {
    const userLocale = getLocaleByCountryCode(user.CountryCode);

    // If the user is not a canadian user
    // log out the user from this build
    if (userLocale !== 'ca') {
      dispatch(logout());
      return;
    }

    const languageLocale = FRENCH_ALPHA_CODES.includes(user.LanguageAlphaCode) ? 'ca-fr' : 'ca';

    // You're already on ca locale with english language
    // or you're on /fr with french language
    if (languageLocale === locale) {
      return;
    }

    if (languageLocale === 'ca') {
      window.location.replace('/');
      return;
    }

    window.location.replace('/fr');
  }

  useEffect(() => {
    if (isAuthenticated && !isEmpty(user) && !userLoading) {
      if (IS_SWEDEN) {
        return;
      }

      if (BUILD_MODE === 'canada') {
        redirectBuildModeCanada(user, locale);
        return;
      }

      redirectBuildModeNormal(user, locale, wrapperPlatform);
    }
  }, [user, isAuthenticated, userLoading, wrapperPlatform]);
};

export default useUserLocaleRedirect;
