import { Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { usePrivacyPolicyLoader } from '@/hooks/usePrivacyPolicyLoader';
import { useTermsAndConditionsLoader } from '@/hooks/useTermsAndConditionsLoader';
import Spinner from '../UI/Spinner';
import { useIntl } from 'react-intl';
import { ConsentView } from '@/models/enum/consent-view.enum';
import ConsentsModalTerms from '../ConsentsModalTerms';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import ApiService from '../../shared/apiService';
import { getConsents } from '@/store/actions/consentsActions';
import { useDispatch, useSelector } from 'react-redux';
import { selectMandatoryConsents } from '@/store/selectors/consentsSelectors';

export function MigratedUserConsentsModal({ closeModal }: { closeModal: () => void }): JSX.Element {
  const [view, setView] = useState<ConsentView>(ConsentView.Consent);
  const absoluteNavigate = useAbsoluteNavigate();
  const { tcData, tcLoading } = useTermsAndConditionsLoader();
  const { ppData, ppLoading } = usePrivacyPolicyLoader();
  const intl = useIntl();
  const { success, loading, request: saveConsents } = useApiV2Request();
  const dispatch = useDispatch();
  const consents = useSelector(selectMandatoryConsents);

  const handleSaveConsents = () => {
    const approvedConsents = consents.map(consent => {
      return {
        ConsentId: consent.ConsentId,
        Version: consent.Version,
        Consented: true,
      };
    });
    saveConsents(ApiService.saveConsents, approvedConsents, () => {
      closeModal();
      dispatch(getConsents());
    });
  };

  useEffect(() => {
    if (success) {
      closeModal();
    }
  }, [success]);

  return (
    <div className="relative min-h-[601px] w-full max-w-sm overflow-hidden rounded-2xl bg-white text-left">
      <Transition
        show={view === ConsentView.Consent}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="absolute inset-0 flex flex-col justify-between space-y-4 p-8">
          <div>
            <h1 className="mb-4 text-xl">
              {intl.formatMessage({ id: 'ontario.consents.header' })}
            </h1>
            <p>{intl.formatMessage({ id: 'ontario.consents.text' })}</p>
            <ul className="mt-4 list-inside list-disc rounded-lg bg-gray-100 p-4 text-sm">
              <li className="mb-0">
                <button
                  type="button"
                  className="underline hover:no-underline"
                  onClick={() => setView(ConsentView.Terms)}
                >
                  {intl.formatMessage({ id: 'consentModal.termsAndConditions' })}
                </button>
              </li>
              <li className="mb-0 mt-3">
                <button
                  type="button"
                  className="underline hover:no-underline"
                  onClick={() => setView(ConsentView.Privacy)}
                >
                  {intl.formatMessage({ id: 'consentModal.privacyAndCookieNotice' })}
                </button>
              </li>
              <li className="mb-0 mt-3">{intl.formatMessage({ id: 'ontario.consents.pep' })}</li>
              <li className="mb-0 mt-3">{intl.formatMessage({ id: 'ontario.consents.myself' })}</li>
              <li className="mb-0 mt-3">{intl.formatMessage({ id: 'ontario.consents.fit' })}</li>
            </ul>
          </div>
          <div className="mt-6 space-y-4">
            <button
              className="h-12 w-full rounded-full bg-cyan-500 px-4 text-center font-bold text-white shadow-lg transition hover:bg-cyan-600 hover:shadow-xl"
              type="button"
              onClick={() => handleSaveConsents()}
            >
              {loading ? (
                <Spinner width={20} height={20} borderwidth={4} color="white" />
              ) : (
                intl.formatMessage({ id: 'consentModal.approve' })
              )}
            </button>
            <button
              className="h-12 w-full rounded-full bg-gray-200 px-4 text-center font-bold transition hover:bg-gray-300"
              type="button"
              onClick={() => {
                closeModal();
                absoluteNavigate('/logout');
              }}
            >
              {intl.formatMessage({ id: 'header.logout' })}
            </button>
          </div>
        </div>
      </Transition>
      <Transition
        show={view === ConsentView.Terms}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {tcData && (
          <ConsentsModalTerms
            content={tcData}
            contentLoading={tcLoading}
            callback={() => setView(ConsentView.Consent)}
          />
        )}
      </Transition>
      <Transition
        show={view === ConsentView.Privacy}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {ppData && (
          <ConsentsModalTerms
            content={ppData}
            contentLoading={ppLoading}
            callback={() => setView(ConsentView.Consent)}
          />
        )}
      </Transition>
    </div>
  );
}
