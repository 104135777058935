import React from 'react';
import classNames from 'classnames';

export function Button({
  onClickHandler,
  isLoading = false,
  text,
  disabled = false,
  type = 'button',
  btnType = 'primary',
  size = 'm',
  'data-cy': dataCy,
  className = '',
}: {
  onClickHandler?: () => void;
  isLoading?: boolean;
  text: string;
  disabled?: boolean;
  type?: 'button' | 'submit';
  btnType?: 'primary' | 'secondary' | 'outline';
  size?: 'xs' | 's' | 'm';
  'data-cy'?: string;
  className?: string;
}): JSX.Element {
  return (
    <button
      data-cy={dataCy}
      type={type === 'submit' ? 'submit' : 'button'}
      className={classNames(
        'relative w-full rounded-3xl transition disabled:cursor-not-allowed disabled:opacity-50',
        {
          'bg-cyan-500 hover:bg-cyan-600 text-white': btnType === 'primary',
          'bg-white/20 hover:bg-white/30 text-white': btnType === 'secondary',
          'border-2 border-cyan-500 bg-white text-cyan-500 hover:border-cyan-600 hover:text-cyan-600':
            btnType === 'outline',
          'p-2': size === 's',
          'p-3': size === 'm',
          'p-1 text-xs': size === 'xs',
        },
        className,
      )}
      onClick={onClickHandler}
      disabled={isLoading || disabled}
    >
      {isLoading && (
        <div className="flex justify-center items-center relative">
          <div className=" absolute w-6 h-6 top-0 rounded-full border-4 border-white/20 border-l-white animate-spin" />
        </div>
      )}
      <span className={`text-md font-bold leading-6 ${isLoading ? 'opacity-0' : ''}`}>{text}</span>
    </button>
  );
}
