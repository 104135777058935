import React, { lazy, Suspense, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { GamesSearch } from './GamesSearch';
import { envIsMexico } from '@/constants/constants';
import GameCategoryNavMexico from '@/pages/games/GameCategoryNavMexico';
import GamesHeader from '@/pages/games/header/GamesHeader';

const GameCategoryNav = lazy(() => import('./GameCategoryNav'));

function Games(): JSX.Element {
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState('');

  return (
    <div>
      <Helmet>
        <title>LuckyDays - {intl.formatMessage({ id: 'header.games' })}</title>
      </Helmet>
      <Suspense fallback={<div />}>
        <GamesHeader searchValue={searchValue} setSearchValue={setSearchValue} />
        {!envIsMexico() && <GameCategoryNav />}
        {envIsMexico() && <GameCategoryNavMexico />}
      </Suspense>
      {searchValue && searchValue.length > 0 ? (
        <GamesSearch searchValue={searchValue} />
      ) : (
        <Outlet />
      )}
    </div>
  );
}

export default Games;
