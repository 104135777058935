import { useEffect } from 'react';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import { BffStaticTextContentResponse, CmsStaticTextContentType } from '@lucky7ventures/bff-types';
import BffApiService from '@/shared/bffApiService';
import { useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';

export const useTermsAndConditionsLoader = () => {
  const locale = useSelector(selectLocale);
  const { request, data, loading } = useBffApiRequest<BffStaticTextContentResponse>();

  useEffect(() => {
    request({
      apiMethod: BffApiService.getStaticTextContent,
      axiosConfig: { params: { contentType: CmsStaticTextContentType.termsAndConditions } },
    });
  }, [locale]);

  return { tcData: data, tcLoading: loading };
};
