import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { useEffect } from 'react';
import { envIsNormal, envIsSweden } from '@/constants/constants';
import { getBonusIntroCards } from '@/store/cms/bonus-intro/actions';

export const useBonusIntroCardsLoader = () => {
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!locale) {
      return;
    }

    if ((envIsNormal() && ['fi', 'fi-en'].includes(locale)) || envIsSweden()) return;
    dispatch(getBonusIntroCards());
  }, [locale]);
};
