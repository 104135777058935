import { useLocation } from 'react-router-dom';
import { HeaderVariant } from '@/models/enum/header-variant.enum';
import { useMemo } from 'react';

function useHeaderVariant(): HeaderVariant {
  const { pathname } = useLocation();

  return useMemo(() => {
    // for Promotion pages we want to have a solid header
    if (pathname.includes('/promotion/')) {
      return HeaderVariant.Solid;
    }

    return HeaderVariant.Default;
  }, [pathname]);
}

export default useHeaderVariant;
