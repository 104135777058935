import {
  all,
  AllEffect,
  ForkEffect,
  StrictEffect,
  put,
  call,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { BffCmsWelcomeTextsResponse, BffResponse } from '@lucky7ventures/bff-types';
import BffApiService from '@/shared/bffApiService';
import {
  WelcomeTextsActionType,
  getWelcomeTextsError,
  getWelcomeTextsSuccess,
} from '@/store/cms/welcome-texts/actions';
import { selectBtagPrefix } from '@/store/selectors/commonSelectors';

export function* getWelcomeTextsSaga(): Generator<
  StrictEffect,
  void,
  AxiosResponse<BffResponse<BffCmsWelcomeTextsResponse>>
> {
  const btag = yield select(selectBtagPrefix);

  try {
    const response = yield call(BffApiService.getWelcomeTexts, { config: { params: { btag } } });

    if (response.data.data) {
      yield put(getWelcomeTextsSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getWelcomeTextsError());
  }
}

export function* watchWelcomeTextsSagas(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(WelcomeTextsActionType.GetWelcomeTexts, getWelcomeTextsSaga)]);
}
