/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import { isEmpty } from '@/shared/utility';
import { ToastList } from '@/components/ToastList';
import { getNonExpiredClosedToasts, saveClosedToast } from '@/utils/localStorage.toasts';
import { hashCode } from '@/utils/string.utils';
import { hasLowBalance } from '@/utils/hasLowBalance';
import { useWalletTotalBalance } from '@/hooks/useWalletTotalBalance';
import { useGracePeriodToast } from '@/hooks/useGracePeriodToast';
import { useAmlVerificationToast } from '@/hooks/useAmlVerificationToast';
import { useUpdateContactInfoToast } from '@/hooks/useUpdateContactInfoToast';
import { IS_SWEDEN } from '@/constants/constants';
import { selectDepositCount, selectUser } from '@/store/selectors/userSelectors';
import { useStateUpdateToast } from '@/hooks/useStateUpdateToast';
import { selectUserManualBonuses } from '@/store/selectors/manualBonusesSelectors';
import { selectAutoLoginFinished } from '@/store/selectors/autoLoginSelectors';
import { selectReferrerContent } from '@/store/selectors/wordpressSelectors';
import { usePhotoIdToast } from '@/hooks/usePhotoIdToast';
import { useResponsibleGamingToast } from '@/hooks/useResponsibleGamingToast';
import { useKYCToast } from '@/hooks/useKYCToast';
import {
  SMS_MAIL_REMINDER_TOAST_ID,
  useSmsMailReminderToast,
} from '@/hooks/useSmsMailReminderToast';
import { useRegistration } from '@/hooks/useRegistration';
import { useCashierShow } from '@/features/cashier/useCashier';
import { selectAvailableBonuses } from '@/store/selectors/bonusesSelectors';
import { selectUserBtag } from '@/store/selectors/combined.selectors';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import { usePsaStore } from '@/store/zustand/psa-store';

const StyledToastsContainer = styled.div`
  position: absolute;
  z-index: 50;
  top: ${props => (props.psaShown ? '116px' : '80px')};
  right: 36px;

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    top: 1rem;
    left: 16px;
    right: 16px;
  }
`;

export function ToastsContainer() {
  const isAuthenticated = useSelector(state => state.auth.token !== null);
  const user = useSelector(selectUser);
  const depositCount = useSelector(selectDepositCount);
  const availableBonuses = useSelector(selectAvailableBonuses);
  const manualBonuses = useSelector(selectUserManualBonuses);
  const locale = useSelector(state => state.common.locale);
  const intl = useIntl();
  const dispatch = useDispatch();
  const btag = useSelector(selectUserBtag);
  const referrerContent = useSelector(selectReferrerContent);
  const wallet = useSelector(state => state.user.wallet);
  const autoLoginFinished = useSelector(selectAutoLoginFinished);
  const { psaMessage } = usePsaStore();
  const { openRegistration } = useRegistration();

  const [toastMessages, setToastMessages] = useState({});
  const [previouslyClosedToasts, setPreviouslyClosedToasts] = useState(getNonExpiredClosedToasts());
  const walletTotalBalance = useWalletTotalBalance();
  const showCashier = useCashierShow();

  const toastsToDisplay = React.useMemo(
    () =>
      Object.values(toastMessages).filter(toast => previouslyClosedToasts.indexOf(toast.id) === -1),
    [toastMessages, previouslyClosedToasts],
  );

  const doesToastExist = id => !!toastMessages[id];

  const scrollToElement = elementId => () => {
    const element = document.getElementById(elementId);
    if (element !== null) {
      element.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  const closeToast = toastId => () => {
    // Closed toasts renew in 7 days, but we set
    // the SMS/Mail reminder toast to renew in 2 weeks
    switch (toastId) {
      case SMS_MAIL_REMINDER_TOAST_ID:
        saveClosedToast(toastId, 14);
        break;
      default:
        saveClosedToast(toastId, 7);
        break;
    }

    setPreviouslyClosedToasts(getNonExpiredClosedToasts());
  };

  const addToastMessage = message => {
    setToastMessages(prevState => ({ ...prevState, [message.id]: message }));
  };

  const addToastMessages = messages => {
    const messagesObj = messages.reduce((acc, message) => ({ ...acc, [message.id]: message }), {});
    setToastMessages(prevState => ({ ...prevState, ...messagesObj }));
  };

  const cleanupToastMessages = key => {
    setToastMessages(prevState => {
      const remainingKeys = Object.keys(prevState).filter(k => k.indexOf(key) === -1);
      return remainingKeys.reduce((a, i) => ({ ...a, [i]: prevState[i] }), {});
    });
  };

  const cleanupToastMessagesByKeys = keys => {
    keys.forEach(key => {
      cleanupToastMessages(key);
    });
  };

  useGracePeriodToast(addToastMessage, cleanupToastMessages);
  useAmlVerificationToast(addToastMessage, cleanupToastMessages);
  useUpdateContactInfoToast(addToastMessage, cleanupToastMessages);
  useStateUpdateToast(addToastMessage, cleanupToastMessages);
  usePhotoIdToast(addToastMessage, cleanupToastMessages);
  useResponsibleGamingToast(addToastMessage, cleanupToastMessages);
  useKYCToast(addToastMessage, cleanupToastMessages);
  useSmsMailReminderToast(addToastMessage, cleanupToastMessages, closeToast);
  // We comment this for now so the switch toast doesnt show before we release .ca
  // useOntarioSwitchToast(addToastMessage, cleanupToastMessages);

  // #2 Add availableBonuses Toasts
  useEffect(() => {
    if (!isAuthenticated || isEmpty(user)) {
      cleanupToastMessages('bonus-notification-');
      return;
    }

    // Add all messages from availableBonuses
    const availableBonusesToasts = availableBonuses.map(bonus => ({
      id: `bonus-notification-${bonus.bonusId}`,
      order: 2,
      title: bonus.notificationHeader,
      subtitle: bonus.notificationText,
      cta: intl.formatMessage({ id: 'misc.moreInfo' }),
      clickCallback: scrollToElement(`bonus-card-${bonus.bonusId}`),
      closeCallback: closeToast(`bonus-notification-${bonus.bonusId}`),
    }));

    // cleanup all the bonuses toasts that shouldn't be there anymore
    const toastsIds = availableBonusesToasts.map(toast => toast.id);
    const toastsToCleanup = Object.keys(toastMessages).filter(
      id => id.indexOf('bonus-notification-') !== -1 && !toastsIds.includes(id),
    );
    cleanupToastMessagesByKeys(toastsToCleanup);

    addToastMessages(availableBonusesToasts);
  }, [availableBonuses, intl, isAuthenticated, user]);

  // #3 Add manualBonuses Toasts
  useEffect(() => {
    if (!isAuthenticated || isEmpty(user)) {
      cleanupToastMessages('manual-bonus-card-');
      return;
    }

    // Add all messages from availableBonuses
    const manualBonusesToasts = manualBonuses
      .filter(bonus => {
        if (bonus.available_for_all) {
          return true;
        }

        const availableForList = bonus.available_for_list;
        return (
          !isEmpty(availableForList) && availableForList.split(',').includes(user.UserID.toString())
        );
      })
      .map((bonus, index) => {
        const manualBonusHash = hashCode(
          `${bonus.notification_header}-${bonus.notification_text}-${bonus.expiry_date}`,
        );
        const manualBonusId = `manual-bonus-card-${manualBonusHash}`;

        return {
          id: manualBonusId,
          order: 3,
          title: bonus.notification_header,
          subtitle: bonus.notification_text,
          cta: intl.formatMessage({ id: 'misc.moreInfo' }),
          clickCallback: scrollToElement(`manual-bonus-card-${index}`),
          closeCallback: closeToast(manualBonusId),
        };
      });

    addToastMessages(manualBonusesToasts);
  }, [intl, isAuthenticated, manualBonuses, user]);

  // #4 Referrer Content toast
  useEffect(() => {
    const referrerContentToastId = 'referrer-content';
    if (isAuthenticated || isEmpty(btag) || isEmpty(referrerContent)) {
      cleanupToastMessages(referrerContentToastId);
      return;
    }

    const toast = {
      id: referrerContentToastId,
      order: 1,
      title: referrerContent.notification_header,
      subtitle: referrerContent.notification_text,
      cta: intl.formatMessage({ id: 'notifications.referrerContentCta' }),
      type: 'cta',
      clickCallback: () => openRegistration({ openedFrom: OpenedFrom.ReferrerToast }),
    };

    addToastMessage(toast);
  }, [btag, referrerContent, isAuthenticated, locale, dispatch, intl]);

  useEffect(() => {
    const lowBalanceToast = 'low-balance';

    if (
      !isAuthenticated ||
      !depositCount ||
      walletTotalBalance === null ||
      !hasLowBalance(depositCount, walletTotalBalance, wallet.Currency)
    ) {
      cleanupToastMessages(lowBalanceToast);
      return;
    }

    if (doesToastExist(lowBalanceToast)) {
      return;
    }

    // add the toast if it already doesn't exist
    const toast = {
      id: lowBalanceToast,
      order: 1,
      title: intl.formatMessage({ id: 'notifications.lowBalanceTitle' }),
      subtitle: intl.formatMessage({ id: 'notifications.lowBalanceSubtitle' }),
      cta: intl.formatMessage({ id: 'notifications.lowBalanceCta' }),
      type: 'cta',
      clickCallback: () => {
        triggerGTag(GTagEvents.low_balance_cta);
        showCashier();
      },
    };

    addToastMessage(toast);
    triggerGTag(GTagEvents.low_balance_view);
  }, [isAuthenticated, depositCount, walletTotalBalance, wallet.Currency]);

  return (
    <StyledToastsContainer
      shouldOffset={!isAuthenticated}
      isSweden={IS_SWEDEN}
      psaShown={psaMessage}
    >
      {autoLoginFinished && <ToastList messages={toastsToDisplay} />}
    </StyledToastsContainer>
  );
}
