import { create } from 'zustand';
import { IndustryResponse, Occupation } from '@lucky7ventures/lucky-components';

import ApiService from '@/shared/apiService';

interface IndustriesState {
  industries: IndustryResponse[];
  loading: boolean;
  error: boolean;
}

interface OccupationsState {
  occupations: Occupation[];
  loading: boolean;
  error: boolean;
}

interface Actions {
  fetch: () => void;
}

export const useIndustriesStore = create<IndustriesState & Actions>((set, get) => ({
  industries: [],
  loading: false,
  error: false,
  fetch: async () => {
    const { industries } = get();
    if (industries.length > 0) {
      return;
    }

    set({
      loading: true,
      error: false,
    });
    try {
      const response = await ApiService.getIndustries();
      set({
        loading: false,
        error: false,
        industries: response.data,
      });
    } catch {
      set({
        loading: false,
        error: true,
      });
    }
  },
}));

export const useOccupationsStore = create<OccupationsState & Actions>((set, get) => ({
  occupations: [],
  loading: false,
  error: false,
  fetch: async () => {
    const { occupations } = get();
    if (occupations.length > 0) {
      return;
    }

    set({
      loading: true,
      error: false,
    });
    try {
      const response = await ApiService.getOccupations({
        regulator: 'agco',
      });
      set({
        loading: false,
        error: false,
        occupations: response.data,
      });
    } catch {
      set({
        loading: false,
        error: true,
      });
    }
  },
}));
