import React, { ReactNode } from 'react';

import classNames from 'classnames';
import TransparentCard from '@/components/shared/card/TransparentCard';
import { isEmpty } from '@/shared/utility';

interface HomeHeroAuthenticationStepLayoutProps {
  children: ReactNode;
  heading?: string | ReactNode;
  maxWidth?: number;
}

const HomeHeroAuthenticationStepLayout = ({
  children,
  heading,
  maxWidth: setMaxWidth,
}: HomeHeroAuthenticationStepLayoutProps) => {
  const maxWidth = !isEmpty(setMaxWidth) ? `max-w-[${setMaxWidth}px]` : undefined;
  return (
    <div
      className={classNames(
        'w-full relative text-center mb-8 mt-10 md:mt-24 px-[16px] md:px-0',
        {
          'max-w-[480px]': isEmpty(setMaxWidth),
        },
        maxWidth,
      )}
    >
      {heading && (
        <h1
          data-cy="home-hero-authentication-heading"
          className="!mb-6 text-white text-center sm:mx-4 text-2xl [&_strong]:text-blue-blue"
        >
          {heading}
        </h1>
      )}
      <TransparentCard>{children}</TransparentCard>
    </div>
  );
};

export default HomeHeroAuthenticationStepLayout;
