import React from 'react';
import HomeHeroContentPublicPayNPlay from './HomeHeroContentPublicPayNPlay';
import { isRegulatedLocale } from '@/shared/utility';
import HomeHeroAuthentication from '@/pages/home/hero/HomeHeroAuthentication';

const HomeHeroContentPublic = ({ locale }: { locale: string }): JSX.Element => {
  if (isRegulatedLocale(locale)) {
    return <HomeHeroContentPublicPayNPlay />;
  }

  return <HomeHeroAuthentication />;
};

export default HomeHeroContentPublic;
