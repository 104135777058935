import React from 'react';
import { shallow } from 'zustand/shallow';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useCookieConsentStore } from '@/store/zustand/cookie-consent-store';
import { openModal } from '@/store/actions/modal';
const CookieBanner = (): JSX.Element | null => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { accepted, acceptCookies } = useCookieConsentStore(
    state => ({ accepted: state.accepted, acceptCookies: state.acceptCookies }),
    shallow,
  );

  const openCookieSettings = () => {
    dispatch(openModal('cookies'));
  };

  const handleAcceptClick = () => {
    // if the user accepts from the banner we allow all cookies
    acceptCookies(true);
  };

  if (accepted) {
    return null;
  }

  return (
    <div className="fixed bottom-[64px] left-2 right-2 z-50 flex justify-center shadow-lg md:bottom-2">
      <div className="rounded-md bg-white p-3 text-sm sm:flex sm:items-center sm:justify-between sm:gap-3">
        <div className="mb-2 sm:mb-0">
          <div className="mb-1">
            <span className="font-bold">{intl.formatMessage({ id: 'cookies.banner.title' })}</span>{' '}
            🍪
          </div>
          <div className="text-xs">{intl.formatMessage({ id: 'cookies.banner.text' })}</div>
        </div>
        <div className="flex justify-end gap-3">
          <button
            type="button"
            onClick={openCookieSettings}
            className="min-w-[120px] rounded-md bg-slate-100 p-3"
          >
            {intl.formatMessage({ id: 'cookies.banner.manage' })}
          </button>
          <button
            type="button"
            className="min-w-[120px] rounded-md bg-cyan-500 p-3 font-bold text-white"
            onClick={handleAcceptClick}
          >
            {intl.formatMessage({ id: 'cookies.accept' })}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
