import React from 'react';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import EmailField from '@/components/form/email/EmailField';
import { Button } from '@/components/buttons/Button';
import Form from '@/components/form/Form';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepActions,
} from '@/store/authentication/AuthenticationStore';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import BffApiService from '@/shared/bffApiService';
import PromptMessage from '@/components/UI/PromptMessage';

const initialValues = {
  email: '',
};

const ForgotPasswordForm = () => {
  const intl = useIntl();
  const { request, loading, error } = useBffApiRequest();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage({ id: 'input.error.required' }))
      .email(intl.formatMessage({ id: 'inputs.email.invalid' })),
  });

  const { setHomeHeroAuthenticationStep } = useHomeHeroAuthenticationStepActions();

  const successCallback = () => {
    triggerGTag(GTagEvents.forgot_password_success);
    setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.ForgotPasswordSuccess);
  };

  const errorCallback = () => {
    triggerGTag(GTagEvents.forgot_password_error);
  };

  const handleSubmit = ({ email }: { email: string }) => {
    triggerGTag(GTagEvents.forgot_password_submit);
    request({
      apiMethod: BffApiService.forgotPassword,
      payload: { email },
      successCallback,
      errorCallback,
    });
  };

  const handleSwitchAuthMethod = () => {
    setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.Login);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      noValidate
      className="flex flex-col w-full justify-between gap-5"
    >
      {() => (
        <>
          <EmailField
            data-cy="forgot-password-form-email"
            label={intl.formatMessage({ id: 'inputs.email' })}
            name="email"
            className="text-white focus-within:border-blue-blue bg-white/20"
          />
          <div className="flex flex-col items-center mt-4">
            {error && <PromptMessage type="error" message={error} />}
            <Button
              text={intl.formatMessage({ id: 'authentication.forgot-password.button' })}
              type="submit"
              btnType="primary"
              data-cy="forgot-password-submit-button"
              isLoading={loading}
            />
            <div className="text-white text-[15px] text-center mt-6">
              <FormattedMessage
                id="authentication.forgot-password.change-to-login"
                values={{
                  a: str => (
                    <span
                      onClick={handleSwitchAuthMethod}
                      className="text-blue-blue font-bold underline cursor-pointer"
                    >
                      {str}
                    </span>
                  ),
                }}
              />
            </div>
            <p className="max-w-[80%] mt-3 block !text-[12px] italic text-white mb-0">
              {intl.formatMessage({ id: 'authentication.forgot-password.description' })}
            </p>
          </div>
        </>
      )}
    </Form>
  );
};

export default ForgotPasswordForm;
