import { useState } from 'react';

import { AxiosRequestConfig } from 'axios';

import { useCancelToken } from '@/hooks/useCancelToken';
import { bffApiErrorHandler } from '@/shared/errorHandler';
import { BffApiServiceI } from '@/shared/bffApiService';
import { InferMethodArgumentPropertyType } from '@/models/MethodObjectArgPropType.model';
import { BffApiError, FrontendErrorCode } from '@/models/bff-api-error';
import { isBffResponse } from '@/utils/type-guards';

type BffApiRequest<T> = <M extends BffApiServiceI[keyof BffApiServiceI]>(arg: {
  apiMethod: M;
  payload?: InferMethodArgumentPropertyType<M, 'payload'>;
  pathParams?: InferMethodArgumentPropertyType<M, 'pathParams'>;
  axiosConfig?: AxiosRequestConfig;
  successCallback?: (data: T) => void;
  errorCallback?: (error: BffApiError, fullError: unknown) => void;
}) => void;

interface Return<T> {
  loading: boolean;
  success: boolean;
  error: BffApiError | null;
  data: T | null;
  request: BffApiRequest<T>;
}

export function useBffApiRequest<T>(): Return<T> {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<BffApiError | null>(null);
  const { newCancelToken } = useCancelToken();

  const request: BffApiRequest<T> = arg => {
    setLoading(true);
    setSuccess(false);
    setError(null);
    const { apiMethod, payload, pathParams, axiosConfig, successCallback, errorCallback } = arg;
    const config = {
      ...axiosConfig,
      cancelToken: newCancelToken(),
    };
    // @ts-ignore
    apiMethod({ payload, pathParams, config })
      .then(response => {
        setLoading(false);
        // Special handling for responses 204, No content
        if (response.status && response.status === 204) {
          setSuccess(true);
          setData(null);
          if (successCallback) {
            // @ts-ignore
            successCallback(null);
          }
          return;
        }
        if (isBffResponse(response.data)) {
          setSuccess(true);
          // @ts-ignore
          setData(response.data.data);
          if (successCallback) {
            // @ts-ignore
            successCallback(response.data.data);
          }
        } else {
          setSuccess(false);
          const unexpectedFormatError = { code: FrontendErrorCode.UnexpectedResponseFormat };
          setError(unexpectedFormatError);
          if (errorCallback) {
            errorCallback(unexpectedFormatError, null);
          }
        }
      })
      .catch(error => {
        setLoading(false);
        setSuccess(false);
        const handledError = bffApiErrorHandler(error);
        setError(handledError);
        if (errorCallback) {
          errorCallback(handledError, error);
        }
      });
  };

  return { loading, success, error, data, request };
}
