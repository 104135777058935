import { WpReferrerContent } from '@/models/wp-referrer-content.model';
import { WordpressActions, WordpressActionType } from '../actions/wordpressActions';

export interface WordpressStateModel {
  referrerContent: WpReferrerContent | null;
  termsAndConditionsLocale: string | null;
}

const initialState: WordpressStateModel = {
  referrerContent: null,
  termsAndConditionsLocale: null,
};

export const wordpressReducer = (
  state = initialState,
  action: WordpressActions,
): WordpressStateModel => {
  switch (action.type) {
    case WordpressActionType.GetReferrerContentSuccess:
      return { ...state, referrerContent: action.content };
    default:
      return state;
  }
};
