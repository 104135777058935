import React, { useState } from 'react';
import ModalLayout from './ModalLayout';
import ToggleSwitch from '../UI/ToggleSwitch';
import { doNothing } from '@/utils/utils';
import { BtnOneLineBlue } from '../buttons/BtnOneLineBlue';
import { useCookieConsentStore } from '@/store/zustand/cookie-consent-store';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { FormattedMessage, useIntl } from 'react-intl';
import { AbsoluteLink } from '../AbsoluteLink';

const CookiesModal = ({ closeModal }: { closeModal: () => void }): JSX.Element => {
  const intl = useIntl();
  const [allowPerformance, setAllowPerformance] = useState(true);
  const acceptCookies = useCookieConsentStore(state => state.acceptCookies);
  const absoluteNavigate = useAbsoluteNavigate();
  const savePreferences = () => {
    acceptCookies(allowPerformance);
    closeModal();
  };

  const openCookiePolicy = () => {
    absoluteNavigate('/privacy-policy');
    closeModal();
  };

  return (
    <ModalLayout>
      <div className="mb-4 text-lg font-bold">
        <FormattedMessage id="cookies.modal.title" />
      </div>
      <div className="flex flex-col gap-4">
        <ToggleSwitch
          label={intl.formatMessage({ id: 'cookies.modal.essential' })}
          labelClass="font-bold"
          checked={true}
          disabled={true}
          onChange={doNothing}
        />
        <div className="mb-4 text-sm">
          {intl.formatMessage({ id: 'cookies.modal.essential.hint' })}
        </div>
        <ToggleSwitch
          label={intl.formatMessage({ id: 'cookies.modal.performance' })}
          labelClass="font-bold"
          checked={allowPerformance}
          disabled={false}
          onChange={setAllowPerformance}
        />
        <div className="text-sm">
          <FormattedMessage id="cookies.modal.performance.hint" />
        </div>
      </div>
      <div className="mt-8 text-sm">
        <FormattedMessage
          id="cookies.modal.performance.link"
          values={{
            a: str => (
              <AbsoluteLink to="/privacy-policy" onClick={openCookiePolicy} className="underline">
                {str}
              </AbsoluteLink>
            ),
          }}
        />
      </div>
      <div className="mt-12">
        <BtnOneLineBlue
          onClickHandler={savePreferences}
          isLoading={false}
          text={intl.formatMessage({ id: 'cookies.accept' })}
        />
      </div>
    </ModalLayout>
  );
};

export default CookiesModal;
