import { PromotionsActions, PromotionsActionType } from '@/store/cms/promotions/actions';
import { BffCmsPromotionResponse } from '@lucky7ventures/bff-types';

export interface PromotionsState {
  slugs: string[];
  promotions: Record<string, BffCmsPromotionResponse>;
  loading: boolean;
  error: boolean;
}

const initialState: PromotionsState = {
  slugs: [],
  promotions: {},
  loading: false,
  error: false,
};

export const promotionsReducer = (
  state = initialState,
  action: PromotionsActions,
): PromotionsState => {
  switch (action.type) {
    case PromotionsActionType.GetPromotions:
      return { ...state, loading: true, error: false };
    case PromotionsActionType.GetPromotionsError:
      return { ...state, slugs: [], promotions: {}, loading: false, error: true };
    case PromotionsActionType.GetPromotionsSuccess: {
      const slugs = action.promotions.map(i => i.slug);
      const promotions = action.promotions.reduce(
        (acc: Record<string, BffCmsPromotionResponse>, promotion: BffCmsPromotionResponse) => {
          acc[promotion.slug] = promotion;
          return acc;
        },
        {},
      );
      return {
        ...state,
        slugs,
        promotions,
        loading: false,
        error: false,
      };
    }
    default:
      return state;
  }
};
