import React from 'react';

import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { BonusRedeemType } from '@lucky7ventures/bff-types';

import BonusCard from '@/components/bonus/BonusCard';
import { selectActiveBonuses } from '@/store/selectors/bonusesSelectors';
import { isEmpty } from '@/shared/utility';

const ActiveBonuses = () => {
  const bonuses = useSelector(selectActiveBonuses);
  const intl = useIntl();

  return (
    <div className="max-w-[720px] mx-auto py-12 px-4 sm:py-16 sm:px-8">
      <div className="mb-12">
        <h1 className="mb-4 text-[22px]">{intl.formatMessage({ id: 'activeBonuses.header' })}</h1>
        <p>{intl.formatMessage({ id: 'activeBonuses.text' })}</p>
      </div>
      <div
        className="flex justify-between flex-col min-h-[480px] gap-4 items-center sm:items-stretch sm:flex-row sm:gap-8
        [&_.bonus-card]:m-0"
      >
        {!isEmpty(bonuses) ? (
          bonuses.map(bonus => (
            <BonusCard
              key={bonus.bonusId}
              id={`bonus-card-${bonus.bonusId}`}
              image={bonus.image}
              bonusCode={bonus.promoCode}
              userBonusId={bonus.userBonusId}
              bonusTerms={bonus.terms}
              bonusType={bonus.type}
              desc={bonus.description}
              availableUntilText={bonus.availableUntil}
              name={bonus.name}
              redeemTypeId={BonusRedeemType.ForfeitBonus}
              desktopGameLink={bonus.desktopGameLink}
              mobileGameLink={bonus.mobileGameLink}
              shortTerms={bonus.shortTerms}
              realBonusMoney={bonus.realBonusMoney}
              bonusMoney={bonus.bonusMoney}
            />
          ))
        ) : (
          <p>{intl.formatMessage({ id: 'activeBonuses.noActiveBonuses' })}</p>
        )}
      </div>
    </div>
  );
};

export default ActiveBonuses;
