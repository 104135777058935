import React, { useEffect, useState } from 'react';

import { Field, FieldProps, Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import 'react-phone-number-input/style.css';

import { selectLocale } from '@/store/selectors/commonSelectors';
import { logException } from '@/utils/logger';
import { getAge } from '@/lib/getAge';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { COUNTRY_PHONE_PREFIX } from '@/constants/phonePrefixes';
import { getUser, getUserTags } from '@/store/actions/user';
import { FormInput } from '@/components/FormInput';
import { MobileInput } from '@/components/MobileInput';
import PromptMessage from '@/components/UI/PromptMessage';
import { Button } from '@/components/buttons/Button';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import BffApiService from '@/shared/bffApiService';
import { BffApiError } from '@/models/bff-api-error';
import { BffErrorCode } from '@lucky7ventures/bff-types';
import { generateBffErrorMessageWithCode } from '@/utils/utils';

interface UserProfileCompletionForm {
  address: string;
  city: string;
  postCode: string;
  year: string;
  month: string;
  day: string;
  mobile: {
    number: string;
    country: string;
    prefix: string;
    wholeNumber: string;
  };
}

type CompleteFastRegProfileProps = {
  onSuccessCallback?: () => void;
};

const CompleteFastRegProfile = ({ onSuccessCallback }: CompleteFastRegProfileProps) => {
  const [error, setError] = useState('');
  const { request, loading } = useBffApiRequest();
  const locale = useSelector(selectLocale);
  const token = useSelector(selectAuthToken);
  const intl = useIntl();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    address: Yup.string().required(
      intl.formatMessage({ id: 'completeProfile.input.error.required' }),
    ),
    city: Yup.string().required(intl.formatMessage({ id: 'completeProfile.input.error.required' })),
    postCode: Yup.string().required(
      intl.formatMessage({ id: 'completeProfile.input.error.required' }),
    ),
    year: Yup.string()
      .required(intl.formatMessage({ id: 'completeProfile.input.error.required' }))
      .matches(
        /^(19[2-9]\d|2[0-9]{3})$/,
        intl.formatMessage({ id: 'completeProfile.input.error.format' }),
      ),
    month: Yup.string()
      .required(intl.formatMessage({ id: 'completeProfile.input.error.required' }))
      .matches(
        /^(0[1-9]|(1)[0-2])/,
        intl.formatMessage({ id: 'completeProfile.input.error.format' }),
      ),
    day: Yup.string()
      .required(intl.formatMessage({ id: 'completeProfile.input.error.required' }))
      .matches(
        /^(0[1-9]|[12]\d|3[01])/,
        intl.formatMessage({ id: 'completeProfile.input.error.format' }),
      ),
    mobile: Yup.object().shape({
      wholeNumber: Yup.string()
        .min(6, intl.formatMessage({ id: 'completeProfile.mobile.error' }))
        .required(intl.formatMessage({ id: 'completeProfile.input.error.required' })),
    }),
  });

  const getInitialCountry = () => {
    switch (locale) {
      case 'ae':
      case 'ca':
      case 'at':
      case 'no':
      case 'nz':
      case 'th':
      case 'za':
        return locale.toUpperCase();
      case 'ca-fr':
        return 'CA';
      default:
        return 'IE';
    }
  };

  const getInitialMobilePrefix = () => {
    switch (locale) {
      case 'ae':
      case 'ca':
      case 'at':
      case 'no':
      case 'nz':
      case 'th':
      case 'za':
        return COUNTRY_PHONE_PREFIX[locale.toUpperCase()];
      case 'ca-fr':
        return COUNTRY_PHONE_PREFIX.CA;
      default:
        return COUNTRY_PHONE_PREFIX.IE;
    }
  };

  const initialValues: UserProfileCompletionForm = {
    address: '',
    city: '',
    postCode: '',
    year: '',
    month: '',
    day: '',
    mobile: {
      number: '',
      country: getInitialCountry(),
      prefix: getInitialMobilePrefix(),
      wholeNumber: getInitialMobilePrefix(),
    },
  };

  const submitHandler = async (values: UserProfileCompletionForm) => {
    setError('');

    const birthday = `${values.year}-${values.month}-${values.day}`;
    const age = getAge(birthday);

    if (locale === 'ca' || locale === 'ca-fr') {
      // The consent age for Canada (/ca and /ca-fr) is 19+
      if (age < 19) {
        setError(intl.formatMessage({ id: 'inputs.birthdate.invalid' }));
        return;
      }
    } else {
      // The consent age for the rest of .com is 18+
      if (age < 18) {
        setError(intl.formatMessage({ id: 'completeProfile.over18' }));
        return;
      }
    }

    const handleSuccess = () => {
      dispatch(getUser(token));
      dispatch(getUserTags(token));
      triggerGTag(GTagEvents.complete_profile_success);
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    };

    const handleError = (error: BffApiError) => {
      if (error.code === BffErrorCode.GIG_MOBILE_AND_PREFIX_COMBINATION_ALREADY_REGISTERED) {
        setError(intl.formatMessage({ id: 'completeProfile.error.duplicateMobile' }));
      } else {
        const errorMessage = generateBffErrorMessageWithCode(
          intl.formatMessage({
            id: 'error.support',
          }),
          error.code,
        );
        setError(errorMessage);
      }
      triggerGTag(GTagEvents.complete_profile_failed, {
        error: `bff-error-${error.code}`,
      });
      logException(error);
    };

    request({
      apiMethod: BffApiService.completeProfile,
      payload: {
        dob: birthday,
        phone: {
          prefix: values.mobile.prefix,
          number: values.mobile.number,
        },
        address: values.address.trim(),
        city: values.city.trim(),
        postCode: values.postCode.trim(),
      },
      successCallback: handleSuccess,
      errorCallback: handleError,
    });
    triggerGTag(GTagEvents.complete_profile_submit_click);
  };

  useEffect(() => {
    triggerGTag(GTagEvents.complete_profile_modal_open);
  }, []);

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="text-xl leading-[1] mb-6 opacity-80">
        {intl.formatMessage({ id: 'completeProfile.header' })}
      </div>
      <p className="text-center">{intl.formatMessage({ id: 'completeProfile.text' })}</p>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => submitHandler(values)}
          validateOnMount
        >
          {({ isValid }) => (
            <Form>
              <Field name="address">
                {({ field }: FieldProps) => (
                  <FormInput
                    label={intl.formatMessage({ id: 'completeProfile.address.label' })}
                    type="text"
                    autoComplete="street-address"
                    placeholder={intl.formatMessage({
                      id: 'completeProfile.address.placeholder',
                    })}
                    {...field}
                  />
                )}
              </Field>
              <Field name="city">
                {({ field }: FieldProps) => (
                  <FormInput
                    label={intl.formatMessage({ id: 'completeProfile.city.label' })}
                    type="text"
                    autoComplete="address-level2"
                    placeholder={intl.formatMessage({ id: 'completeProfile.city.placeholder' })}
                    {...field}
                  />
                )}
              </Field>
              <Field name="postCode">
                {({ field }: FieldProps) => (
                  <FormInput
                    label={intl.formatMessage({ id: 'completeProfile.postCode.label' })}
                    type="text"
                    autoComplete="postal-code"
                    placeholder={intl.formatMessage({
                      id: 'completeProfile.postCode.placeholder',
                    })}
                    {...field}
                  />
                )}
              </Field>
              <Field name="mobile">
                {({ field, form }: FieldProps) => (
                  <MobileInput field={field} form={form} {...field} />
                )}
              </Field>
              <div className="grid gap-4 grid-cols-[repeat(3,minmax(0,1fr))]">
                <Field name="year">
                  {({ field }: FieldProps) => (
                    <FormInput
                      label={intl.formatMessage({ id: 'completeProfile.year.label' })}
                      placeholder={intl.formatMessage({ id: 'completeProfile.year.placeholder' })}
                      type="tel"
                      autoComplete="bday-year"
                      onFocus={() => setError('')}
                      {...field}
                    />
                  )}
                </Field>
                <Field name="month">
                  {({ field }: FieldProps) => (
                    <FormInput
                      label={intl.formatMessage({ id: 'completeProfile.month.label' })}
                      placeholder={intl.formatMessage({
                        id: 'completeProfile.month.placeholder',
                      })}
                      type="tel"
                      autoComplete="bday-month"
                      onFocus={() => setError('')}
                      {...field}
                    />
                  )}
                </Field>
                <Field name="day">
                  {({ field }: FieldProps) => (
                    <FormInput
                      label={intl.formatMessage({ id: 'completeProfile.day.label' })}
                      placeholder={intl.formatMessage({ id: 'completeProfile.day.placeholder' })}
                      type="tel"
                      autoComplete="bday-day"
                      onFocus={() => setError('')}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              {locale === 'th' && <p className="text-left text-sm mt-2 mb-0">กรุณากรอกปี ค.ศ.</p>}
              {error && (
                <div className="mt-8">
                  <PromptMessage type="error" message={error} />
                </div>
              )}
              <Button
                type="submit"
                disabled={!isValid}
                isLoading={loading}
                text={intl.formatMessage({ id: 'misc.submit' })}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CompleteFastRegProfile;
