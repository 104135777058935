import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { usePsaStore } from '@/store/zustand/psa-store';
import { selectLocale } from '@/store/selectors/commonSelectors';

const useCmsLoader = () => {
  const { fetch: fetchPsa } = usePsaStore();
  const locale = useSelector(selectLocale);

  useEffect(() => {
    if (!locale) {
      return;
    }
    fetchPsa();
  }, [locale]);
};

export default useCmsLoader;
