import { KycType } from '@lucky7ventures/bff-types';
import { BffSetAuthenticationMethodPathParam } from '@lucky7ventures/bff-types';

export const BFF_API_URLS = {
  //AUTH
  FORGOT_PASSWORD: '/api/v1/auth/forgot-password',
  FORGOT_PASSWORD_CHANGE: '/api/v1/auth/forgot-password/change',
  LOGOUT: '/api/v1/auth/logout',

  // REGISTER
  REGISTER_MEXICO: '/api/v1/register/mx',

  // USER
  VERIFY: '/api/v1/user/verify',
  COMPLETE_PROFILE_MEXICO: '/api/v1/user/complete-profile/mexico',
  GET_LIFETIME_DEPOSITS: '/api/v1/user/lifetime-deposits',
  GET_AVAILABLE_BONUSES: '/api/v1/user/available-bonuses',
  KYC_DOCUMENTS_UPLOAD: (kycType: KycType) => `/api/v1/user/kyc/${kycType}/upload`,
  KYC_SOWQ: '/api/v1/user/kyc/sowq',
  GET_AUTHENTICATION_METHOD: '/api/v1/user/get-auth-method',
  SET_AUTHENTICATION_METHOD: (authMethod: BffSetAuthenticationMethodPathParam) =>
    `/api/v1/user/set-auth-method/${authMethod}`,
  GET_KYC: '/api/v1/user/kyc',
  COMPLETE_PROFILE: '/api/v1/user/complete-profile',
  GET_ACTIVE_BONUSES: '/api/v1/user/active-bonuses',

  // GAMES
  GET_ALL_GAMES: '/api/v1/games/fetch',
  GET_GAMES_JACKPOTS: '/api/v1/games/jackpots',
  GET_LAST_PLAYED: '/api/v1/games/last-played',
  GET_GAME_URL: '/api/v1/games/game/url',

  // VALIDATE
  VALIDATE_EMAIL_PHONE: '/api/v1/validate/email-phone',

  // CMS
  GET_BONUS_INTRO_CARDS: '/cms/bonus-intro-cards',
  GET_PROMOTIONS: '/cms/promotions',
  GET_STATIC_TEXT_CONTENT: '/cms/static',
  GET_PSA: '/cms/psa',
  GET_WELCOME_TEXTS: '/cms/welcome-texts',
};
