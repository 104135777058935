import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { useEffect } from 'react';
import { getPromotions } from '@/store/cms/promotions/actions';

export const usePromotionsLoader = () => {
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!locale) {
      return;
    }

    dispatch(getPromotions());
  }, [locale]);
};
