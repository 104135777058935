import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepData,
} from '@/store/authentication/AuthenticationStore';
import { envIsCanada } from '@/constants/constants';

const useIsAuthenticationIntroEnabled = () => {
  const { homeHeroAuthenticationStep } = useHomeHeroAuthenticationStepData();
  return homeHeroAuthenticationStep === HomeHeroAuthenticationStep.Registration && envIsCanada();
};

export default useIsAuthenticationIntroEnabled;
