import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import PageHeader from '@/components/Layout/PageHeader';
import LegalCmsContent from '@/components/shared/LegalCmsContent';
import { usePrivacyPolicyLoader } from '@/hooks/usePrivacyPolicyLoader';

const PrivacyPolicy = () => {
  const { ppData, ppLoading } = usePrivacyPolicyLoader();
  const intl = useIntl();

  return (
    <>
      {ppData && (
        <Helmet>
          <title>LuckyDays - {ppData.title}</title>
        </Helmet>
      )}
      <PageHeader>
        <h1>{intl.formatMessage({ id: 'privacyPolicy.header' })}</h1>
      </PageHeader>
      <LegalCmsContent data={ppData} loading={ppLoading} showTitle={false} />
    </>
  );
};

export default PrivacyPolicy;
