import React, { useMemo } from 'react';
import parse from 'html-react-parser';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { useSelector } from 'react-redux';
import { Button } from '@/components/buttons/Button';
import { useIntl } from 'react-intl';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import Card from '@/components/shared/card/Card';
import { cdnImage } from '@/utils/image-utils';
import { CDN_IMAGES_PROMOTIONS_PATH } from '@/constants/constants';
import { ChevronLeftIcon } from '@heroicons/react/20/solid';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import { useCashierShow } from '@/features/cashier/useCashier';

import { BffCmsPromotionResponse, CmsCtaType } from '@lucky7ventures/bff-types';
import StorageManager from '@/shared/storageManager';
import { useRegistration } from '@/hooks/useRegistration';
import { useLogin } from '@/hooks/useLogin';

const PromotionRichText = ({ content }: { content: string | null }) => {
  if (!content) {
    return null;
  }
  return <div className="prose">{parse(content)}</div>;
};

const PromotionCta = ({
  ctaType,
  ctaLink,
  ctaText,
  isAuthenticated,
  slug,
}: {
  ctaType: CmsCtaType;
  ctaLink?: string | null;
  ctaText?: string | null;
  slug: string;
  isAuthenticated: boolean;
}) => {
  const intl = useIntl();
  const showCashier = useCashierShow();
  const navigate = useAbsoluteNavigate();
  const isReturningUser = StorageManager.getIsReturningUser();
  const { openRegistration } = useRegistration();
  const { openLogin } = useLogin();

  const onClickHandler = () => {
    triggerGTag(GTagEvents.promotion_cta, {
      slug,
    });
    if (ctaType === CmsCtaType.Internal && ctaLink) {
      navigate(ctaLink);
      return;
    }

    if (ctaType === CmsCtaType.External && ctaLink) {
      window.open(ctaLink, '_blank');
      return;
    }

    if (!isAuthenticated) {
      if (isReturningUser) {
        openLogin({ openedFrom: OpenedFrom.PromotionPage });
        return;
      }

      openRegistration({ openedFrom: OpenedFrom.PromotionPage });
      return;
    }

    if (ctaType === CmsCtaType.Games) {
      navigate('/games');
      return;
    }

    // we have a 'cashier' ctaType, but as a default we always want to open cashier
    showCashier();
  };

  const buttonMessageText = useMemo(() => {
    if (ctaText) {
      return ctaText;
    }

    if (!isAuthenticated) {
      if (isReturningUser) {
        return intl.formatMessage({ id: 'header.login' });
      }
      return intl.formatMessage({ id: 'header.register' });
    }

    if (ctaType === CmsCtaType.Games) {
      return intl.formatMessage({ id: 'header.games' });
    }

    return intl.formatMessage({ id: 'misc.depositNow' });
  }, [isAuthenticated, ctaType, isReturningUser]);

  return (
    <Button className="!w-fit px-8" text={buttonMessageText} onClickHandler={onClickHandler} />
  );
};

function PromotionContent({ promotion }: { promotion: BffCmsPromotionResponse }) {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const navigate = useAbsoluteNavigate();
  const intl = useIntl();

  return (
    <div className="max-w-5xl mx-auto px-4 pt-4 pb-16">
      <Card>
        <button
          type="button"
          className="flex items-center mb-4"
          onClick={() => navigate('/promotions')}
        >
          <ChevronLeftIcon className="w-6 h-6" />
          {intl.formatMessage({ id: 'promotions.all' })}
        </button>
        {promotion.image && (
          <img
            className="w-full h-full object-cover object-center"
            src={cdnImage(promotion.image, {
              width: '1000',
              height: '300',
              path: CDN_IMAGES_PROMOTIONS_PATH,
            })}
            alt={promotion.title}
          />
        )}
        <div className="mt-8">
          <h1 className="text-4xl">{promotion.title}</h1>
          <PromotionRichText content={promotion.contentBeforeCta} />
          <PromotionCta
            ctaType={promotion.ctaType}
            isAuthenticated={isAuthenticated}
            slug={promotion.slug}
            ctaText={promotion.ctaText}
            ctaLink={promotion.ctaLink}
          />
        </div>
        <div className="mt-8">
          <PromotionRichText content={promotion.contentAfterCta} />
        </div>
        <div className="mt-16">
          <PromotionRichText content={promotion.terms} />
        </div>
      </Card>
    </div>
  );
}

export default PromotionContent;
