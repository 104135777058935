import { BonusIntroCardsActions, BonusIntroCardsActionType } from '@/store/cms/bonus-intro/actions';
import { BffCmsBonusIntroCard } from '@lucky7ventures/bff-types';

export interface BonusIntroState {
  cards: BffCmsBonusIntroCard[] | [];
  loading: boolean;
  error: boolean;
}

const initialState: BonusIntroState = {
  cards: [],
  loading: false,
  error: false,
};

export const bonusIntroCardsReducer = (
  state = initialState,
  action: BonusIntroCardsActions,
): BonusIntroState => {
  switch (action.type) {
    case BonusIntroCardsActionType.GetBonusIntroCards:
      return { ...state, loading: true, error: false };
    case BonusIntroCardsActionType.GetBonusIntroCardsError:
      return { ...state, cards: [], loading: false, error: true };
    case BonusIntroCardsActionType.GetBonusIntroCardsSuccess: {
      return {
        ...state,
        cards: action.bonusIntroCards,
        loading: false,
        error: false,
      };
    }
    default:
      return state;
  }
};
