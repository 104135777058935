import { Action } from 'redux';
import { WpReferrerContent } from '@/models/wp-referrer-content.model';

export enum WordpressActionType {
  GetReferrerContent = 'GET_REFERRER_CONTENT',
  GetReferrerContentSuccess = 'GET_REFERRER_CONTENT_SUCCESS',
}

export type GetReferrerContentAction = Action<WordpressActionType.GetReferrerContent> & {
  locale: string;
  id: string;
};

export type GetReferrerContentSuccessAction =
  Action<WordpressActionType.GetReferrerContentSuccess> & {
    content: WpReferrerContent;
  };

export type WordpressActions = GetReferrerContentAction | GetReferrerContentSuccessAction;

export const getReferrerContent = (locale: string, id: string): GetReferrerContentAction => ({
  type: WordpressActionType.GetReferrerContent,
  locale,
  id,
});

export const getReferrerContentSuccess = (
  content: WpReferrerContent,
): GetReferrerContentSuccessAction => ({
  type: WordpressActionType.GetReferrerContentSuccess,
  content,
});
