import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { useLocalizedTo } from '@/hooks/useLocalizedTo';

interface AbsoluteNavLinkProps extends NavLinkProps {
  'data-cy'?: string;
}

export function AbsoluteNavLink({
  to,
  children,
  className,
  end,
  onClick,
  'data-cy': dataCy,
}: AbsoluteNavLinkProps): JSX.Element | null {
  const localizedTo = useLocalizedTo(to);

  if (!localizedTo) {
    return null;
  }

  return (
    <NavLink data-cy={dataCy} to={localizedTo} className={className} end={end} onClick={onClick}>
      {children}
    </NavLink>
  );
}
