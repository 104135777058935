import React from 'react';
import { useIntl } from 'react-intl';
import { cdnImage } from '@/utils/image-utils';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { Button } from '@/components/buttons/Button';

function PromotionsError() {
  const intl = useIntl();
  const navigate = useAbsoluteNavigate();
  return (
    <div className="text-center my-16">
      <img
        src={cdnImage('sitting-cat.png', { width: '200', height: '200', fit: 'scale-down' })}
        alt="Lucky Cat Sitting"
        className="mx-auto"
      />
      <div className="my-8">{intl.formatMessage({ id: 'promotions.overview.error' })}</div>
      <Button
        onClickHandler={() => navigate('/')}
        text={intl.formatMessage({ id: 'header.home' })}
        className="w-min px-8"
      />
    </div>
  );
}

export default PromotionsError;
