import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

export const GIG_GAMES_CATEGORIES_RECORD = {
  'last-played': 'games.categories.lastPlayed',
  recommended: 'games.categories.recommended',
  halloween: 'games.categories.halloween',
  'winter-games': 'games.categories.winterGames',
  'new-games': 'games.categories.newGames',
  'live-casino': 'games.categories.liveCasino',
  slots: 'games.categories.slots',
  jackpots: 'games.categories.jackpots',
  'table-games': 'games.categories.tableGames',
  'all-games': 'games.categories.allGames',
};

export const MEXICO_FEATURES_OPTIONS = [
  'bonus-game',
  'buy-feature',
  'infinity-reels',
  'multiplier',
  'fixed-jackpots',
  'bingo',
  'bonus-wheel',
  'gamble',
  'respins',
  'random-wilds-additional-wilds',
  'sticky-wilds',
  'avalanche',
  'cluster-pays',
  'level-up',
  'expanding-symbols',
  'paid-respin',
  'spreading-wilds',
  'wild-rush',
  'expanding-wild-with-re-spin',
  'wild-nudge',
  'megaways',
  'locked-reels',
  'additive-symbol',
] as const;

export const MEXICO_PROVIDERS_OPTIONS = [
  '4ThePlayer',
  'Alchemy Gaming',
  'All For One Studios',
  'AreaVegas Games',
  'Aurum Signature Studios',
  'Avatar UX',
  'Barstruck',
  'Blue Guru Games',
  'Blue Ring Studios',
  'Buck Stakes Entertainment',
  'Circular Arrow',
  'Coin Machine Gaming',
  'Crazy Tooth Studio',
  'Evolution',
  'Fantasma Games',
  'FBM',
  'Flip Five Gaming',
  'Fortune Factory Studios',
  'Four Leaf Gaming',
  'Foxium',
  'Gameburger Studios',
  'Games Global',
  'Gold Coin Studios',
  'Gong Gaming',
  'Half Pixel Studios',
  'Hammertime Games',
  'High Limit Studios',
  'Infinity Dragon Studios',
  'INO Games',
  'Just For The Win',
  'Nailed It! Games',
  'Neko Games',
  'Neon Valley Studios',
  'NetEnt',
  'Northern Lights',
  'Oros Gaming',
  'Oryx Gaming',
  'PearFiction Studios',
  'Peter & Sons',
  'Playtech',
  'Pragmatic Play',
  'Print Studios',
  'Real Dealer Studios',
  'Red Tiger',
  'Relax Gaming',
  'Silverback',
  'Slingshot Studios',
  'Snowborn Games',
  'SpinPlay Games',
  'Storm Gaming',
  'Stormcraft Studios',
  'Switch Studios',
  'Trigger',
  'Triple Edge Studios',
  'Wishbone',
  'Zitro',
] as const;

export const MEXICO_PROVIDERS_RECORD: { [key: string]: string } = MEXICO_PROVIDERS_OPTIONS.reduce(
  (record, label) => {
    const key = label
      .toLocaleLowerCase()
      .replace(/[^a-z0-9\s]/g, '') // Remove non-alphanumeric characters except spaces
      .replace(/\s+/g, '-'); // Replace spaces with dashes
    return { ...record, [key]: label };
  },
  {},
);

type Feature = (typeof MEXICO_FEATURES_OPTIONS)[number];
type Provider = (typeof MEXICO_PROVIDERS_RECORD)[number];
type Category = keyof typeof GIG_GAMES_CATEGORIES_RECORD;

export const isMexicoFeatureOption = (value: unknown): value is Feature => {
  return MEXICO_FEATURES_OPTIONS.includes(value as Feature);
};

export function createMexicoGameFilterOptions(options: string[]) {
  return options.map(option => ({
    label: option,
    value: option
      .toLocaleLowerCase()
      .replace(/[^a-z0-9\s]/g, '') // Remove non-alphanumeric characters except spaces
      .replace(/\s+/g, '-'), // Replace spaces with dashes
  }));
}

export interface GamesCategoryNavStore {
  feature?: Feature;
  provider?: Provider;
  category?: Category;
}

type GamesCategoryNavActions = {
  setFeature: (feature?: Feature) => void;
  setProvider: (provider?: Provider) => void;
  setCategory: (category?: Category) => void;
  reset: () => void;
};

type State = GamesCategoryNavStore;

const initialState: State = {
  feature: undefined,
  provider: undefined,
  category: undefined,
};

export const useGameCategoryNavStore = create<{ data: State } & GamesCategoryNavActions>(set => ({
  data: initialState,
  setFeature: (feature?: Feature) =>
    set({ data: { feature, provider: undefined, category: undefined } }),
  setProvider: (provider?: Provider) =>
    set({ data: { provider, feature: undefined, category: undefined } }),
  setCategory: (category?: Category) =>
    set({ data: { category, provider: undefined, feature: undefined } }),
  reset: () => set({ data: initialState }),
}));

export const useGameCategoryNavData = () => {
  return useGameCategoryNavStore(state => state.data, shallow);
};

export const useGameCategoryNavActions = () => {
  return useGameCategoryNavStore(
    state => ({
      setFeature: state.setFeature,
      setProvider: state.setProvider,
      setCategory: state.setCategory,
      reset: state.reset,
    }),
    shallow,
  );
};
