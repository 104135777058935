import React, { useEffect, useState } from 'react';

import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { Helmet } from 'react-helmet-async';
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useWindowScroll } from 'react-use';
import styled, { css } from 'styled-components';
import { ReactComponent as SwipeUp } from '@/components/UI/Icons/swipe-up.svg';
import { selectFitToPlay, selectLocale } from '@/store/selectors/commonSelectors';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectGameById } from '@/store/selectors/gamesSelectors';
import { useRegistration } from '@/hooks/useRegistration';
import { selectAutoLoginFinished } from '@/store/selectors/autoLoginSelectors';
import { GamePlayable, useGameUrl } from '@/hooks/useGameUrl';
import { selectShowBalanceUpdate } from '@/store/selectors/modalSelectors';
import { selectIsDrawerOpen } from '@/store/selectors/drawerSelectors';
import { gameThumbImg } from '@/utils/image-utils';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import { envIsCanada, envIsSweden } from '@/constants/constants';
import { setIsSingleGame } from '@/store/actions/uiActions';
import { getLastPlayedGames } from '@/store/actions/gamesActions';
import { GameHeader } from './GameHeader';
import Spinner from '@/components/UI/Spinner';
import { GameRestricted } from './GameRestricted';
import { getGameMetaDescByLocale, getGameTitleByLocale, isEmpty } from '@/shared/utility';
import { openFullscreen } from '@/lib/fullScreen';
import Tooltip from '@/components/UI/Tooltip';
import { BtnBlueLink } from '@/components/buttons/BtnBlueLink';
import { openModal } from '@/store/actions/modal';
import { getUserWallet } from '@/store/actions/user';
import { getUserBonuses } from '@/store/actions/bonusesActions';
import BtnIcon from '@/components/buttons/BtnIcon';

const StyledGame = styled.div<{ isSweden: boolean; isMobile: boolean; isLandscape: boolean }>`
  height: 100vh;

  ${props =>
    !props.isMobile &&
    css`
      overflow: hidden;

      #header {
        display: flex !important;
      }
    `}
  .wrapper {
    background: #000;
    background-size: cover;
    top: ${props => (props.isSweden && props.isMobile ? '28px' : '0')};
    position: fixed;
    height: ${props => (props.isSweden && props.isMobile ? 'calc(100% - 28px)' : '100%')};
    width: 100%;
    min-width: 100%;
    min-height: ${props => (props.isSweden && props.isMobile ? 'calc(100% - 28px)' : '100%')};
    display: grid;
    grid-template-rows: 32px 1fr;
    padding-left: env(safe-area-inset-left, 32px);
    padding-top: env(safe-area-inset-top);

    ${props =>
      props.isMobile &&
      props.isLandscape &&
      css`
        grid-template-rows: auto;
        grid-template-columns: 32px 1fr;
      `}
    ${props =>
      !props.isMobile &&
      css`
        display: flex;
        grid-template-columns: none;
        padding: 0;
        position: relative;
        background: ${props => props.theme.colors.blue3};
        background: radial-gradient(circle, rgba(0, 112, 120, 1) 0%, rgba(0, 38, 43, 1) 100%);
        flex-flow: column;

        &:after {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: ${props => props.theme.blue1};
          opacity: 0.8;
          content: '';
          display: block;
        }
      `}
    .game-wrapper {
      padding: 0;
      height: 100%;
      display: flex;
      position: relative;
      flex-grow: 1;
      background: #000;
      color: #fff;
      z-index: 1;

      .lucky-spinner {
        z-index: 2;
      }

      ${props =>
        props.isMobile &&
        props.isLandscape &&
        css`
          display: block;
        `}
      .game-container {
        height: 100%;
        width: 100%;

        .game-iframe {
          height: 100%;
          max-height: 100%;
          width: 100%;
          max-width: 100%;
          display: block;
          border: none;
        }

        .game-buttons {
          position: absolute;
          top: 0;
          right: -60px;

          .game-button {
            margin-bottom: 16px;
            display: block;
            line-height: 0;
          }
        }

        .buttons {
          display: none;
        }
      }

      ${props =>
        !props.isMobile &&
        css`
          justify-content: center;
          align-items: center;
          background: none;
          padding: 92px;

          .game-container {
            position: relative;
            background: #000;
            display: block;
            cursor: pointer;
            height: auto;
            max-width: calc((100vh - 200px) * 1.777);
            margin: 0 auto;

            &:after {
              content: '';
              display: block;
              padding-bottom: 56.27%;
            }

            .buttons {
              position: absolute;
              top: 0;
              right: -40px;
              display: block;
            }

            .login-to-play {
              position: absolute;
              top: 100%;
              right: 0;
              left: 0;
              text-align: center;
              padding: 12px;
              font-size: 12px;
              font-weight: bold;
              background: ${props => props.theme.colors.blue3};
              color: ${props => props.theme.colors.white};
              border-radius: 0 0 8px 8px;

              p {
                margin: 0;
                display: inline-flex;
                align-items: center;
                font-size: 16px;

                svg {
                  height: 18px;
                  width: 18px;
                  margin-right: 6px;
                }
              }
            }

            .game-iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              max-width: 100%;
              height: auto;
            }
          }
        `}
    }

    .swipe-up-overlay {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      height: 3000px;
      background: rgba(0, 0, 0, 0.8);
      width: 100vw;
      z-index: 1;

      svg {
        height: 100vh;
      }
    }
  }
`;

function Game() {
  const locale = useSelector(selectLocale);
  const intl = useIntl();
  const absoluteNavigate = useAbsoluteNavigate();
  const { gameId } = useParams();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);
  const { y } = useWindowScroll();
  const { isLandscape } = useMobileOrientation();
  const game = useSelector(selectGameById(parseInt(gameId || '')));
  const { openRegistration } = useRegistration();
  const autoLoginFinished = useSelector(selectAutoLoginFinished);
  const { gameUrl, loading, error, playable } = useGameUrl(gameId || '', game.vendor);
  const fitToPlay = useSelector(selectFitToPlay);
  const showBalanceUpdate = useSelector(selectShowBalanceUpdate);
  const isDrawerOpen = useSelector(selectIsDrawerOpen);

  useEffect(() => {
    if (!game) {
      return;
    }

    const params = {
      gameId: game.gameId,
      gameName: game.name,
      gameType: game.type,
      gameImg: gameThumbImg(game.slug),
      gameProviderId: game.vendorId,
      gameProviderGameId: game.vendorGameId,
      ...(game.jackpot && {
        gameJackpotAmount: game.jackpot.amount,
        gameJackpotCurrency: game.jackpot.currencyCode,
      }),
    };

    triggerGTag(GTagEvents.game_launch, params);
  }, [game]);

  useEffect(() => {
    // for build mode Canada we don't want the user to be able to open the game if he is not logged in
    if (envIsCanada()) {
      if (autoLoginFinished && !isAuthenticated) {
        absoluteNavigate('/');
        dispatch(openModal('login'));
      }
    }
  }, [isAuthenticated, autoLoginFinished]);

  useEffect(() => {
    if (isMobile) {
      if (window?.innerHeight !== window?.outerHeight) {
        setShowScrollIndicator(true);
      } else {
        setShowScrollIndicator(false);
      }
    }
  }, [y]);

  useEffect(() => {
    dispatch(setIsSingleGame(true));
    return () => {
      dispatch(setIsSingleGame(false));
    };
  }, []);

  useEffect(() => {
    return () => {
      if (isAuthenticated) {
        dispatch(getUserWallet());
        dispatch(getLastPlayedGames());
        dispatch(getUserBonuses());
      }
    };
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && !fitToPlay && playable === GamePlayable.Yes) {
      dispatch(openModal('fitToPlay'));
    }
  }, [fitToPlay, isAuthenticated, playable]);

  useEffect(() => {
    if (showBalanceUpdate && !isDrawerOpen) {
      dispatch(openModal('depositBalanceUpdate'));
    }
  }, [showBalanceUpdate, isDrawerOpen]);

  return (
    <>
      <Helmet>
        <body className="single-game" />
      </Helmet>
      {!isEmpty(game) && !isEmpty(game.name) && (
        <Helmet>
          <body className="single-game" />
          <title>{getGameTitleByLocale(game.name, locale)}</title>
          <meta name="description" content={getGameMetaDescByLocale(game.name, locale)} />
        </Helmet>
      )}
      <StyledGame isMobile={isMobile} isLandscape={isLandscape} isSweden={envIsSweden()}>
        <div className="wrapper">
          <GameHeader />
          <div className="game-wrapper">
            {error && (
              <p>
                {intl.formatMessage({ id: 'error.support' })}[{error.code}]
              </p>
            )}
            {loading && <Spinner absolute color="white" />}
            <div className="game-container">
              {playable !== GamePlayable.Yes && <GameRestricted playable={playable} />}
              {gameUrl && playable === GamePlayable.Yes && (
                <>
                  <iframe
                    key={gameUrl}
                    title="game-iframe"
                    className="game-iframe"
                    id="game-iframe"
                    src={gameUrl}
                    allow="fullscreen"
                    frameBorder="0"
                    style={{ height: '100%', maxHeight: '100%', width: '100%', maxWidth: '100%' }}
                  />
                  <div className="flex flex-col gap-4 absolute top-0 right-[-60px]">
                    <BtnIcon
                      className="tooltip-container"
                      onClickHandler={() => absoluteNavigate('/games')}
                    >
                      <XMarkIcon className="w-6 h-6" />
                      <Tooltip
                        position="bottom-right"
                        width="auto"
                        text={intl.formatMessage({ id: 'game.back.tooltip' })}
                      />
                    </BtnIcon>
                    <BtnIcon className="tooltip-container" onClickHandler={() => openFullscreen()}>
                      <ArrowsPointingOutIcon className="w-6 h-6" />
                      <Tooltip
                        position="bottom-right"
                        width="auto"
                        text={intl.formatMessage({ id: 'game.fullscreen.enter.tooltip' })}
                      />
                    </BtnIcon>
                  </div>
                  {autoLoginFinished && !isAuthenticated && !isMobile && (
                    <div className="login-to-play">
                      <p>
                        {intl.formatMessage({ id: 'game.playForReal.text' })} &nbsp;
                        <BtnBlueLink
                          onClickHandler={() => openRegistration({ openedFrom: OpenedFrom.Game })}
                          text={intl.formatMessage({ id: 'game.playForReal.linkText' })}
                        />
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {showScrollIndicator && isMobileOnly && isLandscape && (
            <div className="swipe-up-overlay">
              <SwipeUp />
            </div>
          )}
        </div>
      </StyledGame>
    </>
  );
}

export default Game;
