import React from 'react';
import useTagManager from '@/features/data-loader/hooks/useTagManager';
import useUserLocaleRedirect from '@/features/data-loader/hooks/useUserLocaleRedirect';
import useManualBonusesLoader from '@/features/data-loader/hooks/useManualBonusesLoader';
import useWordpressLoader from '@/features/data-loader/hooks/useWordpressLoader';
import useGamesLoader from '@/features/data-loader/hooks/useGamesLoader';
import useKycLoader from '@/features/data-loader/hooks/useKycLoader';
import useSentry from '@/features/data-loader/hooks/useSentry';
import useAmlVerificationStatusLoader from '@/features/data-loader/hooks/useAmlVerificationStatusLoader';
import useIpDataLoader from '@/features/data-loader/hooks/useIpDataLoader';
import useDetailsReverification from '@/features/data-loader/hooks/useDetailsReverification';
import useBtagLogic from '@/features/data-loader/hooks/useBtagLogic';
import useSearchParams from '@/features/data-loader/hooks/useSearchParams';
import useReturningUser from '@/features/data-loader/hooks/useReturningUser';
import { usePromotionsLoader } from '@/hooks/usePromotionsLoader';
import { useBonusIntroCardsLoader } from '@/hooks/useBonusIntroCardsLoader';
import useCmsLoader from '@/hooks/useCmsLoader';
import { useWelcomeTextsLoader } from '@/hooks/useWelcomeTextsLoader';

export function DataLoader({ children }: { children: React.ReactChildren }): JSX.Element {
  useTagManager();
  useUserLocaleRedirect();
  useManualBonusesLoader();
  useWordpressLoader();
  useGamesLoader();
  useKycLoader();
  useSentry();
  useAmlVerificationStatusLoader();
  useIpDataLoader();
  useDetailsReverification();
  useBtagLogic();
  useSearchParams();
  useReturningUser();
  usePromotionsLoader();
  useBonusIntroCardsLoader();
  useCmsLoader();
  useWelcomeTextsLoader();

  return <>{children}</>;
}
