import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/models/root-state.model';
import { isRegulatedLocale } from '@/shared/utility';
import { openModal } from '@/store/actions/modal';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import { setLoginOpenedFrom } from '@/store/actions/uiActions';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepActions,
} from '@/store/authentication/AuthenticationStore';
import useIsHomePage from '@/hooks/useIsHomePage';

interface OpenLogin {
  openedFrom: OpenedFrom;
}

export function useLogin(): { openLogin: (params: OpenLogin) => void } {
  const locale = useSelector((state: RootState) => state.common.locale);
  const dispatch = useDispatch();
  const { setHomeHeroAuthenticationStep } = useHomeHeroAuthenticationStepActions();
  const isHomePage = useIsHomePage();

  const openLogin = ({ openedFrom }: OpenLogin) => {
    dispatch(setLoginOpenedFrom(openedFrom));
    triggerGTag(GTagEvents.open_registration, {
      openedFrom: openedFrom,
    });

    // for Sweden & Finland we want to open the PayNPlay modal
    if (isRegulatedLocale(locale)) {
      dispatch(openModal('paynplay'));
      return;
    }

    // for other builds (Normal & Mexico) we have the homepage log/reg flow
    // we want to open the login modal on all non-home pages
    // we want to scroll to top on the homepage
    setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.Login);
    if (!isHomePage) {
      dispatch(openModal('login'));
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  return { openLogin };
}
