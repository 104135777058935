import React from 'react';

import { CogIcon, CreditCardIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

import { envIsSweden } from '@/constants/constants';
import { selectUiIsSingleGame } from '@/store/selectors/uiSelectors';
import { useCashierShow } from '@/features/cashier/useCashier';
import { usePageScrolled } from '@/hooks/usePageScrolled';
import { useOntarioRegisterRouteCheck } from '@/hooks/useOntarioRegisterRouteCheck';
import Psa from './Psa';
import { AbsoluteLink } from '../../AbsoluteLink';
import LuckyDaysLogo from '../../UI/Logos/LuckyDaysLogo';
import { AbsoluteNavLink } from '../../AbsoluteNavLink';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import { HeaderIconButton } from './HeaderIconButton';
import TotalBalance from '../../TotalBalance';
import { openSearchPallete } from '@/utils/utils';
import { useRewardsWheel } from '@/hooks/useRewardsWheel';
import { cdnImage } from '@/utils/image-utils';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { openDrawer } from '@/store/actions/drawer';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import AuthButton from '@/components/shared/header/AuthButton';
import useHeaderVariant from '@/hooks/useHeaderVariant';
import classNames from 'classnames';
import { HeaderVariant } from '@/models/enum/header-variant.enum';

function Header(): JSX.Element | null {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();
  const intl = useIntl();
  const isSingleGame = useSelector(selectUiIsSingleGame);
  const showCashier = useCashierShow();
  const { isEnabled: isRewardReelEnabled, pagePath: rewardsWheelPagePath } = useRewardsWheel();
  const navigate = useAbsoluteNavigate();

  const isScrolled = usePageScrolled();
  const isOntarioRegisterRoute = useOntarioRegisterRouteCheck();
  const headerVariant = useHeaderVariant();

  if (isSingleGame && isMobile) {
    return null;
  }

  return (
    <header
      id="header"
      className={classNames('sticky z-[90] w-full transition-all md:fixed', {
        'top-0': !envIsSweden(),
        'top-7': envIsSweden(), // Sweden has Spelpaus header above the main header
        'hidden md:block': isAuthenticated,
        'bg-white md:bg-transparent text-slate-900 md:text-white':
          headerVariant === HeaderVariant.Default && !isScrolled,
        'bg-white text-slate-900': isScrolled || headerVariant === HeaderVariant.Solid,
        'shadow-md': isScrolled || headerVariant === HeaderVariant.Solid,
      })}
    >
      <Psa />
      <nav
        className={classNames(
          'flex items-center justify-between px-4 py-2 transition-all md:px-8',
          {
            'md:py-3': isScrolled,
            'md:py-5': !isScrolled,
          },
        )}
      >
        <div className="flex space-x-10">
          <AbsoluteLink data-cy="header-luckydays-logo" to="/">
            <LuckyDaysLogo />
          </AbsoluteLink>
          <div className="hidden space-x-10 md:flex">
            <AbsoluteNavLink
              to="/"
              className={({ isActive }) =>
                `font-bold transition hover:opacity-100 ${isActive ? 'opacity-100' : 'opacity-60'}`
              }
              end
            >
              {intl.formatMessage({ id: 'header.home' })}
            </AbsoluteNavLink>
            <AbsoluteNavLink
              to="/games"
              data-cy="header-navigation-games"
              className={({ isActive }) =>
                `font-bold transition hover:opacity-100 ${isActive ? 'opacity-100' : 'opacity-60'}`
              }
              // TODO: Can we check if we need these still?
              onClick={() => {
                triggerGTag(GTagEvents.open_category, {
                  openedFrom: OpenedFrom.Header,
                  categoryName: 'game-lobby',
                });
              }}
            >
              {intl.formatMessage({ id: 'header.games' })}
            </AbsoluteNavLink>
            <button
              type="button"
              onClick={() => {
                triggerGTag(GTagEvents.header_help_click);
                dispatch(openDrawer('help'));
              }}
              className="font-bold opacity-60 transition hover:opacity-100"
            >
              {intl.formatMessage({ id: 'header.help' })}
            </button>
          </div>
        </div>
        <div className="flex gap-2 md:gap-4">
          {isRewardReelEnabled && (
            <HeaderIconButton
              text={intl.formatMessage({
                id: 'rewardReel.title.cta',
              })}
              onClick={() => {
                triggerGTag(GTagEvents.open_reward_reel, {
                  openedFrom: OpenedFrom.Header,
                });
                navigate(`/${rewardsWheelPagePath}`);
              }}
              headerVariant={headerVariant}
              isScrolled={isScrolled}
            >
              <img
                height="20"
                width="20"
                className="h-[20px] w-[20px]"
                src={cdnImage('rewards-wheel-icon.png', {
                  width: '20',
                  height: '20',
                  dpr: '2',
                })}
                alt={intl.formatMessage({ id: 'rewardReel.title' })}
              />
            </HeaderIconButton>
          )}
          {isAuthenticated ? (
            <>
              <button
                type="button"
                className="flex h-10 w-10 items-center justify-center rounded-full bg-cyan-500 font-bold text-white shadow-md transition hover:bg-cyan-600 hover:shadow-lg md:w-auto md:px-4"
                onClick={() => {
                  triggerGTag(GTagEvents.header_open_cashier);
                  showCashier();
                }}
                data-cy="navigation-cashier"
                id="e2e_headerCashier"
              >
                {isSingleGame ? <span>+ / -</span> : <TotalBalance />}
                <CreditCardIcon className="ml-2 h-5 w-5" />
              </button>
              <HeaderIconButton
                to="settings"
                isScrolled={isScrolled}
                id="e2e_headerSettings"
                headerVariant={headerVariant}
              >
                <CogIcon className="h-5 w-5" />
              </HeaderIconButton>
            </>
          ) : (
            <AuthButton headerVariant={headerVariant} isScrolled={isScrolled} />
          )}
          {!isOntarioRegisterRoute && (
            <HeaderIconButton
              className="hidden md:flex"
              onClick={() => {
                triggerGTag(GTagEvents.header_search_click);
                openSearchPallete();
              }}
              isScrolled={isScrolled}
              ariaLabel="Search Button"
              headerVariant={headerVariant}
            >
              <MagnifyingGlassIcon id="e2e_search" className="h-5 w-5" />
            </HeaderIconButton>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
