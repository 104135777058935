import {
  all,
  AllEffect,
  ForkEffect,
  StrictEffect,
  takeLatest,
  put,
  call,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { BffCmsPromotionsResponse, BffResponse } from '@lucky7ventures/bff-types';
import BffApiService from '@/shared/bffApiService';
import {
  getPromotionsError,
  getPromotionsSuccess,
  PromotionsActionType,
} from '@/store/cms/promotions/actions';

export function* getPromotionsSaga(): Generator<
  StrictEffect,
  void,
  AxiosResponse<BffResponse<BffCmsPromotionsResponse>>
> {
  try {
    const response = yield call(BffApiService.getPromotions);

    if (response.data.data) {
      yield put(getPromotionsSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getPromotionsError());
  }
}

export function* watchPromotionsSagas(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeLatest(PromotionsActionType.GetPromotions, getPromotionsSaga)]);
}
