import React from 'react';

import { BffStaticTextContentResponse } from '@lucky7ventures/bff-types';
import parse from 'html-react-parser';

import LoadingSpinner from '@/components/shared/spinner/LoadingSpinner';

const LegalCmsContent = ({
  data,
  loading,
  showTitle = true,
}: {
  data: BffStaticTextContentResponse | null;
  loading: boolean;
  showTitle?: boolean;
}) => {
  if (loading) {
    return (
      <div className="flex items-center justify-center mt-6">
        <LoadingSpinner mode="dark" />
      </div>
    );
  }

  if (data === null) {
    return null;
  }

  return (
    <div
      className="prose mx-auto max-w-[100ch] p-2 [&_ol]:pl-4 multi-level-list-marker [&_a]:text-blue-blue [&_a]:underline
      [&_strong]:text-black [&_table]:overflow-x-auto [&_table]:block [&_a]:break-words"
    >
      {showTitle && <h1 className="mt-6">{data.title}</h1>}
      {parse(data.content)}
    </div>
  );
};

export default LegalCmsContent;
