import React from 'react';
import SkeletonLoader from '@/components/shared/skeleton-loader/SkeletonLoader';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

const PromotionSkeletonCard = () => {
  return (
    <div>
      <SkeletonLoader className="w-full h-32" />
      <SkeletonLoader className="h-5 mt-3 w-[80%] rounded-md" />
      <SkeletonLoader className="h-5 mt-3 w-[60%] rounded-md" />
    </div>
  );
};

function PromotionsLoading() {
  const intl = useIntl();
  return (
    <div>
      <div className="my-8">{intl.formatMessage({ id: 'promotions.overview.loading' })}</div>
      <div className={classNames('grid grid-cols-1 md:grid-cols-2 gap-8')}>
        <PromotionSkeletonCard />
        <PromotionSkeletonCard />
        <PromotionSkeletonCard />
        <PromotionSkeletonCard />
      </div>
    </div>
  );
}

export default PromotionsLoading;
