import React from 'react';

type TransparentCardProps = {
  children: React.ReactNode;
};

const TransparentCard = ({ children }: TransparentCardProps) => (
  <div className="bg-white/5 rounded-lg backdrop-blur-sm shadow-md border-white/10 border-solid border-[1px] p-6 sm:p-8">
    {children}
  </div>
);

export default TransparentCard;
