import { RootState } from '@/models/root-state.model';
import { BffCmsWelcomeTextsResponse } from '@lucky7ventures/bff-types';
import { createSelector } from 'reselect';
import parse from 'html-react-parser';

const selectWelcomeTextsHtml = (state: RootState): BffCmsWelcomeTextsResponse =>
  state.welcomeTexts.welcomeTexts;
export const selectWelcomeTextsHtmlLoading = (state: RootState): boolean =>
  state.welcomeTexts.loading;
export const selectHeroWelcomeTextsHtml = createSelector(selectWelcomeTextsHtml, welcomeTexts =>
  parse(welcomeTexts.hero.text),
);
export const selectHomeCenterWelcomeTextsHtml = createSelector(
  selectWelcomeTextsHtml,
  welcomeTexts => parse(welcomeTexts.homeCenter.text),
);
export const selectBonusIntroWelcomeTextsHtml = createSelector(
  selectWelcomeTextsHtml,
  welcomeTexts => parse(welcomeTexts.bonusIntro.text),
);
