import { Action } from 'redux';
import { BffCmsWelcomeTextsResponse } from '@lucky7ventures/bff-types';

export enum WelcomeTextsActionType {
  GetWelcomeTexts = 'GET_WELCOME_TEXTS',
  GetWelcomeTextsSuccess = 'GET_WELCOME_TEXTS_SUCCESS',
  GetWelcomeTextsError = 'GET_WELCOME_TEXTS_ERROR',
}

export type GetWelcomeTextsAction = Action<WelcomeTextsActionType.GetWelcomeTexts>;
export type GetWelcomeTextsSuccessAction = Action<WelcomeTextsActionType.GetWelcomeTextsSuccess> & {
  welcomeTexts: BffCmsWelcomeTextsResponse;
};
export type GetWelcomeTextsErrorAction = Action<WelcomeTextsActionType.GetWelcomeTextsError>;

export type WelcomeTextsActions =
  | GetWelcomeTextsAction
  | GetWelcomeTextsSuccessAction
  | GetWelcomeTextsErrorAction;

export const getWelcomeTexts = (): GetWelcomeTextsAction => ({
  type: WelcomeTextsActionType.GetWelcomeTexts,
});

export const getWelcomeTextsSuccess = (
  welcomeTexts: BffCmsWelcomeTextsResponse,
): GetWelcomeTextsSuccessAction => {
  return {
    type: WelcomeTextsActionType.GetWelcomeTextsSuccess,
    welcomeTexts,
  };
};

export const getWelcomeTextsError = (): GetWelcomeTextsErrorAction => ({
  type: WelcomeTextsActionType.GetWelcomeTextsError,
});
