import React, { useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { Button } from '@/components/buttons/Button';
import Divider from './Divider';
import PromptMessage from '@/components/UI/PromptMessage';
import { selectBtag, selectLocale } from '@/store/selectors/commonSelectors';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import {
  Consents,
  useRegistrationActions,
  useRegistrationData,
} from '@/store/zustand/registration-store';
import ApiService from '@/shared/apiService';
import { logException } from '@/utils/logger';
import { triggerNetreferSignup } from '@/shared/netrefer';
import { RegisterAgcoRequestDto } from '@/models/apiV2/request/register-agco-request-dto.model';
import { RegisterResponse } from '@/models/apiV2/response/register-response-dto.model';
import { ApiV2Error } from '@/shared/errorHandler';
import { runVerify } from '@/store/actions/kycActions';
import SwitchField from '@/components/form/switch/SwitchField';
import { loginSuccess } from '@/store/actions/auth';
import Form from '@/components/form/Form';

const ConsentsForm = ({
  stepBack,
  setShowError,
}: {
  stepBack: () => void;
  setShowError: (errorCode: string | null) => void;
}) => {
  const { tcConsent, marketingConsent, pepHioConsent, proxyConsent, ...rest } =
    useRegistrationData();
  const { updateField, reset: resetRegistrationStore } = useRegistrationActions();
  const { request: registerRequest, loading: registerLoading } =
    useApiV2Request<RegisterResponse>();
  const [errorMessage, setErrorMessage] = useState('');
  const btag = useSelector(selectBtag);
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useAbsoluteNavigate();
  const locale = useSelector(selectLocale);

  const data = [
    {
      name: 'marketingConsent',
      label: intl.formatMessage({ id: 'inputs.marketing' }),
      labelClassName: 'text-white font-bold',
      onChange: (checked: boolean) => {
        updateField('marketingConsent', checked);
        triggerGTag(GTagEvents.ont_reg_marketing_click);
      },
    },
    {
      name: 'tcConsent',
      label: (
        <FormattedMessage
          id="inputs.tc"
          values={{
            a: str => (
              <a
                href={`${window.location.origin}/terms-and-conditions`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-blue font-bold underline cursor-pointer"
              >
                {str}
              </a>
            ),
            b: str => (
              <a
                href={`${window.location.origin}/privacy-policy`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-blue font-bold underline cursor-pointer"
              >
                {str}
              </a>
            ),
          }}
        />
      ),
      labelClassName: 'text-white',
      onChange: (checked: boolean) => {
        triggerGTag(GTagEvents.ont_reg_terms_click);
        updateField('tcConsent', checked);
      },
      validationErrorEvent: GTagEvents.ont_reg_terms_error,
    },
    {
      name: 'pepHioConsent',
      label: intl.formatMessage({ id: 'inputs.pepHio' }),
      labelClassName: 'text-white',
      onChange: (checked: boolean) => {
        triggerGTag(GTagEvents.ont_reg_pep_click);
        updateField('pepHioConsent', checked);
      },
      validationErrorEvent: GTagEvents.ont_reg_pep_error,
    },
    {
      name: 'proxyConsent',
      label: intl.formatMessage({ id: 'inputs.proxy' }),
      labelClassName: 'text-white',
      onChange: (checked: boolean) => {
        triggerGTag(GTagEvents.ont_reg_3rdparty_click);
        updateField('proxyConsent', checked);
      },
      validationErrorEvent: GTagEvents.ont_reg_3rdparty_error,
    },
  ];

  const initialValues: Consents = {
    tcConsent,
    marketingConsent,
    pepHioConsent,
    proxyConsent,
  };

  const validationSchema = Yup.object().shape({
    tcConsent: Yup.bool()
      .required(intl.formatMessage({ id: 'input.error.required' }))
      .test('tc', intl.formatMessage({ id: 'inputs.tc.invalid' }), value => value!),
    marketingConsent: Yup.bool().required(intl.formatMessage({ id: 'input.error.required' })),
    pepHioConsent: Yup.bool()
      .required(intl.formatMessage({ id: 'input.error.required' }))
      .test('pepHio', intl.formatMessage({ id: 'inputs.pepHio.invalid' }), value => value!),
    proxyConsent: Yup.bool()
      .required(intl.formatMessage({ id: 'input.error.required' }))
      .test('proxy', intl.formatMessage({ id: 'inputs.proxy.invalid' }), value => value!),
  });

  const handleOnSubmit = () => {
    const { birthDate, postCode, ...payloadData } = rest;
    const { day, month, year } = birthDate;
    const dateOfBirth = `${year}-${month}-${day}`;
    triggerGTag(GTagEvents.ont_reg_step4_submit_cta_click);

    const payload: RegisterAgcoRequestDto = {
      userData: {
        ...payloadData,
        birthDate: dateOfBirth,
        postalCode: postCode,
        countryCode: 'CA',
        mobile: payloadData.mobile.number,
        mobilePrefix: payloadData.mobile.prefix,
        industryId: Number(payloadData.industryId),
        occupationId: Number(payloadData.occupationId),
      },
      emailConsented: marketingConsent,
      smsConsented: marketingConsent,
      btag,
    };

    const handleOnSuccess = ({ userId, token }: RegisterResponse) => {
      triggerGTag(GTagEvents.ont_reg_success, { userId }, () => {
        triggerNetreferSignup(btag, userId, locale);
      });

      dispatch(loginSuccess(token, { isRegistration: true }));
      resetRegistrationStore();
      // Starts the GBG Verification process in the background
      dispatch(runVerify());
      navigate('/');
    };

    const handleOnError = (error: ApiV2Error) => {
      logException(new Error('Ontario Reg Failed'), { error });
      triggerGTag(GTagEvents.ont_reg_failed, { error: `[${error.errorId}] - ${error.error}` });
      // 1. for error ACCOUNT_BLOCKED we want to do a special error handling, also we want to clear the registration state in this case
      if (error.error === 'ACCOUNT_BLOCKED') {
        resetRegistrationStore();
        setShowError(error.error);
        return;
      }
      setErrorMessage(
        `${intl.formatMessage({
          id: 'failedSignup.SomethingWentWrongMessage',
        })} (${error.errorId})`,
      );
    };

    setShowError(null);
    setErrorMessage('');
    registerRequest(ApiService.registerAGCO, payload, handleOnSuccess, handleOnError);
  };

  return (
    <div className="w-full h-full pt-4">
      <h4 className="text-blue-blue m-0 font-normal mb-8">
        {intl.formatMessage({ id: 'registration.ontario.consentsTitle' })}
      </h4>
      <h5 className="text-white m-0 mb-5 mt-1.5 text-sm sm:text-base">
        {intl.formatMessage({ id: 'registration.ontario.consentsSubtitle' })}
      </h5>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleOnSubmit}
        className="flex flex-col justify-between gap-8"
      >
        {() => (
          <>
            {data.map((consent, index, arr) => (
              <React.Fragment key={consent.name}>
                <SwitchField
                  name={consent.name}
                  label={consent.label}
                  labelClassName={consent.labelClassName}
                  onChange={consent.onChange}
                  onValidationError={error => {
                    if (consent.validationErrorEvent) {
                      triggerGTag(consent.validationErrorEvent, { error });
                    }
                  }}
                />
                {index < arr.length - 1 && <Divider />}
              </React.Fragment>
            ))}
            <div className="flex flex-col gap-4 [&>div:first-child]:m-0">
              {errorMessage && <PromptMessage message={errorMessage} type="error" />}
              <div className="flex flex-col-reverse items-center gap-4 mt-4 sm:flex-row">
                <Button
                  text={intl.formatMessage({ id: 'misc.back' })}
                  onClickHandler={() => {
                    triggerGTag(GTagEvents.ont_reg_step4_back_cta_click);
                    stepBack();
                  }}
                  btnType="secondary"
                />
                <Button
                  text={intl.formatMessage({ id: 'registration.ontario.finish' })}
                  type="submit"
                  btnType="primary"
                  isLoading={registerLoading}
                />
              </div>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default ConsentsForm;
