import * as React from 'react';
import { useEffect, useState } from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { Alert } from './Alert';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveDepositLimit } from '@/store/selectors/limitsSelectors';
import { RootState } from '@/models/root-state.model';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import {
  selectUserCurrency,
  selectUserEmail,
  selectUserId,
  selectUserRealBalance,
} from '@/store/selectors/userSelectors';
import * as Yup from 'yup';
import { getLimits, getLimitsSuccess } from '@/store/actions/limitsActions';
import { FormInput } from './FormInput';
import { depositLimits } from '@/utils/utils';
import {
  LimitDetails,
  LimitDuration,
  LimitType,
  SetUserLimitModelV2,
  UserWalletPublic,
} from '@lucky7ventures/lucky-components';

import { BtnOneLineBlue } from './buttons/BtnOneLineBlue';
import { BtnBasicLink } from './buttons/BtnBasicLink';
import Currency from './UI/Currency';
import { formatCurrency } from '@/utils/currency-utils';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { GetUserLimitModel } from '@/models/gig/get-user-limit.model';
import { closeSpecificModal } from '@/store/actions/modal';
import Spinner from './UI/Spinner';
import { BtnOneLineRed } from './buttons/BtnOneLineRed';
import ApiService from '../shared/apiService';
import { apiV2ErrorHandler } from '@/shared/errorHandler';
import { AxiosError } from 'axios';
import { getUserWalletSuccess, setUserEmail } from '@/store/actions/user';
import { useSimpleRequest } from '@/hooks/useSimpleRequest';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { getUserBonuses } from '@/store/actions/bonusesActions';
import { selectActiveBonuses } from '@/store/selectors/bonusesSelectors';
import { BffActiveBonusesResponse } from '@lucky7ventures/bff-types';

enum FlowStep {
  SetDepositLimits,
  Refund,
}

interface RefundSetLimitsResponse {
  limits: GetUserLimitModel;
  wallet: UserWalletPublic;
}

interface RefundSetLimitsPayload {
  token: string;
  limits: SetUserLimitModelV2[];
}

interface DepositFormValues {
  depositLimitDay: string | number;
  depositLimitWeek: string | number;
  depositLimitMonth: string | number;
  email: string;
}

const DEPOSIT_LIMITS = depositLimits();

// there is a special workflow in the BO, where a user needs to be sent an email if they set a deposit limit above 10k
const DEPOSIT_LIMIT_EMAIL_TRIGGER = 10000;

function ConnectedDeposits({
  limitWeek,
  limitMonth,
}: {
  limitWeek: string | number;
  limitMonth: string | number;
}): null {
  const {
    values: { depositLimitDay },
    setFieldValue,
  } = useFormikContext<{ depositLimitDay: number }>();

  useEffect(() => {
    if (!depositLimitDay) {
      if (!limitWeek) {
        setFieldValue('depositLimitWeek', '');
      }
      if (!limitMonth) {
        setFieldValue('depositLimitMonth', '');
      }
      return;
    }

    const depositLimitWeek = depositLimitDay * 7;
    const depositLimitMonth = depositLimitDay * 30;

    if (!limitWeek) {
      setFieldValue(
        'depositLimitWeek',
        depositLimitWeek > DEPOSIT_LIMITS.week ? DEPOSIT_LIMITS.week : depositLimitWeek,
      );
    }
    if (!limitMonth) {
      setFieldValue(
        'depositLimitMonth',
        depositLimitMonth > DEPOSIT_LIMITS.month ? DEPOSIT_LIMITS.month : depositLimitMonth,
      );
    }
  }, [depositLimitDay]);

  return null;
}

function SetDepositsStep({
  initialValues,
  submitCallback,
  logoutCallback,
  depositsUpdating,
  depositsError,
  limitDay,
  limitWeek,
  limitMonth,
  emailCompleted,
}: {
  initialValues: DepositFormValues;
  submitCallback: (values: DepositFormValues) => void;
  logoutCallback: () => void;
  depositsUpdating: boolean;
  depositsError: string | null;
  limitDay: LimitDetails | undefined;
  limitWeek: LimitDetails | undefined;
  limitMonth: LimitDetails | undefined;
  emailCompleted: boolean;
}): JSX.Element {
  const intl = useIntl();

  const limitDayError = intl.formatMessage(
    { id: 'depositLimit.amount.error' },
    { min: DEPOSIT_LIMITS.min, max: DEPOSIT_LIMITS.day.toLocaleString() },
  );
  const limitWeekError = intl.formatMessage(
    { id: 'depositLimit.amount.error' },
    { min: DEPOSIT_LIMITS.min, max: DEPOSIT_LIMITS.week.toLocaleString() },
  );
  const limitMonthError = intl.formatMessage(
    { id: 'depositLimit.amount.error' },
    { min: DEPOSIT_LIMITS.min, max: DEPOSIT_LIMITS.month.toLocaleString() },
  );

  const validationSchema = Yup.object().shape({
    depositLimitDay: Yup.number()
      .typeError(limitDayError)
      .min(DEPOSIT_LIMITS.min, limitDayError)
      .max(DEPOSIT_LIMITS.day, limitDayError)
      .required(limitDayError),
    depositLimitWeek: Yup.number()
      .typeError(limitWeekError)
      .min(DEPOSIT_LIMITS.min, limitWeekError)
      .max(DEPOSIT_LIMITS.week, limitWeekError)
      .required(limitWeekError),
    depositLimitMonth: Yup.number()
      .typeError(limitMonthError)
      .min(DEPOSIT_LIMITS.min, limitMonthError)
      .max(DEPOSIT_LIMITS.month, limitMonthError)
      .required(limitMonthError),
    email: Yup.string().when(['depositLimitDay', 'depositLimitWeek', 'depositLimitMonth'], {
      is: (limitDay: number, limitWeek: number, limitMonth: number) => {
        return (
          limitDay >= DEPOSIT_LIMIT_EMAIL_TRIGGER ||
          limitWeek >= DEPOSIT_LIMIT_EMAIL_TRIGGER ||
          limitMonth >= DEPOSIT_LIMIT_EMAIL_TRIGGER
        );
      },
      then: schema =>
        schema
          .required(intl.formatMessage({ id: 'inputs.email.required' }))
          .email(intl.formatMessage({ id: 'inputs.email.invalid' })),
      otherwise: schema => schema.notRequired(),
    }),
  });

  const depositFormat = (value: string): string => {
    if (!value) {
      return '';
    }

    const parsed = parseInt(value);

    if (isNaN(parsed)) {
      return '';
    }

    return parsed.toString();
  };

  return (
    <div>
      <h1 className="mb-8 text-2xl">
        {intl.formatMessage({ id: 'modal.requiredDepositLimits.header' })}
      </h1>
      <div>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values: DepositFormValues) => submitCallback(values)}
        >
          {({ isValid, values }) => {
            return (
              <Form>
                <FormInput
                  name="depositLimitDay"
                  label={intl.formatMessage({
                    id: 'updateContactInfo.modal.depositLimitDaily',
                  })}
                  placeholder={intl.formatMessage(
                    { id: 'updateContactInfo.modal.depositLimitPlaceholder' },
                    { min: DEPOSIT_LIMITS.min, max: DEPOSIT_LIMITS.day.toLocaleString() },
                  )}
                  formatOnChange={depositFormat}
                  completed={!!limitDay}
                />
                <FormInput
                  name="depositLimitWeek"
                  label={intl.formatMessage({
                    id: 'updateContactInfo.modal.depositLimitWeekly',
                  })}
                  placeholder={intl.formatMessage(
                    { id: 'updateContactInfo.modal.depositLimitPlaceholder' },
                    { min: DEPOSIT_LIMITS.min, max: DEPOSIT_LIMITS.week.toLocaleString() },
                  )}
                  completed={!!limitWeek}
                  formatOnChange={depositFormat}
                />
                <FormInput
                  name="depositLimitMonth"
                  label={intl.formatMessage({
                    id: 'updateContactInfo.modal.depositLimitMonthly',
                  })}
                  placeholder={intl.formatMessage(
                    { id: 'updateContactInfo.modal.depositLimitPlaceholder' },
                    { min: DEPOSIT_LIMITS.min, max: DEPOSIT_LIMITS.month.toLocaleString() },
                  )}
                  completed={!!limitMonth}
                  formatOnChange={depositFormat}
                />
                {(values.depositLimitDay >= DEPOSIT_LIMIT_EMAIL_TRIGGER ||
                  values.depositLimitWeek >= DEPOSIT_LIMIT_EMAIL_TRIGGER ||
                  values.depositLimitMonth >= DEPOSIT_LIMIT_EMAIL_TRIGGER) && (
                  <FormInput
                    name="email"
                    type="email"
                    label={intl.formatMessage({
                      id: 'inputs.email',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'inputs.email.placeholder',
                    })}
                    hint={intl.formatMessage({ id: 'requiredDepositLimits.email.hint' })}
                    completed={emailCompleted}
                  />
                )}
                <div className="mt-8 mb-4">
                  <BtnOneLineBlue
                    onClickHandler={() => submitCallback(values)}
                    isLoading={depositsUpdating}
                    disabled={!isValid}
                    text={intl.formatMessage({ id: 'misc.update' })}
                  />
                </div>
                <ConnectedDeposits
                  limitWeek={initialValues.depositLimitWeek}
                  limitMonth={initialValues.depositLimitMonth}
                />
              </Form>
            );
          }}
        </Formik>
        {depositsError && <Alert text={depositsError} />}
        <BtnBasicLink
          onClickHandler={logoutCallback}
          text={intl.formatMessage({ id: 'header.logout' })}
        />
      </div>
    </div>
  );
}

function RefundStep({
  realBalance,
  limits,
  bonuses,
  reviseLimitsCallback,
  currency,
  refundCallback,
  refundInProgress,
  refundError,
  locale,
}: {
  realBalance: number;
  limits: DepositFormValues;
  bonuses: BffActiveBonusesResponse;
  reviseLimitsCallback: () => void;
  currency: string;
  refundCallback: (values: DepositFormValues) => void;
  refundInProgress: boolean;
  refundError: boolean;
  locale: string;
}): JSX.Element {
  const intl = useIntl();

  const minLimit = Math.min(
    +limits.depositLimitDay,
    +limits.depositLimitWeek,
    +limits.depositLimitMonth,
  );
  const refundAmount = realBalance - minLimit;

  if (refundInProgress) {
    return (
      <div>
        <h1 className="mb-8 text-xl">{intl.formatMessage({ id: 'limits.refund.loading' })}</h1>
        <Spinner />
      </div>
    );
  }

  if (refundError) {
    return (
      <div>
        <p className="mb-8 text-xl">{intl.formatMessage({ id: 'error.support' })}</p>
        <BtnOneLineBlue
          onClickHandler={reviseLimitsCallback}
          isLoading={false}
          text={intl.formatMessage({ id: 'misc.tryAgain' })}
        />
      </div>
    );
  }

  return (
    <div>
      <h1 className="mb-8 text-2xl">{intl.formatMessage({ id: 'limits.refund.title' })}</h1>
      <div>
        <p>
          {intl.formatMessage(
            { id: 'limits.refund.text' },
            {
              amount: (
                <span className="font-bold">
                  <Currency currency={currency} amount={realBalance} />
                </span>
              ),
            },
          )}
        </p>
        <BtnOneLineBlue
          onClickHandler={reviseLimitsCallback}
          isLoading={false}
          text={intl.formatMessage({ id: 'limits.refund.btn.changeLimits' })}
        />
        <div className="my-8 h-[1px] bg-gray-300"></div>
        <p>
          {intl.formatMessage(
            { id: 'limits.refund.disclaimer' },
            {
              amount: (
                <span className="font-bold">
                  <Currency currency={currency} amount={refundAmount} />
                </span>
              ),
            },
          )}
        </p>
      </div>
      {bonuses.length > 0 && (
        <div className="underline">
          <p>{intl.formatMessage({ id: 'limits.refund.bonuses.disclaimer' })}</p>
        </div>
      )}
      <BtnOneLineRed
        onClickHandler={() => refundCallback(limits)}
        isLoading={false}
        text={intl.formatMessage(
          { id: 'limits.refund.btn' },
          { amount: formatCurrency(refundAmount, locale, currency, 0) },
        )}
      />
    </div>
  );
}

export function RequiredDepositLimitsModal({
  closeModal,
}: {
  closeModal: () => void;
}): JSX.Element {
  const intl = useIntl();
  const userId = useSelector(selectUserId);
  const token = useSelector(selectAuthToken);
  const {
    request: setLimitsRequest,
    error: setLimitsError,
    loading: setLimitsLoading,
  } = useSimpleRequest();
  const {
    request: refundLimitsRequest,
    error: refundError,
    loading: refundLoading,
    reset: refundReset,
  } = useSimpleRequest<RefundSetLimitsResponse, RefundSetLimitsPayload>();
  const dispatch = useDispatch();
  const [flowStep, setFlowStep] = useState<FlowStep>(FlowStep.SetDepositLimits);
  const absoluteNavigate = useAbsoluteNavigate();
  const realBalance = useSelector(selectUserRealBalance);
  const locale = useSelector(selectLocale);
  const userCurrency = useSelector(selectUserCurrency);
  const userEmail = useSelector(selectUserEmail);
  const activeBonuses = useSelector(selectActiveBonuses);
  const limitDay = useSelector((state: RootState) =>
    selectActiveDepositLimit(state, LimitDuration._24Hours),
  );
  const limitWeek = useSelector((state: RootState) =>
    selectActiveDepositLimit(state, LimitDuration._1Week),
  );
  const limitMonth = useSelector((state: RootState) =>
    selectActiveDepositLimit(state, LimitDuration._1Month),
  );
  const [initialDepositValues, setInitialDepositValues] = useState({
    depositLimitDay: limitDay ? limitDay.Amount : '',
    depositLimitWeek: limitWeek ? limitWeek.Amount : '',
    depositLimitMonth: limitMonth ? limitMonth.Amount : '',
    email: userEmail,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const mapLimits = (values: DepositFormValues): SetUserLimitModelV2[] => {
    if (!userId) {
      return [];
    }

    return [
      ...(!limitDay
        ? [
            {
              UserId: userId,
              Type: LimitType.Deposit,
              Duration: LimitDuration._24Hours,
              LimitAmount: Number(values.depositLimitDay),
            },
          ]
        : []),
      ...(!limitWeek
        ? [
            {
              UserId: userId,
              Type: LimitType.Deposit,
              Duration: LimitDuration._1Week,
              LimitAmount: Number(values.depositLimitWeek),
            },
          ]
        : []),
      ...(!limitMonth
        ? [
            {
              UserId: userId,
              Type: LimitType.Deposit,
              Duration: LimitDuration._1Month,
              LimitAmount: Number(values.depositLimitMonth),
            },
          ]
        : []),
    ];
  };

  const submitDeposits = async (values: DepositFormValues) => {
    setInitialDepositValues(values);
    setError('');
    try {
      if (values.email !== userEmail) {
        setLoading(true);
        await ApiService.changeEmail({ email: values.email });
        dispatch(setUserEmail(values.email));
        setLoading(false);
      }

      // here we want to determine if we can set the limits immediately or if we need to prompt the user for the refund flow
      if (realBalance === null) {
        return;
      }

      // Check if the user has set one of the limits that would go under his already deposited Balance
      const minLimit = Math.min(
        +values.depositLimitDay,
        +values.depositLimitWeek,
        +values.depositLimitMonth,
      );

      if (realBalance > minLimit) {
        // if the real balance is over the min limit we want to trigger the refund flow
        setFlowStep(FlowStep.Refund);
        return;
      }

      const limits = mapLimits(values);

      const refreshData = () => {
        dispatch(getLimits());
        closeModal();
      };

      setLimitsRequest(
        '/api/responsiblegaming/limits/setuserlimits',
        { token, limits },
        refreshData,
      );
    } catch (e) {
      setLoading(false);
      if (!(e instanceof AxiosError)) {
        setError(intl.formatMessage({ id: 'error.support' }));
      } else {
        const error = apiV2ErrorHandler(e);
        if (error.error === 'EMAIL_ALREADY_EXISTS') {
          setError(intl.formatMessage({ id: 'error.emailAlreadyExists' }));
        } else {
          setError(`${intl.formatMessage({ id: 'error.support' })} (${error.errorId})`);
        }
      }
    }
  };

  const initiateRefund = (values: DepositFormValues): void => {
    if (!token) {
      return;
    }

    const limits = mapLimits(values);
    const onSuccess = (data: RefundSetLimitsResponse) => {
      dispatch(getLimitsSuccess(data.limits));
      dispatch(getUserWalletSuccess(data.wallet));
      dispatch(getUserBonuses());
      dispatch(closeSpecificModal('requiredDepositLimits'));
    };
    refundLimitsRequest('/api/responsiblegaming/refund-setlimits', { token, limits }, onSuccess);
  };

  const logout = (): void => {
    closeModal();
    absoluteNavigate('/logout');
  };

  if (realBalance === null) {
    return (
      <div className="relative w-full max-w-sm rounded-lg bg-slate-50 px-8 pt-12 pb-8">
        <h1 className="mt-4 text-lg font-bold">Opps, något gick fel</h1>
        <p className="mt-3 mb-0">Vänligen ladda om sidan för att nå ditt spelkonto.</p>
      </div>
    );
  }

  return (
    <div className="relative w-full max-w-sm rounded-lg bg-slate-50 px-6 pt-12 pb-8">
      {flowStep === FlowStep.SetDepositLimits && (
        <SetDepositsStep
          initialValues={initialDepositValues}
          submitCallback={submitDeposits}
          logoutCallback={logout}
          depositsUpdating={setLimitsLoading || loading}
          depositsError={setLimitsError || error}
          limitDay={limitDay}
          limitWeek={limitWeek}
          limitMonth={limitMonth}
          emailCompleted={!!userEmail}
        />
      )}
      {flowStep === FlowStep.Refund && (
        <RefundStep
          realBalance={realBalance}
          limits={initialDepositValues}
          bonuses={activeBonuses}
          currency={userCurrency}
          reviseLimitsCallback={() => {
            refundReset();
            setFlowStep(FlowStep.SetDepositLimits);
          }}
          refundInProgress={refundLoading}
          refundError={!!refundError}
          refundCallback={initiateRefund}
          locale={locale}
        />
      )}
    </div>
  );
}
