import React, { useCallback, useState } from 'react';

import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import FastRegistrationFormInput from './FastRegistrationFormInput';
import FastRegistrationFormPassword from './FastRegistrationFormPassword';
import { BtnOneLineBlue } from '@/components/buttons/BtnOneLineBlue';
import PromptMessage from '@/components/UI/PromptMessage';
import ReactCountryFlag from 'react-country-flag';
import { CANADA_PROVINCES, FAST_REG_COUNTRIES } from '@/constants/locales';
import { useApiV2Request } from '@/hooks/useApiV2Request';
import { RegisterResponse } from '@/models/apiV2/response/register-response-dto.model';
import { selectIpData, selectLocale } from '@/store/selectors/commonSelectors';
import { selectUserBtag } from '@/store/selectors/combined.selectors';
import { sha256Hash } from '@/shared/crypto-utils';
import { logException } from '@/utils/logger';
import { ApiV2Error } from '@/shared/errorHandler';
import { openModal } from '@/store/actions/modal';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import { loginSuccess } from '@/store/actions/auth';
import { triggerNetreferSignup } from '@/shared/netrefer';
import ApiService from '@/shared/apiService';
import SearchDropdownField from '@/components/form/search-dropdown/SearchDropdownField';
import { selectRegistrationOpenedFrom } from '@/store/selectors/uiSelectors';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import Anchor from '@/components/UI/Buttons/Anchor';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepActions,
} from '@/store/authentication/AuthenticationStore';

type FormValues = {
  fullName: string;
  email: string;
  country: string;
  province: string;
  password: string;
};

const countries = FAST_REG_COUNTRIES.map(country => ({
  value: country.code,
  label: country.name,
  endIcon: <ReactCountryFlag countryCode={country.code.toLocaleUpperCase()} />,
}));
const provinces = CANADA_PROVINCES.map(province => ({
  value: province.code,
  label: province.name,
}));

const FastRegistrationForm = () => {
  const [error, setError] = useState<string | undefined>();
  const { request, loading, success } = useApiV2Request<RegisterResponse>();
  const intl = useIntl();
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);
  const ipData = useSelector(selectIpData);
  const btag = useSelector(selectUserBtag);
  const registrationOpenedFrom = useSelector(selectRegistrationOpenedFrom);
  const navigate = useAbsoluteNavigate();

  const { setHomeHeroAuthenticationStep } = useHomeHeroAuthenticationStepActions();

  const handleLogin = () => {
    triggerGTag(GTagEvents.home_auth_login_click);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.Login);
  };

  const isCanadaLocale = locale === 'ca' || locale === 'ca-fr';

  const defineInitialCountry = useCallback(() => {
    if (isCanadaLocale) return 'ca';
    if (locale === 'row') {
      // Check if the country from IP data is in the array of our supported countries
      const activeCountry =
        countries.find(country => country.value === ipData.country.toLowerCase())?.value ?? '';
      if (ipData && ipData.country && activeCountry) {
        return activeCountry;
      } else {
        return '';
      }
    }
    return locale;
  }, [ipData, locale]);

  const initialFormValues: FormValues = {
    fullName: '',
    email: '',
    country: defineInitialCountry(),
    province: '',
    password: '',
  };

  const formSchema = Yup.object().shape({
    fullName: Yup.string()
      .matches(
        /^[\p{L}\p{M}'-]+(\s[\p{L}\p{M}'-]+)+$/u,
        intl.formatMessage({ id: 'fastReg.name.error.invalid' }),
      )
      .trim()
      .required(),
    email: Yup.string()
      .required()
      .email(intl.formatMessage({ id: 'fastReg.email.error.invalid' })),
    country: Yup.string().required(),
    province: Yup.object().when('country', country => {
      if (country === 'ca' || isCanadaLocale) {
        return Yup.string().required();
      }
      return Yup.string().notRequired();
    }),
    password: Yup.string()
      .test(
        'no-whitespace',
        intl.formatMessage({ id: 'fastReg.password.whitespaces.invalid' }),
        value => !(value && /\s/.test(value)),
      )
      .matches(/^\S{6,}$/, intl.formatMessage({ id: 'fastReg.password.invalid' }))
      .required(),
  });

  const handleSubmit = (values: FormValues) => {
    // push the hashed email to the dataLayer
    sha256Hash(values.email)
      .then(hashedEmail => {
        window.dataLayer.push({
          email: hashedEmail,
        });
      })
      .catch(e => logException(e));

    const handleError = (error: ApiV2Error) => {
      if (error.error === 'USERNAME_ALREADY_EXISTS' || error.error === 'EMAIL_ALREADY_EXISTS') {
        dispatch(
          openModal('basicModal', {
            title: intl.formatMessage({ id: 'fastReg.existingUser.title' }),
            body: intl.formatMessage({ id: 'fastReg.existingUser.body' }),
            btnText: intl.formatMessage({ id: 'login.login' }),
            onBtnClick: () => {
              dispatch(openModal('login'));
              triggerGTag(GTagEvents.fast_reg_existing_acc_login_click);
            },
            onClose: () => triggerGTag(GTagEvents.fast_reg_existing_acc_close),
          }),
        );
        triggerGTag(GTagEvents.fast_reg_existing_acc_show, {
          error: `[${error.errorId}] - ${error.error}`,
        });
      } else if (error.error === 'VALIDATION_ERROR') {
        setError(
          intl.formatMessage({
            id: 'error.register.validation',
          }),
        );
        triggerGTag(GTagEvents.fast_reg_failed, { error: `[${error.errorId}] - ${error.error}` });
      } else {
        setError(
          `${intl.formatMessage({
            id: 'failedSignup.SomethingWentWrongMessage',
          })} (${error.errorId})`,
        );
        triggerGTag(GTagEvents.fast_reg_failed, { error: `[${error.errorId}] - ${error.error}` });
        logException(error);
      }
    };

    const handleSuccess = (data: RegisterResponse) => {
      dispatch(loginSuccess(data.token, { isRegistration: true }));
      triggerGTag(GTagEvents.fast_reg_success, { userId: data.userId });
      triggerNetreferSignup(btag, data.userId, locale);

      // do specific redirects for some cases if registration was started on specific place
      if (registrationOpenedFrom === OpenedFrom.RewardsWheel) {
        navigate('/rewards-wheel');
      }
    };

    let countryCode = '';
    if (locale === 'row') {
      countryCode = values.country;
    } else {
      if (isCanadaLocale) {
        // We send 'ca' for both /ca and /ca-fr locales
        countryCode = 'ca';
      } else {
        countryCode = locale;
      }
    }

    const payload = {
      userData: {
        email: values.email,
        password: values.password,
        firstName: values.fullName.split(' ')[0],
        lastName: values.fullName.split(' ').slice(1).join(' '),
        countryCode,
        ...((isCanadaLocale || values.country === 'ca') && {
          provinceCode: values.province,
        }),
      },
      btag,
    };

    request(ApiService.registerShort, payload, handleSuccess, handleError);
  };

  return (
    <>
      {error && (
        <div className="[&_p]:!text-base">
          <PromptMessage type="error" message={error} />
        </div>
      )}
      <Formik
        initialValues={initialFormValues}
        validationSchema={formSchema}
        validateOnMount
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ isValid, values }) => {
          return (
            <Form
              id="e2e_fastReg"
              autoComplete="off"
              className="flex flex-col gap-4 [&>button:last-of-type]:!mx-0 [&>button:last-of-type]:min-h-[50px]"
            >
              <FastRegistrationFormInput
                type="text"
                name="fullName"
                label={intl.formatMessage({ id: 'inputs.fullName' })}
                onFocus={() => triggerGTag(GTagEvents.fast_reg_name_click)}
                onValidationError={error => triggerGTag(GTagEvents.fast_reg_name_error, { error })}
                className="capitalize"
              />
              <FastRegistrationFormInput
                data-cy="registration-form-email"
                type="email"
                name="email"
                label={intl.formatMessage({
                  id: 'inputs.email',
                })}
                onFocus={() => triggerGTag(GTagEvents.fast_reg_email_click)}
                onValidationError={error => triggerGTag(GTagEvents.fast_reg_email_error, { error })}
                autoComplete="new-email"
              />
              <FastRegistrationFormPassword
                label={intl.formatMessage({
                  id: 'inputs.password',
                })}
                name="password"
                onFocus={() => triggerGTag(GTagEvents.fast_reg_password_click)}
                onValidationError={error =>
                  triggerGTag(GTagEvents.fast_reg_password_error, { error })
                }
                autoComplete="new-password"
              />
              {locale === 'row' && (
                <SearchDropdownField
                  labelClassName="text-white"
                  inputClassName="text-white"
                  name="country"
                  label={intl.formatMessage({
                    id: 'inputs.country',
                  })}
                  options={countries}
                  onFocus={() => triggerGTag(GTagEvents.fast_reg_country_click)}
                  onValidationError={error =>
                    triggerGTag(GTagEvents.fast_reg_country_error, { error })
                  }
                />
              )}
              {(isCanadaLocale || values.country === 'ca') && (
                <SearchDropdownField
                  labelClassName="text-white"
                  inputClassName="text-white"
                  name="province"
                  label={intl.formatMessage({
                    id: 'inputs.province',
                  })}
                  options={provinces}
                  onFocus={() => triggerGTag(GTagEvents.fast_reg_province_click)}
                  onValidationError={error =>
                    triggerGTag(GTagEvents.fast_reg_province_error, { error })
                  }
                />
              )}
              <BtnOneLineBlue
                type="submit"
                isLoading={loading}
                disabled={!isValid}
                text={
                  !loading && success
                    ? intl.formatMessage({ id: 'fastReg.success' })
                    : intl.formatMessage({ id: 'header.register' })
                }
                onClickHandler={() => triggerGTag(GTagEvents.fast_reg_cta_click)}
              />
            </Form>
          );
        }}
      </Formik>
      <div className="text-base text-white mt-3">
        <FormattedMessage
          id="fastReg.login"
          values={{
            a: str => (
              <span
                data-cy="registration-form-switch-login-button"
                onClick={handleLogin}
                className="text-blue-blue font-bold underline cursor-pointer"
              >
                {str}
              </span>
            ),
          }}
        />
      </div>
      <p className="my-3 block !text-[12px] italic text-white">
        <FormattedMessage
          id="fastReg.consent.text"
          values={{
            a: str => (
              <span
                onClick={() => navigate('/terms-and-conditions')}
                className="text-blue-blue font-bold underline cursor-pointer"
              >
                {str}
              </span>
            ),
          }}
        />
      </p>
      <Anchor
        to=""
        href=""
        className="!no-underline !text-sm text-white"
        onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
          event.preventDefault();
          const bonusInfo = document.getElementById('bonus-info');
          if (bonusInfo !== null) {
            bonusInfo.scrollIntoView({
              block: 'start',
              behavior: 'smooth',
            });
          }
          triggerGTag(GTagEvents.more_info);
        }}
      >
        {intl.formatMessage({ id: 'hero.moreInfo' })}
      </Anchor>
    </>
  );
};

export default FastRegistrationForm;
