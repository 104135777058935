import React from 'react';

import RegistrationIntroCanada from '@/features/authentication/registration/canada/RegistrationIntroCanada';

const HomeHeroAuthenticationIntro = () => {
  // In the future, we will use different conditionals here to determine which Authentication Intro to display
  // right now defaults to Canada, because it's the only one that needs intro
  return <RegistrationIntroCanada />;
};

export default HomeHeroAuthenticationIntro;
