import { useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { isSplashTechEnabled } from '@/utils/logic.utils';
import { selectAuthToken } from '@/store/selectors/authSelectors';
import { envIsMexico, envIsNormal, SPLASHTECH_CLIENT, SPLASHTECH_URL } from '@/constants/constants';
import { isRegulatedLocale } from '@/shared/utility';

const getTenantId = (locale: string) => {
  if (envIsNormal() && !isRegulatedLocale(locale)) {
    return 67;
  }

  return 96;
};

export function useRewardsWheel(): { isEnabled: boolean; url: string | null; pagePath: string } {
  const locale = useSelector(selectLocale);
  const token = useSelector(selectAuthToken);

  const tenantId = getTenantId(locale);
  const pagePath = envIsMexico() ? 'rueda-de-la-suerte' : 'rewards-wheel';

  return {
    isEnabled: isSplashTechEnabled(locale),
    url: token
      ? `${SPLASHTECH_URL}?client=${SPLASHTECH_CLIENT}&game=WHEEL&token=${token}&tenantId=${tenantId}`
      : null,
    pagePath,
  };
}
