import { call, put, PutEffect, CallEffect } from 'redux-saga/effects';
import { checkDevice } from '@/shared/utility';
import { AxiosResponse } from 'axios';
import BffApiService from '@/shared/bffApiService';
import {
  BffActiveBonusesResponse,
  BffAvailableBonusesResponse,
  BffResponse,
  DeviceTypeId,
} from '@lucky7ventures/bff-types';
import {
  getAvailableBonusesFail,
  getAvailableBonusesSuccess,
  getUserBonusesFail,
  getUserBonusesSuccess,
} from '@/store/actions/bonusesActions';

export function* getUserBonusesSaga(): Generator<
  CallEffect<AxiosResponse<BffResponse<BffActiveBonusesResponse>>> | PutEffect,
  void,
  AxiosResponse<BffResponse<BffActiveBonusesResponse>>
> {
  try {
    const response: AxiosResponse = yield call(BffApiService.getActiveBonuses);
    yield put(getUserBonusesSuccess(response.data.data));
  } catch (error) {
    yield put(getUserBonusesFail());
  }
}

export function* getAvailableBonusesSaga(): Generator<
  CallEffect<AxiosResponse<BffResponse<BffAvailableBonusesResponse>>> | PutEffect,
  void,
  AxiosResponse<BffResponse<BffAvailableBonusesResponse>>
> {
  const device: DeviceTypeId = checkDevice();

  try {
    const response = yield call(BffApiService.getAvailableBonuses, {
      payload: {
        deviceTypeId: device,
      },
    });
    if (response.data.data) {
      yield put(getAvailableBonusesSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getAvailableBonusesFail());
  }
}
