import React from 'react';
import { PromotionWidgetVm } from '@/models/vm/promotion-widget-vm.model';
import PromotionWidget from '@/pages/promotions/PromotionWidget';

function PromotionsOverview({ promotions }: { promotions: PromotionWidgetVm[] }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 my-8 px-4">
      {promotions.map(promotion => (
        <PromotionWidget promotion={promotion} key={`promotion-${promotion.slug}`} />
      ))}
    </div>
  );
}

export default PromotionsOverview;
