import { create } from 'zustand';
import StorageManager from '@/shared/storageManager';

interface CookieConsentState {
  accepted: boolean;
  performance: boolean;
  acceptCookies: (performance: boolean) => void;
}

export const useCookieConsentStore = create<CookieConsentState>(set => {
  // Get the current value from Local Storage
  const currentConsent = StorageManager.getCookiesConsent();
  return {
    accepted: currentConsent.accepted,
    performance: currentConsent.performance,
    acceptCookies: (performance: boolean) => {
      set(state => ({ ...state, accepted: true, performance }));
      // Save the result to the Local Storage
      StorageManager.saveCookiesConsent(true, performance);
    },
  };
});
