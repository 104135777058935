import React from 'react';

import { useIntl } from 'react-intl';

import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import Anchor from '@/components/UI/Buttons/Anchor';
import { openModal } from '@/store/actions/modal';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@/components/buttons/Button';

import { CDN_BASE, CDN_IMAGES_COMMON_PATH } from '@/constants/constants';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { selectHeroWelcomeTextsHtml } from '@/store/cms/welcome-texts/selectors';
import classNames from 'classnames';

const RegistrationIntroCanada = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useAbsoluteNavigate();
  const welcomeTextHeroHtml = useSelector(selectHeroWelcomeTextsHtml);

  return (
    <div className="flex flex-col gap-y-6 md:gap-y-10 items-center p-[16px] md:p-0">
      <div
        data-cy="authentication-canada-registration-intro"
        className={classNames(
          'pt-28 max-w-[900px] flex flex-col items-center',
          '[&_h1]:text-4xl [&_h1]:sm:text-5xl [&_h1]:md:text-6xl [&_h1]:text-white [&_h1]:text-center [&_h1]:m-0 [&_h1]:mb-6 [&_h1]:sm:mb-10',
          '[&_p]:text-white [&_p]:max-w-[700px] [&_p]:m-0 [&_p]:mb-4',
          '[&_h2]:text-white [&_h2]:m-0 [&_h2]:mb-4 [&_h2]:text-center [&_h2]:text-2xl [&_h2]:sm:text-3xl [&_h2]:md:text-4xl [&_h2]:sm:mb-8 ',
          '[&_strong]:text-blue-blue ',
        )}
      >
        {welcomeTextHeroHtml}
        <button
          type="button"
          onClick={() => dispatch(openModal('bonusTermsAndConditions'))}
          className="bold italic underline text-white leading-snug"
        >
          {intl.formatMessage({
            id: 'authentication.registration.canada.description.terms-and-conditions',
          })}
        </button>
      </div>
      <div className="flex flex-col space-y-4 max-w-[300px] w-full">
        <Button
          data-cy="authentication-canada-registration-intro-button"
          type="button"
          text={intl.formatMessage({ id: 'authentication.registration.canada.initiate' })}
          onClickHandler={() => navigate('/register')}
        />
        <Anchor
          to=""
          href=""
          className="!no-underline !text-sm text-white"
          onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            const bonusInfo = document.getElementById('bonus-info');
            if (bonusInfo !== null) {
              bonusInfo.scrollIntoView({
                block: 'start',
                behavior: 'smooth',
              });
            }
            triggerGTag(GTagEvents.more_info);
          }}
        >
          {intl.formatMessage({ id: 'hero.moreInfo' })}
        </Anchor>
      </div>

      <div className="mt-4">
        <img
          src={`${CDN_BASE}/cdn-cgi/image/fit=cover,width=100,height=auto,format=auto,dpr=2${CDN_IMAGES_COMMON_PATH}/footer/igamingontario.png`}
          alt="iGaming Ontario"
          className="mx-auto"
        />
      </div>
    </div>
  );
};

export default RegistrationIntroCanada;
