import React from 'react';
import classNames from 'classnames';

type CardProps = {
  children: React.ReactNode;
  className?: string;
};

const Card = ({ children, className }: CardProps): JSX.Element => {
  return (
    <div className={classNames('rounded-xl bg-white p-4 md:p-8 shadow-md', className)}>
      {children}
    </div>
  );
};

export default Card;
