import React, { lazy, Suspense } from 'react';

import { envIsMexico } from '@/constants/constants';
import useApkDownload from '@/hooks/useApkDownload';

const GamesHeaderMexico = lazy(() => import('./GamesHeaderMexico'));
const GamesHeaderNormal = lazy(() => import('./GamesHeaderNormal'));
const GamesHeaderRest = lazy(() => import('./GamesHeaderRest'));

const GamesHeader = ({
  searchValue,
  setSearchValue,
}: {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { isApkDownloadAvailable } = useApkDownload();

  const renderHeader = () => {
    if (envIsMexico()) {
      return <GamesHeaderMexico />;
    } else if (isApkDownloadAvailable) {
      return <GamesHeaderNormal />;
    } else {
      return <GamesHeaderRest searchValue={searchValue} setSearchValue={setSearchValue} />;
    }
  };

  return <Suspense fallback={<div>Loading header...</div>}>{renderHeader()}</Suspense>;
};

export default GamesHeader;
