import React from 'react';
import { useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { PayNPlayHome } from '@/features/authentication/pay-n-play/PayNPlayHome';
import { HeroBankingLogos } from '@/components/HeroBankingLogos';
import { cdnImage } from '@/utils/image-utils';
import { selectHeroWelcomeTextsHtml } from '@/store/cms/welcome-texts/selectors';
import classNames from 'classnames';

const HomeHeroContentPublicPayNPlay = (): JSX.Element => {
  const locale = useSelector(selectLocale);
  const homeHeroHtml = useSelector(selectHeroWelcomeTextsHtml);
  return (
    <div className="flex w-full flex-col">
      <div className="mx-auto flex w-full max-w-6xl py-16 px-6 md:space-x-8 pb-24">
        <div className="relative mb-10 hidden w-full flex-col justify-center text-white md:flex">
          <div className="mt-6 space-y-4 text-xl">
            <div
              className={classNames(
                '[&_h1]:text-white [&_h1]:m-0 [&_h1]:mb-8 [&_h1]:text-4xl',
                '[&_h2]:text-white [&_h2]:m-0 [&_h2]:mb-6 [&_h2]:text-3xl',
                '[&_strong]:text-blue-blue',
              )}
            >
              {homeHeroHtml}
            </div>
            <div className="flex items-center gap-4 flex-wrap">
              <img
                src={cdnImage('trustly.png', { width: '130px', height: 'auto', dpr: '2' })}
                className="brightness-0 invert"
                alt="Trustly logo"
                width="130px"
              />
              <img
                src={cdnImage('bankid.png', { width: '32px', height: 'auto', dpr: '2' })}
                className="brightness-0 invert"
                alt="BankID logo"
                width="32px"
              />
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col items-center justify-center text-center md:justify-end">
          <PayNPlayHome />
        </div>
      </div>
      <HeroBankingLogos locale={locale} />
    </div>
  );
};

export default HomeHeroContentPublicPayNPlay;
