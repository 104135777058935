import React from 'react';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import EmailField from '@/components/form/email/EmailField';
import PasswordField from '@/components/form/password/PasswordField';
import { Button } from '@/components/buttons/Button';
import Form from '@/components/form/Form';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepActions,
} from '@/store/authentication/AuthenticationStore';
import { useSimpleRequest } from '@/hooks/useSimpleRequest';
import { AuthResponseDto } from '@lucky7ventures/lucky-components';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '@/store/actions/auth';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';
import { ApiError } from '@/models/api-error.model';
import PromptMessage from '@/components/UI/PromptMessage';
import { closeSpecificModal } from '@/store/actions/modal';
import { envIsCanada } from '@/constants/constants';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';

interface LoginPayload {
  username: string;
  password: string;
}

const initialValues = {
  email: '',
  password: '',
};

const LoginForm = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useAbsoluteNavigate();
  const { loading, error, request } = useSimpleRequest<AuthResponseDto, LoginPayload>();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage({ id: 'input.error.required' }))
      .email(intl.formatMessage({ id: 'inputs.email.invalid' }))
      .trim(),
    password: Yup.string()
      .required(intl.formatMessage({ id: 'input.error.required' }))
      .trim(),
  });

  const { setHomeHeroAuthenticationStep, setAuthenticationCredentials } =
    useHomeHeroAuthenticationStepActions();

  const handleSwitchAuthMethod = () => {
    setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.Registration);
    if (envIsCanada()) navigate('/register');

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    dispatch(closeSpecificModal('login'));
  };

  const handleForgotPasswordMethod = () => {
    setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.ForgotPassword);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const successCallback = (data: AuthResponseDto, username: string, password: string) => {
    if (data.Result === 'SUCCESSFUL') {
      dispatch(loginSuccess(data.Token));
      triggerGTag(GTagEvents.login_success);
    } else if (data.Result === 'TWO_FACTOR_FIRST_STEP_SUCCESSFUL') {
      setAuthenticationCredentials(username, password);
      setHomeHeroAuthenticationStep(HomeHeroAuthenticationStep.TwoFactorAuthentication);
      triggerGTag(GTagEvents.login_2fa);
    }
  };

  const errorCallback = (error: ApiError) => {
    triggerGTag(GTagEvents.login_error, {
      errorId: error.id,
      error: error.message,
    });
  };

  const handleSubmit = ({ email, password }: { email: string; password: string }) => {
    const payload = { username: email, password };

    request(
      '/api/auth/v2/login',
      payload,
      data => successCallback(data, email, password),
      errorCallback,
    );
  };

  return (
    <Form
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      className="flex flex-col w-full justify-between gap-5"
    >
      {() => (
        <>
          <EmailField
            data-cy="login-form-email"
            label={intl.formatMessage({ id: 'inputs.email' })}
            name="email"
            className="text-white border-[1px] border-transparent focus-within:border-blue-blue bg-white/20"
          />
          <PasswordField
            data-cy="login-form-password"
            label={intl.formatMessage({ id: 'inputs.password' })}
            name="password"
            className="text-white border-[1px] border-transparent focus-within:border-blue-blue bg-white/20"
          />
          <div className="flex flex-col items-center mt-4">
            {error && <PromptMessage type="error" message={error} />}
            <Button
              text={intl.formatMessage({ id: 'authentication.login.login-button' })}
              type="submit"
              btnType="primary"
              data-cy="login-form-submit-button"
              isLoading={loading}
            />
            <div className="text-white text-[15px] text-center mt-6">
              <FormattedMessage
                id="authentication.login.change-to-register"
                values={{
                  a: str => (
                    <span
                      data-cy="login-form-switch-registration-button"
                      onClick={handleSwitchAuthMethod}
                      className="text-blue-blue font-bold underline cursor-pointer"
                    >
                      {str}
                    </span>
                  ),
                }}
              />
            </div>
            <p className="max-w-[80%] mt-3 block !text-[12px] italic text-white mb-0">
              <FormattedMessage
                id="authentication.login.forgot-password"
                values={{
                  a: str => (
                    <span
                      data-cy="login-form-switch-forgot-password-button"
                      onClick={handleForgotPasswordMethod}
                      className="text-blue-blue font-bold underline cursor-pointer"
                    >
                      {str}
                    </span>
                  ),
                }}
              />
            </p>
          </div>
        </>
      )}
    </Form>
  );
};

export default LoginForm;
