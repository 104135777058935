import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { useEffect } from 'react';
import { getWelcomeTexts } from '@/store/cms/welcome-texts/actions';

export const useWelcomeTextsLoader = () => {
  const locale = useSelector(selectLocale);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!locale) {
      return;
    }

    dispatch(getWelcomeTexts());
  }, [locale]);
};
