import { BffCmsPromotionResponse, CmsPromoVisibility } from '@lucky7ventures/bff-types';

export const filterPromotionSlugs = (params: {
  slugs: string[];
  promotions: Record<string, BffCmsPromotionResponse>;
  isAuthenticated: boolean;
  btagPrefix: string | null;
  userTags: string[];
}): string[] => {
  const { slugs, promotions, isAuthenticated, userTags, btagPrefix } = params;
  return slugs.reduce((acc: string[], slug) => {
    const promotion = promotions[slug];
    if (promotion) {
      // there are several layers of filtering that need to be applied

      // 1. visibility - depends on the logged in status of the user
      // if the promotion is visible only to logged out users, and the user is logged in, skip
      if (promotion.visibility === CmsPromoVisibility.LoggedOut && isAuthenticated) {
        return acc;
      }
      // if the promotion is visible to only logged in users, and the user is logged out, skip
      if (promotion.visibility === CmsPromoVisibility.LoggedIn && !isAuthenticated) {
        return acc;
      }

      // 2. excluded tags - if the promotion has excluded tags, and the user has one of those tags, skip
      if (
        promotion.excludedTags.length > 0 &&
        userTags.some(tag => promotion.excludedTags.includes(tag))
      ) {
        return acc;
      }

      // 3. exclusive tags - if the promotion has exclusive tags, and the user doesn't have at least one of those tags, skip
      if (
        promotion.exclusiveTags.length > 0 &&
        !userTags.some(tag => promotion.exclusiveTags.includes(tag))
      ) {
        return acc;
      }

      // 4. exclusive btags - if the promotions has an exclusive btag, and the user doesn't have that btag, skip
      if (
        promotion.exclusiveBtags &&
        promotion.exclusiveBtags.length > 0 &&
        (!btagPrefix || !promotion.exclusiveBtags.includes(btagPrefix))
      ) {
        return acc;
      }

      acc.push(promotion.slug);
    }
    return acc;
  }, []);
};
