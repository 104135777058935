import TagManager from 'react-gtm-module';
import { HomeHeroAuthenticationStep } from '@/store/authentication/AuthenticationStore';

export enum GTagEvents {
  'authentication_switch_method' = 'authentication_switch_method',
  'bottom_nav_cashier_click' = 'bottom_nav_cashier_click',
  'bottom_nav_games_click' = 'bottom_nav_games_click',
  'bottom_nav_home_click' = 'bottom_nav_home_click',
  'bottom_nav_menu_click' = 'bottom_nav_menu_click',
  'bottom_nav_search_click' = 'bottom_nav_search_click',
  'cashier_bonus_forfeited' = 'cashier_bonus_forfeited',
  'cashier_choose_deposit_amount' = 'cashier_choose_deposit_amount',
  'cashier_choose_deposit_method' = 'cashier_choose_deposit_method',
  'cashier_choose_withdrawal_account' = 'cashier_choose_withdrawal_account',
  'cashier_choose_withdrawal_amount' = 'cashier_choose_withdrawal_amount',
  'cashier_choose_withdrawal_method' = 'cashier_choose_withdrawal_method',
  'cashier_deposit_error' = 'cashier_deposit_error',
  'cashier_drawer_close' = 'cashier_drawer_close',
  'cashier_drawer_open' = 'cashier_drawer_open',
  'cashier_ftd_error' = 'cashier_ftd_error',
  'cashier_init_start' = 'cashier_init_start',
  'cashier_ready' = 'cashier_ready',
  'cashier_withdrawal_cancelled' = 'cashier_withdrawal_cancelled',
  'cashier_withdrawal_error' = 'cashier_withdrawal_error',
  'cashier_withdrawal_success' = 'cashier_withdrawal_success',
  'complete_profile_details_click' = 'complete_profile_details_click',
  'complete_profile_failed' = 'complete_profile_failed',
  'complete_profile_game_click' = 'complete_profile_game_click',
  'complete_profile_modal_close' = 'complete_profile_modal_close',
  'complete_profile_modal_open' = 'complete_profile_modal_open',
  'complete_profile_submit_click' = 'complete_profile_submit_click',
  'complete_profile_success' = 'complete_profile_success',
  'deposit_success' = 'deposit_success',
  'fast_reg_country_click' = 'fast_reg_country_click',
  'fast_reg_country_error' = 'fast_reg_country_error',
  'fast_reg_cta_click' = 'fast_reg_cta_click',
  'fast_reg_email_click' = 'fast_reg_email_click',
  'fast_reg_email_error' = 'fast_reg_email_error',
  'fast_reg_existing_acc_close' = 'fast_reg_existing_acc_close',
  'fast_reg_existing_acc_login_click' = 'fast_reg_existing_acc_login_click',
  'fast_reg_existing_acc_show' = 'fast_reg_existing_acc_show',
  'fast_reg_failed' = 'fast_reg_failed',
  'home_auth_login_click' = 'home_auth_login_click',
  'fast_reg_name_click' = 'fast_reg_name_click',
  'fast_reg_name_error' = 'fast_reg_name_error',
  'fast_reg_password_click' = 'fast_reg_password_click',
  'fast_reg_password_error' = 'fast_reg_password_error',
  'fast_reg_province_click' = 'fast_reg_province_click',
  'fast_reg_province_error' = 'fast_reg_province_error',
  'fast_reg_success' = 'fast_reg_success',
  'first_deposit' = 'first_deposit',
  'fluid_cashier_error' = 'fluid_cashier_error',
  'fluid_show_init' = 'fluid_show_init',
  'fluid_show_not_init' = 'fluid_show_not_init',
  'forgot_password_error' = 'forgot_password_error',
  'forgot_password_submit' = 'forgot_password_submit',
  'forgot_password_success' = 'forgot_password_success',
  'ftd_exit_intent_appeared' = 'ftd_exit_intent_appeared',
  'ftd_exit_intent_closed' = 'ftd_exit_intent_closed',
  'ftd_exit_intent_deposit' = 'ftd_exit_intent_deposit',
  'game_launch' = 'game_launch',
  'games_all_games_game' = 'games_all_games_game',
  'games_bonus_buy_game' = 'games_bonus_buy_game',
  'games_buy_feature_game' = 'games_buy_feature_game',
  'games_crash_games_game' = 'games_crash_games_game',
  'games_jackpots_game' = 'games_jackpots_game',
  'games_last_played_games_game' = 'games_last_played_games_game',
  'games_live_casino_game' = 'games_live_casino_game',
  'games_megaways_game' = 'games_megaways_game',
  'games_new_games_game' = 'games_new_games_game',
  'games_recommended_game' = 'games_recommended_game',
  'games_slots_game' = 'games_slots_game',
  'games_table_games_game' = 'games_table_games_game',
  'games_video_bingo_game' = 'games_video_bingo_game',
  'header_help_click' = 'header_help_click',
  'header_login_click' = 'header_login_click',
  'header_open_cashier' = 'header_open_cashier',
  'header_register_click' = 'header_register_click',
  'header_search_click' = 'header_search_click',
  'hero_btn_deposit_click' = 'hero_btn_deposit_click',
  'hero_btn_games_click' = 'hero_btn_games_click',
  'home_bonus_buy_game' = 'home_bonus_buy_game',
  'home_hero_bonus_click' = 'home_hero_bonus_click',
  'home_jackpots_game' = 'home_jackpots_game',
  'home_live_casino_game' = 'home_live_casino_game',
  'home_new_games_game' = 'home_new_games_game',
  'home_recommended_game' = 'home_recommended_game',
  'home_slots_game' = 'home_slots',
  'home_video_bingo_game' = 'home_video_bingo_game',
  'location_smart_error' = 'location_smart_error',
  'location_smart_fail' = 'location_smart_fail',
  'location_smart_start' = 'location_smart_start',
  'location_smart_start_recheck' = 'location_smart_start',
  'location_smart_success' = 'location_smart_success',
  'login_2fa' = 'login_2fa',
  'login_2fa_submit' = 'login_2fa_submit',
  'login_2fa_error' = 'login_2fa_error',
  'login_2fa_success' = 'login_2fa_success',
  'login_auto' = 'login_auto',
  'login_error' = 'login_error',
  'login_success' = 'login_success',
  'logout' = 'logout',
  'logout_success' = 'logout_success',
  'ont_reg_3rdparty_click' = 'ont_reg_3rdparty_click',
  'ont_reg_3rdparty_error' = 'ont_reg_3rdparty_error',
  'ont_reg_address_click' = 'ont_reg_address_click',
  'ont_reg_address_error' = 'ont_reg_address_error',
  'ont_reg_citizenship_click' = 'ont_reg_citizenship_click',
  'ont_reg_citizenship_error' = 'ont_reg_citizenship_error',
  'ont_reg_city_click' = 'ont_reg_city_click',
  'ont_reg_city_error' = 'ont_reg_city_error',
  'ont_reg_consent_fit_to_play_click' = 'ont_reg_consent_fit_to_play_click',
  'ont_reg_consent_fit_to_play_error' = 'ont_reg_consent_fit_to_play_error',
  'ont_reg_dob_click' = 'ont_reg_dob_click',
  'ont_reg_dob_error' = 'ont_reg_dob_error',
  'ont_reg_email_click' = 'ont_reg_email_click',
  'ont_reg_email_error' = 'ont_reg_email_error',
  'ont_reg_email_exists_error' = 'ont_reg_email_exists_error',
  'ont_reg_error_help_click' = 'ont_reg_error_help_click',
  'ont_reg_error_home_click' = 'ont_reg_error_home_click',
  'ont_reg_existing_acc_close' = 'ont_reg_existing_acc_close',
  'ont_reg_existing_acc_login_click' = 'ont_reg_existing_acc_login_click',
  'ont_reg_existing_acc_show' = 'ont_reg_existing_acc_show',
  'ont_reg_failed' = 'ont_reg_failed',
  'ont_reg_first_name_click' = 'ont_reg_first_name_click',
  'ont_reg_first_name_error' = 'ont_reg_first_name_error',
  'ont_reg_home_email_click' = 'ont_reg_home_email_click',
  'ont_reg_home_email_submit' = 'ont_reg_home_email_submit',
  'ont_reg_industry_click' = 'ont_reg_industry_click',
  'ont_reg_industry_error' = 'ont_reg_industry_error',
  'ont_reg_last_name_click' = 'ont_reg_last_name_click',
  'ont_reg_last_name_error' = 'ont_reg_last_name_error',
  'ont_reg_marketing_click' = 'ont_reg_marketing_click',
  'ont_reg_mobile_click' = 'ont_reg_mobile_click',
  'ont_reg_mobile_error' = 'ont_reg_mobile_error',
  'ont_reg_mobile_exists_error' = 'ont_reg_mobile_exists_error',
  'ont_reg_mobile_invalid_error' = 'ont_reg_mobile_invalid_error',
  'ont_reg_number_click' = 'ont_reg_number_click',
  'ont_reg_number_error' = 'ont_reg_number_error',
  'ont_reg_occupation_click' = 'ont_reg_occupation_click',
  'ont_reg_occupation_error' = 'ont_reg_occupation_error',
  'ont_reg_password_click' = 'ont_reg_password_click',
  'ont_reg_password_error' = 'ont_reg_password_error',
  'ont_reg_pep_click' = 'ont_reg_pep_click',
  'ont_reg_pep_error' = 'ont_reg_pep_error',
  'ont_reg_province_click' = 'ont_reg_province_click',
  'ont_reg_province_error' = 'ont_reg_province_error',
  'ont_reg_step1_continue_cta_click' = 'ont_reg_step1_continue_cta_click',
  'ont_reg_step2_back_cta_click' = 'ont_reg_step2_back_cta_click',
  'ont_reg_step2_continue_cta_click' = 'ont_reg_step2_continue_cta_click',
  'ont_reg_step3_back_cta_click' = 'ont_reg_step3_back_cta_click',
  'ont_reg_step3_continue_cta_click' = 'ont_reg_step3_continue_cta_click',
  'ont_reg_step4_back_cta_click' = 'ont_reg_step4_back_cta_click',
  'ont_reg_step4_submit_cta_click' = 'ont_reg_step4_submit_cta_click',
  'ont_reg_success' = 'ont_reg_success',
  'ont_reg_terms_click' = 'ont_reg_terms_click',
  'ont_reg_terms_error' = 'ont_reg_terms_error',
  'ont_reg_zipcode_click' = 'ont_reg_zipcode_click',
  'ont_reg_zipcode_error' = 'ont_reg_zipcode_error',
  'open_category' = 'open_category',
  'open_registration' = 'open_registration',
  'ping_login_failed' = 'ping_login_failed',
  'reset_password_error' = 'reset_password_error',
  'reset_password_submit' = 'reset_password_submit',
  'reset_password_success' = 'reset_password_success',
  'reverification_accepted' = 'reverification_accepted',
  'reverification_error' = 'reverification_error',
  'reverification_logout' = 'reverification_logout',
  'reverification_modified' = 'reverification_modified',
  'reverification_triggered' = 'reverification_triggered',
  'sidenav_cashier_click' = 'sidenav_cashier_click',
  'sidenav_login_click' = 'sidenav_login_click',
  'user_claimed_bonus' = 'user_claimed_bonus',
  'user_verify_approved' = 'user_verify_approved',
  'user_verify_error' = 'user_verify_error',
  'user_verify_pep' = 'user_verify_pep',
  'user_verify_rejected' = 'user_verify_rejected',
  'user_verify_start' = 'user_verify_start',
  'user_id_set' = 'user_id_set',
  'user_id_unset' = 'user_id_unset',
  'kyc_upload_success' = 'kyc_upload_success',
  'mx_reg_success' = 'mx_reg_success',
  'mx_reg_submit' = 'mx_reg_submit',
  'mx_reg_error' = 'mx_reg_error',
  'mx_reg_name_valid' = 'mx_reg_name_valid',
  'mx_reg_email_valid' = 'mx_reg_email_valid',
  'regily_error' = 'regily_error',
  'open_support' = 'open_support',
  'more_info' = 'more_info',
  'open_game' = 'open_game',
  'open_game_success' = 'open_game_success',
  'open_game_error' = 'open_game_error',
  'user_took_a_timeout' = 'user_took_a_timeout',
  'low_balance_view' = 'low_balance_view',
  'low_balance_cta' = 'low_balance_cta',
  'pnp_first_deposit' = 'pnp_first_deposit',
  'pnp_deposit' = 'pnp_deposit',
  'pnp_registration' = 'pnp_registration',
  'sowq_modal_open' = 'sowq_modal_open',
  'sowq_modal_opened' = 'sowq_modal_opened',
  'sowq_get_success' = 'sowq_get_success',
  'sowq_get_error' = 'sowq_get_error',
  'sowq_post_success' = 'sowq_post_success',
  'sowq_post_error' = 'sowq_post_error',
  'open_reward_reel' = 'open_reward_reel',
  'rewards_wheel_reminder_shown' = 'rewards_wheel_reminder_shown',
  'rewards_wheel_reminder_close' = 'rewards_wheel_reminder_close',
  'rewards_wheel_reminder_cta' = 'rewards_wheel_reminder_cta',
  'promotion_widget_cta' = 'promotion_widget_cta',
  'promotion_streamline_cta' = 'promotion_streamline_cta',
  'promotion_streamline_view_all' = 'promotion_streamline_view_all',
  'promotion_cta' = 'promotion_cta',
  'games_banner_cta' = 'games_banner_cta',
}

export enum OpenedFrom {
  Header = 'header',
  ReferrerToast = 'referrer-toast',
  GameHeader = 'game-header',
  LoginFirstStep = 'login-first-step',
  UrlParams = 'url-params',
  BonusIntro = 'bonus-intro',
  MenuDrawer = 'menu-drawer',
  Game = 'game',
  Games = 'games',
  GamesHeaderMexico = 'games-header-mexico',
  GamesSearch = 'games-search',
  MexicoRegistration = 'mexico-registration',
  RewardsWheel = 'rewards-wheel',
  PromotionsEmpty = 'promotions-empty',
  PromotionPage = 'promotion-page',
}

type GTagParams = {
  currentMethod: HomeHeroAuthenticationStep;
  newMethod: HomeHeroAuthenticationStep;
  error: string | number;
  errorId: number;
  userId: number;
  gameId: number;
  gameName: string;
  gameType: string;
  gameImg: string;
  gameProviderId: number;
  gameProviderGameId: string;
  gameJackpotAmount: number;
  gameJackpotCurrency: string;
  cashierMethod: string;
  cashierAccount: string;
  openedFrom: string;
  categoryName: string;
  amount: number;
  bonusCode: string;
  currency: string;
  slug: string;
};

export function triggerGTag(
  event: GTagEvents,
  params?: Partial<GTagParams>,
  triggerAdditionalCallback?: () => void,
) {
  // We prefix all events with app_ before sending to GA -> for purposes of easy targeting all events coming from the app
  TagManager.dataLayer({ dataLayer: { event: `app_${event}`, ...params } });
  triggerAdditionalCallback?.();
}
