import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import React, { useMemo } from 'react';
import {
  selectPromotionBySlug,
  selectPromotionsError,
  selectPromotionsInProgress,
} from '@/store/cms/promotions/selectors';
import { useParams } from 'react-router-dom';
import { RootState } from '@/models/root-state.model';
import PromotionContent from '@/pages/promotion/PromotionContent';
import PromotionLoading from '@/pages/promotion/PromotionLoading';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import PromotionError from '@/pages/promotion/PromotionError';

function PromotionPage() {
  const intl = useIntl();
  const promotionsError = useSelector(selectPromotionsError);
  const promotionsLoading = useSelector(selectPromotionsInProgress);
  const { slug } = useParams();
  const promotion = useSelector((state: RootState) => selectPromotionBySlug(state, slug));
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const VisibleComponent = useMemo(() => {
    if (promotionsLoading) {
      return <PromotionLoading />;
    }

    if (!promotion || promotionsError) {
      return <PromotionError />;
    }

    return <PromotionContent promotion={promotion} />;
  }, [promotionsError, promotionsLoading, promotion, isAuthenticated]);

  return (
    <>
      <Helmet>
        <title>
          LuckyDays - {intl.formatMessage({ id: 'promotions.header' })} -{' '}
          {promotion ? promotion.title : intl.formatMessage({ id: '404.header' })}
        </title>
      </Helmet>
      <div className="pt-8 md:pt-24">{VisibleComponent}</div>
    </>
  );
}

export default PromotionPage;
