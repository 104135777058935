import React, { useState } from 'react';

import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { usePsaStore } from '@/store/zustand/psa-store';

const Psa = () => {
  const { psaMessage } = usePsaStore();
  const [isShown, setIsShown] = useState(true);

  if (!psaMessage || !isShown) {
    return null;
  }

  return (
    <div className="flex items-start justify-between space-x-4 bg-slate-900 px-2 py-1 text-white md:items-center md:px-8">
      <div className="flex items-start space-x-4 md:items-center">
        <ExclamationCircleIcon className="mt-1 ml-1 h-5 w-5 md:m-0" />
        <p className="mb-0 flex-1 text-sm">{psaMessage}</p>
      </div>
      <button
        type="button"
        onClick={() => setIsShown(false)}
        className="rounded-md p-1 transition hover:bg-slate-800"
      >
        <XMarkIcon className="h-5 w-5" />
      </button>
    </div>
  );
};

export default Psa;
