import React from 'react';
import LinearGradient from '@/components/shared/gradient/LinearGradient';
import classNames from 'classnames';
import { AbsoluteLink } from '@/components/AbsoluteLink';
import { gameThumbImgMexico } from '@/utils/image-utils';
import { GameCardVM } from '@/models/vm/game-card-vm.model';
import { useSelector } from 'react-redux';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { useLogin } from '@/hooks/useLogin';
import { useIntl } from 'react-intl';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import { PlayIcon } from '@heroicons/react/24/solid';
import { useLocation } from 'react-router-dom';

const OverlayButton = ({ children }: { children: React.ReactNode }) => (
  <div className="bg-blue-blue px-3 py-2 rounded-3xl text-sm text-white font-bold">{children}</div>
);

const GameCardMC = ({
  game,
  large = false,
  category,
  ...props
}: {
  game: GameCardVM;
  large?: boolean;
  category: string;
}) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const { openLogin } = useLogin();
  const intl = useIntl();
  const location = useLocation();

  const shouldOpenLogin = !isAuthenticated && !game.isDemoAvailable;

  const onCardClick = (e?: React.MouseEvent<HTMLAnchorElement>): void => {
    if (shouldOpenLogin) {
      e?.preventDefault();
      openLogin({ openedFrom: OpenedFrom.Games });
    } else {
      triggerGTag(GTagEvents.open_game, {
        openedFrom: location.pathname,
        categoryName: category,
        gameName: game.name,
      });
    }
  };
  const overlayIcon = shouldOpenLogin ? (
    <OverlayButton>{intl.formatMessage({ id: 'gameCard.loginToPlay' })}</OverlayButton>
  ) : (
    <PlayIcon width={40} height={40} fill="white" />
  );

  return (
    <AbsoluteLink
      to={`/games/${game.slug}/${game.gameId}`}
      title={game.name}
      onClick={onCardClick}
      className={classNames(
        'relative rounded-xl border-[1px] w-full h-full overflow-hidden flex flex-col snap-start',
      )}
      {...props}
    >
      <LinearGradient
        from="rgba(255, 162, 10, 0.6)"
        to="rgba(254, 118, 200)"
        direction="bottom right"
        transitionPoint="46%"
      />
      <div className="relative z-[1] flex flex-col justify-end h-full">
        <img
          className="w-full h-full object-cover object-center"
          alt={game.name}
          src={gameThumbImgMexico(game.slug, large)}
        />
        <div className="bg-black/70 min-h-[40px] pt-1 pb-2 px-2 text-[14px] leading-[15px] xs:leading-[18px] truncate text-center text-white font-extrabold">
          {game.name}
          <div className="text-[11px] leading-[11px] font-light text-white/60">
            {game.shortDesc}
          </div>
        </div>
        {overlayIcon && (
          <div className="group bg-transparent absolute inset-0 flex rounded-xl hover:bg-black/50 justify-center items-center transition-all duration-200 ease-in-out">
            <div className="hidden group-hover:block">{overlayIcon}</div>
          </div>
        )}
      </div>
    </AbsoluteLink>
  );
};

export default GameCardMC;
