import { all, AllEffect, call, ForkEffect, put, StrictEffect, takeEvery } from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import { doNothing, isSuccessfulWpResponse, wpApiUrl } from '@/utils/utils';
import {
  GetReferrerContentAction,
  getReferrerContentSuccess,
  WordpressActionType,
} from '../actions/wordpressActions';
import { WpReferrerContent } from '@/models/wp-referrer-content.model';

export function* getReferrerContentSaga(
  action: GetReferrerContentAction,
): Generator<StrictEffect, void, AxiosResponse<WpReferrerContent>> {
  try {
    const response = yield call(axios.get, wpApiUrl(action.locale, `/referrers/${action.id}`));

    if (isSuccessfulWpResponse(response)) {
      yield put(getReferrerContentSuccess(response.data));
    }
  } catch (error) {
    doNothing();
  }
}

export function* watchWordpress(): Generator<AllEffect<ForkEffect<never>>, void> {
  yield all([takeEvery(WordpressActionType.GetReferrerContent, getReferrerContentSaga)]);
}
