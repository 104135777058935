import React from 'react';

import { ArrowLeftIcon } from '@heroicons/react/20/solid';

import LegalCmsContent from '@/components/shared/LegalCmsContent';
import { BffStaticTextContentResponse } from '@lucky7ventures/bff-types';

const ConsentsModalTerms = ({
  callback,
  content,
  contentLoading,
}: {
  callback: () => void;
  content: BffStaticTextContentResponse | null;
  contentLoading: boolean;
}) => {
  if (!content) {
    return null;
  }

  return (
    <div className="relative w-full h-full flex flex-col overflow-y-scroll scrollbar-hide px-8 max-w-[750px] max-h-[650px]">
      <div className="flex w-full bg-white sticky top-0 pt-8 pb-4">
        <div className="w-1/4">
          <button type="button" onClick={callback}>
            <ArrowLeftIcon className="h-5 w-5" />
          </button>
        </div>
        <h1 className="!m-0 !text-base w-1/2 text-center">{content.title}</h1>
        <div className="w-1/4" />
      </div>
      <LegalCmsContent data={content} loading={contentLoading} />
    </div>
  );
};

export default ConsentsModalTerms;
