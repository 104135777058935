import React from 'react';
import { PromotionWidgetVm } from '@/models/vm/promotion-widget-vm.model';
import { cdnImage } from '@/utils/image-utils';
import { CDN_IMAGES_PROMOTIONS_PATH } from '@/constants/constants';
import { Button } from '@/components/buttons/Button';
import { useIntl } from 'react-intl';
import { useAbsoluteNavigate } from '@/hooks/useAbsoluteNavigate';
import { GTagEvents, triggerGTag } from '@/lib/gTagManager';

function PromotionWidget({ promotion }: { promotion: PromotionWidgetVm }) {
  const intl = useIntl();
  const navigate = useAbsoluteNavigate();

  const onMoreInfoHandler = () => {
    triggerGTag(GTagEvents.promotion_widget_cta, {
      slug: promotion.slug,
    });
    navigate(`/promotion/${promotion.slug}`);
  };

  return (
    <div className="rounded-md overflow-hidden shadow-md flex flex-col">
      {promotion.image && (
        <img
          src={cdnImage(promotion.image, {
            width: '1000',
            height: '300',
            path: CDN_IMAGES_PROMOTIONS_PATH,
          })}
          alt={promotion.widgetTitle}
        />
      )}
      <div className="p-8 flex flex-col justify-between grow">
        <div>
          <div className="text-2xl font-bold">{promotion.widgetTitle}</div>
          <div className="text-base my-4">{promotion.widgetShortDescription}</div>
          {promotion.widgetShortTerms && (
            <div className="italic text-xs my-4 opacity-80">{promotion.widgetShortTerms}</div>
          )}
        </div>
        <Button
          onClickHandler={onMoreInfoHandler}
          className="!w-fit px-8"
          text={intl.formatMessage({ id: 'misc.moreInfo' })}
        />
      </div>
    </div>
  );
}

export default PromotionWidget;
