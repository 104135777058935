import { Action } from 'redux';
import { BffCmsPromotionsResponse } from '@lucky7ventures/bff-types';

export enum PromotionsActionType {
  GetPromotions = 'GET_PROMOTIONS',
  GetPromotionsSuccess = 'GET_PROMOTIONS_SUCCESS',
  GetPromotionsError = 'GET_PROMOTIONS_ERROR',
}

export type GetPromotionsAction = Action<PromotionsActionType.GetPromotions>;
export type GetPromotionsSuccessAction = Action<PromotionsActionType.GetPromotionsSuccess> & {
  promotions: BffCmsPromotionsResponse;
};
export type GetPromotionsErrorAction = Action<PromotionsActionType.GetPromotionsError>;

export type PromotionsActions =
  | GetPromotionsAction
  | GetPromotionsSuccessAction
  | GetPromotionsErrorAction;

export const getPromotions = (): GetPromotionsAction => ({
  type: PromotionsActionType.GetPromotions,
});

export const getPromotionsSuccess = (
  promotions: BffCmsPromotionsResponse,
): GetPromotionsSuccessAction => ({
  type: PromotionsActionType.GetPromotionsSuccess,
  promotions,
});

export const getPromotionsError = (): GetPromotionsErrorAction => ({
  type: PromotionsActionType.GetPromotionsError,
});
