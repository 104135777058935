import { useDispatch, useSelector } from 'react-redux';
import { getReferrerContent } from '@/store/actions/wordpressActions';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { selectAutoLoginFinished, selectAutoLoginStep } from '@/store/selectors/autoLoginSelectors';
import { selectIsAuthenticated } from '@/store/selectors/authSelectors';
import { selectUserBtag } from '@/store/selectors/combined.selectors';
import { useEffect } from 'react';
import { isRegulatedLocale } from '@/shared/utility';
import { AutoLoginStep } from '@/models/enum/auto-login-step.enum';

const useWordpressLoader = (): void => {
  const locale = useSelector(selectLocale);
  const autoLoginStep = useSelector(selectAutoLoginStep);
  const autoLoginFinished = useSelector(selectAutoLoginFinished);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const btag = useSelector(selectUserBtag);
  const dispatch = useDispatch();

  // Referrer content
  // 1. don't need referrer content for regulated locale
  // 2. don't call referrer content if there is no btag in the query param
  // 3. don't call referrer content if auto login hasn't finished
  useEffect(() => {
    if (!autoLoginFinished) {
      return;
    }
    if (
      !locale ||
      isRegulatedLocale(locale) ||
      !btag ||
      !autoLoginFinished ||
      isAuthenticated ||
      autoLoginStep === AutoLoginStep.LoggedOut
    ) {
      return;
    }
    // Get referrer content, which is displayed in as a notification and on registration in the regily modal, if the user has a btag in the cookies
    const referrerId = btag.split('_')[0];
    dispatch(getReferrerContent(locale, referrerId));
  }, [btag, locale, autoLoginFinished, isAuthenticated, autoLoginStep]);
};

export default useWordpressLoader;
