import React, { useEffect } from 'react';

import { BffStaticTextContentResponse, CmsStaticTextContentType } from '@lucky7ventures/bff-types';

import LegalCmsContent from '@/components/shared/LegalCmsContent';
import { useBffApiRequest } from '@/hooks/useBffApiRequest';
import BffApiService from '@/shared/bffApiService';

const BonusTermsAndConditionsContent = () => {
  const { request, data, loading } = useBffApiRequest<BffStaticTextContentResponse>();

  useEffect(() => {
    request({
      apiMethod: BffApiService.getStaticTextContent,
      axiosConfig: { params: { contentType: CmsStaticTextContentType.bonusTerms } },
    });
  }, []);

  return <LegalCmsContent data={data} loading={loading} />;
};

export default BonusTermsAndConditionsContent;
