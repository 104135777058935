import * as React from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from '@/store/selectors/commonSelectors';
import { envIsCanada, envIsMexico, envIsNormal, envIsSweden } from '@/constants/constants';
import { useIntl } from 'react-intl';
import { isRegulatedLocale } from '@/shared/utility';
import { useLocation } from 'react-use';
import classNames from 'classnames';
import {
  HomeHeroAuthenticationStep,
  useHomeHeroAuthenticationStepData,
} from '@/store/authentication/AuthenticationStore';
import { GTagEvents, OpenedFrom, triggerGTag } from '@/lib/gTagManager';
import { sendEventToAppWrapper } from '@/utils/app-wrapper-utils';
import { useLogin } from '@/hooks/useLogin';
import { openModal } from '@/store/actions/modal';
import { useRegistration } from '@/hooks/useRegistration';
import { UserPlusIcon } from '@heroicons/react/24/outline';

import useIsHomePage from '@/hooks/useIsHomePage';
import { HeaderVariant } from '@/models/enum/header-variant.enum';

const AuthButton = ({
  headerVariant,
  isScrolled,
}: {
  headerVariant: HeaderVariant;
  isScrolled: boolean;
}) => {
  const locale = useSelector(selectLocale);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { openLogin } = useLogin();
  const { openRegistration } = useRegistration();
  const { homeHeroAuthenticationStep } = useHomeHeroAuthenticationStepData();
  const isHomeRoute = useIsHomePage();

  const handleOpenRegistration = () => {
    triggerGTag(GTagEvents.header_register_click);
    if (isRegulatedLocale(locale)) {
      dispatch(openModal('paynplayIframe', { amount: 0, login: true }));
      return;
    }

    openRegistration({ openedFrom: OpenedFrom.Header });
  };

  const handleLoginClick = () => {
    triggerGTag(GTagEvents.header_login_click);
    sendEventToAppWrapper(GTagEvents.header_login_click);
    openLogin({ openedFrom: OpenedFrom.Header });
  };

  const isRegButtonHidden = useMemo(() => {
    // For normal and mexico builds Reg button should be hidden
    // only when showing Registration Form on homepage and
    // when not scrolled down
    if (envIsMexico() || envIsNormal())
      return (
        homeHeroAuthenticationStep === HomeHeroAuthenticationStep.Registration &&
        isHomeRoute &&
        !isScrolled
      );

    // For Canada build we hide register button only when on /register route
    if (envIsCanada()) return pathname?.toLocaleLowerCase().includes('register');

    return false;
  }, [homeHeroAuthenticationStep, isHomeRoute, isScrolled, pathname]);

  const isLoginButtonHidden = useMemo(() => {
    // Login button is hidden only on homepage when showing Login Form
    // and when not scrolled down
    return (
      homeHeroAuthenticationStep === HomeHeroAuthenticationStep.Login && !isScrolled && isHomeRoute
    );
  }, [homeHeroAuthenticationStep, isScrolled, isHomeRoute]);

  if (isRegulatedLocale(locale)) {
    return (
      <button
        data-cy="authentication-button-registration"
        type="button"
        className={classNames(
          'rounded-full px-3 md:px-4 font-bold transition hover:shadow-md text-white py-2 bg-cyan-500 hover:bg-cyan-600',
          {
            'py-2': isScrolled,
            'md:bg-white/20  md:hover:bg-white/30':
              !isScrolled && headerVariant === HeaderVariant.Default,
          },
        )}
        onClick={handleOpenRegistration}
      >
        <p className="m-0 p-0">
          {intl.formatMessage({
            id: envIsSweden() ? 'header.register.zeroDeposit' : 'header.register',
          })}
        </p>
      </button>
    );
  }

  return (
    <>
      <button
        type="button"
        data-cy="authentication-button-login"
        className={classNames(
          'h-10 rounded-full px-4 font-bold transition hover:shadow-md bg-slate-900/10 text-slate-900 hover:bg-slate-900/20',
          {
            hidden: isLoginButtonHidden,
            ' md:bg-white/20 md:text-white md:hover:bg-white/30':
              !isScrolled && headerVariant === HeaderVariant.Default,
          },
        )}
        onClick={handleLoginClick}
      >
        {intl.formatMessage({ id: 'header.login' })}
      </button>
      <button
        data-cy="authentication-button-registration"
        type="button"
        className={classNames(
          'rounded-full px-3 md:px-4 font-bold transition hover:shadow-md text-white py-2 bg-cyan-500 hover:bg-cyan-600 ',
          {
            hidden: isRegButtonHidden,
            'py-2': isScrolled,
            'md:bg-white/20  md:hover:bg-white/30':
              !isScrolled && headerVariant === HeaderVariant.Default,
          },
        )}
        onClick={handleOpenRegistration}
      >
        <p className="m-0 p-0 hidden md:block">{intl.formatMessage({ id: 'header.register' })}</p>
        <UserPlusIcon width={20} height={20} className="md:hidden justify-self-center" />
      </button>
    </>
  );
};

export default AuthButton;
