import { create } from 'zustand';

import { BffPsaResponse } from '@lucky7ventures/bff-types';
import BffApiService from '@/shared/bffApiService';

interface State {
  psaMessage: BffPsaResponse['message'];
  loading: boolean;
  error: boolean;
}

interface Actions {
  fetch: () => void;
}

export const usePsaStore = create<State & Actions>(set => ({
  psaMessage: null,
  loading: false,
  error: false,
  fetch: async () => {
    set({
      loading: true,
      error: false,
    });
    try {
      const response = await BffApiService.getPsa();
      set({
        loading: false,
        error: false,
        psaMessage: response.data.data?.message,
      });
    } catch {
      set({
        loading: false,
        error: true,
      });
    }
  },
}));
