import React from 'react';
import { envIsCanada } from '@/constants/constants';
import { envIsMexico } from '@/constants/constants';
import MexicoRegistrationForm from '@/features/authentication/registration/mexico/MexicoRegistrationForm';
import FastRegistrationForm from '@/features/authentication/registration/fast-registration/FastRegistrationForm';
import RegistrationIntroCanada from '@/features/authentication/registration/canada/RegistrationIntroCanada';

const Registration = () => {
  if (envIsMexico()) return <MexicoRegistrationForm />;
  else if (envIsCanada()) return <RegistrationIntroCanada />;
  else return <FastRegistrationForm />;
};

export default Registration;
